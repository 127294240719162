import captionService from 'CaptionService';

export const themeConfiguration = {
	close: () => captionService.getString('e11f9410-ce63-4603-a4ca-1e3e1a781716', 'Close'),
	color: () => captionService.getString('57135ef4-7de6-49cc-a0f0-2c2e7652032d', 'Color'),
	colors: () => captionService.getString('f796dffb-d4b1-4298-92d3-cc098996780d', 'Colors'),
	contentBackground: () => captionService.getString('ad69163f-b1e7-4552-85d8-cd34d2a12083', 'Content Background'),
	copyThemeFrom: () => captionService.getString('b72d209f-99ef-4009-aa6f-4cb2090ec905', 'Copy theme from'),
	dark: () => captionService.getString('31f1a5bd-9860-4023-b708-e9aa051fed3d', 'Dark'),
	portal: () => captionService.getString('decb220b-eaeb-47e9-b7ae-db8f28c56c0e', 'Portal'),
	primary: () => captionService.getString('4ac084c5-5a53-461c-a3a2-cc3f2e04b09e', 'Primary'),
	brand: () => captionService.getString('21a47707-bcd8-4902-bfc5-db50d2720ab6', 'Brand'),
	lightTheme: () => captionService.getString('8bb3bce1-6864-442b-b02d-33c826b792b6', 'Light theme'),
	darkTheme: () => captionService.getString('e6d60800-dd6c-4c0a-8d75-972f6e947425', 'Dark theme'),
	logo: () => captionService.getString('989233b0-bb6c-423b-a066-7801ce0eafc4', 'Logo'),
	logoColor: () => captionService.getString('991f729f-395a-4fc9-93df-8e27bec046f5', 'Logo background'),
	logoDarkDescription: () => captionService.getString('0cbdde16-9080-438e-ac5f-1506c1e4cc24', 'Dark logo'),
	logoLightDescription: () => captionService.getString('c0759b34-d63a-45aa-9fb5-8431479296a2', 'Light logo'),
	logos: () => captionService.getString('41302576-58a7-41ec-af67-02f0bdb31d29', 'Logos'),
	brandLogin: () => captionService.getString('ed92629d-a813-4803-adaa-731522f1e52e', 'Login logo background'),
	reset: () => captionService.getString('7a3460f2-de9d-412f-833f-b0e5b88eec85', 'Reset'),
	save: () => captionService.getString('56bf844c-4fa8-4de8-bd85-a4a9b7ff989f', 'Save'),
	select: () => captionService.getString('fdf7b6e9-03c5-4c32-a284-9441294f73b4', 'Select logo'),
	title: () => captionService.getString('a0a8cf66-1b53-4521-be7e-ec04d84fbe70', 'Theme configuration'),
	presets: () => captionService.getString('9f2cc88a-66be-47a2-a133-81416e2c33e3', 'Themes'),
	themeColors: () => captionService.getString('a4f844d7-ed87-4787-8941-7b5f90d116f2', 'Theme colors'),
	fileSizeTooLarge: () => captionService.getString('5074930f-3edb-422d-ba95-ecda45566718', 'File size too large'),
	themeName: () => captionService.getString('e40f0c23-88d8-4345-9eac-299a2be69a4e', 'Theme name'),
	systemThemeName: () => captionService.getString('df182905-e187-45a7-b44a-dac6ece0255b', 'CargoWise default theme'),
	newTheme: () => captionService.getString('84d498b6-3e5f-49c9-8512-e3de60965102', 'New theme'),
	editTheme: () => captionService.getString('46f3fda0-df2d-4cef-8faf-6d655a618979', 'Edit theme'),
	configureBy: () => captionService.getString('205bf8f1-5a1a-46d2-a713-2192a699b884', 'Configure by'),
	hostname: () => captionService.getString('97b4b6f9-1562-4b6f-841b-ba9dc4bceffe', 'Hostname'),
	organisation: () => captionService.getString('99cb2ec7-ea77-409d-826b-fe43b04fcce8', 'Organization'),
	company: () => captionService.getString('5084e95b-ea46-4d07-8edb-198bc1d68423', 'Company'),
	branch: () => captionService.getString('486089fc-b55d-449f-900f-3a3efb0fe267', 'Branch'),
	department: () => captionService.getString('90c801f6-1291-4cb9-9776-76c35256d533', 'Department'),
	configureTheme: () => captionService.getString('0af2b62f-eda6-4448-a97d-4466fefe1e4d', 'Configure theme'),
	createNewTheme: () => captionService.getString('99699707-ccf4-4afd-a088-fe3421c9fdb6', 'Create new theme'),
	saveTheme: () => captionService.getString('be259ff8-989e-4d54-ba69-be8e24890fbd', 'Save theme'),
	userContact: () => captionService.getString('41849237-79d5-438f-b678-b39944facfac', 'User - Contact'),
	userStaff: () => captionService.getString('0d2e778c-c5b4-4263-bbc0-329b8520295b', 'User - Staff'),
	textColor: () => captionService.getString('662a441c-2cbe-43fc-80a9-38a0d6afd588', 'Text color'),
	whiteText: () => captionService.getString('9f48a594-23c6-41cd-8530-c00a984f381c', 'White'),
	blackText: () => captionService.getString('a9a05aee-b887-4c22-9824-2f7029b97158', 'Black'),
	upload: () => captionService.getString('6478f395-811f-402d-a02e-1346be6db8df', 'Upload'),
	navigation: () => captionService.getString('a9f2bbb0-1517-4517-9bcf-82ef5964be5e', 'Navigation'),
};
