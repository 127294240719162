import AsyncLock from "AsyncLock";
import type { AggregateStrategy } from "./AggregateStrategy.ts";
import { IdbAggregateStrategy } from "./IdbAggregateStrategy.ts";
import { IdbStrategy } from "./IdbStrategy.ts";
import { PersistentStore } from "./PersistentStore.ts";
import { RamAggregateStrategy } from "./RamAggregateStrategy.ts";
import { RamStrategy } from "./RamStrategy.ts";
import { StorageError, StorageErrorType } from "./StorageError.ts";
import type { Strategy } from "./Strategy.ts";

const storeCache = new Map<string, PersistentStore>();
/*! StartNoStringValidationRegion Constants */
const publicPrefix = "Public";
export const sessionPrefix = "Session";
export const namesDBName = "DBNAMES-df815f77-cdf2-4760-b72c-bee534bb797e";
/*! EndNoStringValidationRegion */

interface ErrorHandler {
  (error: unknown): void;
}

let _strategySelectionErrorHandler: ErrorHandler | undefined;

export function publicStore(namespace?: string): PersistentStore {
  return getStore(publicPrefix, namespace);
}

export function sessionStore(namespace?: string): PersistentStore {
  return getStore(sessionPrefix, namespace);
}

export async function clearPublicStorageAsync(): Promise<void> {
  const strategy = await strategyFactory.getAggregateStrategyAsync();
  await strategy.clearStorageAsync(storeCache, publicPrefix);
}

export async function clearSessionStorageAsync(): Promise<void> {
  const strategy = await strategyFactory.getAggregateStrategyAsync();
  await strategy.clearStorageAsync(storeCache, sessionPrefix);
}

export function userStore(userKey: string, namespace?: string): PersistentStore {
  if (!userKey) {
    throw new Error("Must specify a user key.");
  }
  /*! SuppressStringValidation Not a caption. */
  return getStore("User" + userKey, namespace);
}

export function strategySelectionErrorHandler(): ErrorHandler | undefined;
export function strategySelectionErrorHandler(handler: ErrorHandler): void;
export function strategySelectionErrorHandler(handler?: ErrorHandler): ErrorHandler | undefined | void {
  if (arguments.length === 0) {
    return _strategySelectionErrorHandler;
  } else {
    _strategySelectionErrorHandler = handler;
  }
}

export function resetPersistentStorage(): void {
  _strategySelectionErrorHandler = undefined;
  storeCache.clear();
  strategyFactory.resetStrategyType();
}

export { StorageError, StorageErrorType };

function getDBNamesStore(): PersistentStore {
  return getStore(namesDBName, undefined, () => {
    return IdbStrategy.createAsync(namesDBName, undefined);
  });
}

function getStore(name: string, namespace?: string, getStrategyAsync?: () => Promise<Strategy>): PersistentStore {
  namespace = namespace || "";
  const storeInternalName = name + namespace;
  let result = storeCache.get(storeInternalName);
  if (!result) {
    result = new PersistentStore(storeInternalName, getStrategyAsync || strategyFactory.getStrategyAsync);
    storeCache.set(storeInternalName, result);
  }

  return result;
}

interface StrategyFactory {
  getAggregateStrategyAsync: () => Promise<AggregateStrategy>;
  getStrategyAsync: (name: string) => Promise<Strategy>;
  resetStrategyType: () => void;
}

const strategyFactory = ((): StrategyFactory => {
  enum StrategyType {
    Idb,
    Ram,
  }

  const lock = new AsyncLock();
  let selectedType: StrategyType | undefined;

  function getAggregateStrategyAsync(): Promise<AggregateStrategy> {
    return getStrategyCoreAsync(
      () => IdbAggregateStrategy.createAsync(getDBNamesStore),
      () => new RamAggregateStrategy(),
    );
  }

  function getStrategyAsync(name: string): Promise<Strategy> {
    return getStrategyCoreAsync<Strategy>(
      () => IdbStrategy.createAsync(name, registerDBNameAsync),
      () => new RamStrategy(),
    );
  }

  async function registerDBNameAsync(name: string): Promise<void> {
    if (indexedDB.databases != null) {
      return;
    }

    const store = getDBNamesStore();
    await store.setAsync(name, name);
  }

  function getStrategyCoreAsync<T extends AggregateStrategy | Strategy>(
    idbStrategyFactory: () => Promise<T>,
    ramStrategyFactory: () => T,
  ): Promise<T> {
    return lock.doAsync(async (): Promise<T> => {
      const strategyType = getStrategyType();
      if (strategyType !== undefined) {
        return strategyType === StrategyType.Ram ? ramStrategyFactory() : idbStrategyFactory();
      } else {
        try {
          const strategy = await idbStrategyFactory();
          selectedType = StrategyType.Idb;
          return strategy;
        } catch (error) {
          if (isReportableError(error)) {
            _strategySelectionErrorHandler?.(error);
          }

          selectedType = StrategyType.Ram;
          return ramStrategyFactory();
        }
      }
    });
  }

  function getStrategyType(): StrategyType | undefined {
    if (selectedType === undefined) {
      if (!window.indexedDB) {
        selectedType = StrategyType.Ram;
      }
    }

    return selectedType;
  }

  function resetStrategyType(): void {
    selectedType = undefined;
  }

  function isReportableError(error: unknown): boolean {
    while (error) {
      if (error instanceof DOMException) {
        return false;
      }
      if (!(error instanceof StorageError)) {
        break;
      }
      if (error.type === StorageErrorType.Write) {
        return false;
      }
      error = error.cause;
    }
    return true;
  }

  return { getAggregateStrategyAsync, getStrategyAsync, resetStrategyType };
})();
