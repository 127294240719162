import { delayAsync } from "DelayedPromise";
import { createEntityAsync } from "EntityCreationExtensions";
import { getPrimaryKey, isDeletedOrDetached } from "EntityExtensions";
import { getPropertyValueAsync } from "EntityPropertyExtensions";
import { synchronizeAsync } from "Synchronizer";
import type { Entity, EntityType, NavigationProperty } from "breeze-client";
import type { Observable } from "knockout";

type UnwrapObservableType<T> = T extends Observable<infer P> ? P : T;
type UnwrapItemType<T> = T extends Array<infer I> ? I : T;
type GetCollectionItemType<T> = UnwrapItemType<UnwrapObservableType<T> & Entity>;
type GetSelfProperties<T> = Omit<T, keyof Entity>;
type ExcludeNavigationProperties<T> = {
  [K in keyof T as T[K] extends Array<unknown> | object | null | undefined ? never : K]: T[K];
};
type Values<T> = ExcludeNavigationProperties<
  GetSelfProperties<{
    [K in keyof T]?: UnwrapObservableType<T[K]>;
  }>
>;

/**
 * Get the first item from a collection property.
 * The items can be further filtered using the values option.
 *
 * Throws an error if the property does not exist or is not a collection.
 *
 * @param entity The entity that owns the collection property.
 * @param propertyName The collection property name.
 * @param values Optional property values to filter the collection.
 * @returns The first item in the collection that matches the values or null if not found.
 */
export async function getFirstOrDefaultChildAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  propertyName: K,
  values?: Values<GetCollectionItemType<T[K]>>,
): Promise<GetCollectionItemType<T[K]> | null> {
  const collection = await getCollectionAsync(entity, propertyName);
  return getChild(collection, values, true);
}

/**
 * Get the first item from a collection property or creates a new one.
 * The items can be further filtered using the values option.
 * If none match, a new item will be created with the values provided.
 *
 * Throws an error if the property does not exist or is not a collection.
 *
 * @param entity The entity that owns the collection property.
 * @param propertyName The collection property name.
 * @param values Optional property values to filter the collection or assign to the new item.
 * @returns The first item in the collection that matches the values or a new item created with the values provided.
 */
export function getOrCreateFirstChildAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  propertyName: K,
  values?: Values<GetCollectionItemType<T[K]>>,
): Promise<GetCollectionItemType<T[K]>> {
  return getOrCreateChildCoreAsync(entity, propertyName, values, true);
}

/**
 * Get a single item from a collection property.
 * The items can be further filtered using the values option.
 *
 * Throws an error if the property does not exist or is not a collection.
 * Throws an error if more than one match the filter.
 *
 * @param entity The entity that owns the collection property.
 * @param propertyName The collection property name.
 * @param values Optional property values to filter the collection.
 * @returns A single item in the collection that matches the values or null if not found.
 */
export async function getSingleOrDefaultChildAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  propertyName: K,
  values?: Values<GetCollectionItemType<T[K]>>,
): Promise<GetCollectionItemType<T[K]> | null> {
  const collection = await getCollectionAsync(entity, propertyName);
  return getChild(collection, values, false);
}

/**
 * Get a single item from a collection property or creates a new one.
 * The items can be further filtered using the values option.
 * If none match, a new item will be created with the values provided.
 *
 * Throws an error if the property does not exist or is not a collection.
 * Throws an error if more than one match the filter.
 *
 * @param entity The entity that owns the collection property.
 * @param propertyName The collection property name.
 * @param values Optional property values to filter the collection or assign to the new item.
 * @returns A single item in the collection that matches the values or a new item created with the values provided.
 */
export function getOrCreateSingleChildAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  propertyName: K,
  values?: Values<GetCollectionItemType<T[K]>>,
): Promise<GetCollectionItemType<T[K]>> {
  return getOrCreateChildCoreAsync(entity, propertyName, values, false);
}

/**
 * Creates a new Breeze entity instance of a given collection property's element type and adds it to
 * the collection.
 *
 * While creating and initializing the entity, proposed value rules are applied and validation is
 * suspended.
 *
 * @param entity The entity that owns the collection property.
 * @param property The collection property to add the entity to.
 * @param options
 * * fallbackEntityType
 * > The type of the entity to create when the collection property's element type is abstract.
 *
 * * initialize
 * > An initialization function to invoke after creating the entity. Proposed values are enabled and
 *   validation is suspended during this time.
 *
 * * initiallyUnchanged
 * > Whether the created entity should be in the special "initially unchanged" state. The entity
 *   starts off in the Unchanged state and becomes Added when modified.
 *
 * @returns A promise that resolves with the created entity.
 */
export async function addNewToCollectionAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  property: NavigationProperty | K,
  options?: {
    /**
     * The type of the entity to create when the collection property's element type is
     * abstract.
     */
    fallbackEntityType?: EntityType | string;
    /**
     * An initialization function to invoke after creating the entity. Proposed values are
     * enabled and validation is suspended during this time.
     */
    initialize?: (result: GetCollectionItemType<T[K]>) => Promise<void> | void;
    /**
     * Whether the created entity should be in the special "initially unchanged" state. The
     * entity starts off in the Unchanged state and becomes Added when modified.
     */
    initiallyUnchanged?: boolean;
  },
): Promise<GetCollectionItemType<T[K]>> {
  if (isDeletedOrDetached(entity)) {
    throw new Error("You cannot add a new collection item to a deleted or detached entity.");
  }

  const collectionProperty = ensureCollectionProperty(entity, property);
  const entityType =
    !collectionProperty.entityType.isAbstract || !options?.fallbackEntityType
      ? collectionProperty.entityType
      : options.fallbackEntityType;

  const result = await createEntityAsync<GetCollectionItemType<T[K]>>(entity.entityAspect.entityManager, entityType, {
    initiallyUnchanged: options?.initiallyUnchanged,
    // eslint-disable-next-line rulesdir/async-function-suffix
    async initialize(result) {
      const foreignKeyName = collectionProperty.invForeignKeyNames[0];
      const key = getPrimaryKey(entity);
      result.setProperty(foreignKeyName, key);
      await options?.initialize?.(result);

      // Quick and dirty way to wait for proposed values.
      // It might not work for complex cases but we can't do a proper await yet until
      // calculated properties no longer call this function, otherwise deadlocks can occur.
      await delayAsync(0);
    },
  });

  return result;
}

async function getOrCreateChildCoreAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  propertyName: K,
  values?: Values<GetCollectionItemType<T[K]>>,
  returnFirst?: boolean,
): Promise<GetCollectionItemType<T[K]>> {
  const collection = await getCollectionAsync(entity, propertyName);
  let relatedEntity = getChild(collection, values, returnFirst);
  if (relatedEntity) {
    return relatedEntity;
  }

  relatedEntity = await synchronizeAsync(entity, async () => {
    let relatedEntity = getChild(collection, values, returnFirst);
    if (relatedEntity) {
      return relatedEntity;
    }

    relatedEntity = await addNewToCollectionAsync(entity, propertyName);
    if (values) {
      initializeValues(relatedEntity, values);
    }

    return relatedEntity;
  });

  return relatedEntity;
}

async function getCollectionAsync<T extends Entity, K extends keyof T & string>(
  entity: T,
  propertyName: K,
): Promise<Array<GetCollectionItemType<T[K]>>> {
  const propertyValue = await getPropertyValueAsync<Array<GetCollectionItemType<T[K]>>>(entity, propertyName);
  if (!Array.isArray(propertyValue)) {
    throw new Error("Property is not an array");
  }

  return propertyValue;
}

function getChild<T extends Entity>(
  collection: T[],
  values?: Record<string, unknown>,
  returnFirst?: boolean,
): T | null {
  if (collection.length === 0) {
    return null;
  } else if (values) {
    let result: T | null = null;
    for (let i = 0; i < collection.length; i++) {
      const item = collection[i];
      if (isMatchingEntity(item, values)) {
        if (returnFirst) {
          return item;
        }
        if (result) {
          throw new Error("Expected one matching item.");
        }
        result = item;
      }
    }

    return result;
  } else if (collection.length === 1 || returnFirst) {
    return collection[0];
  } else {
    throw new Error("Expected one item.");
  }
}

function isMatchingEntity<T extends Entity>(entity: Entity, values: Values<T>): boolean {
  for (const [name, value] of Object.entries(values)) {
    const property = entity.entityType.getProperty(name);

    if (!property) {
      throw new Error(`Property '${name}' does not exist.`);
    }

    if (property.isNavigationProperty) {
      throw new Error(`Property '${name}' is a navigation property, which is not supported by this operation.`);
    }

    if (entity.getProperty(name) !== value) {
      return false;
    }
  }

  return true;
}

function initializeValues<T extends Entity>(entity: T, values: Values<T>): void {
  Object.entries(values).forEach(([name, value]) => {
    const property = name in entity && entity[name as keyof T];
    if (typeof property === "function") {
      property(value);
    }
  });
}

function ensureCollectionProperty(entity: Entity, property: NavigationProperty | string): NavigationProperty {
  if (typeof property === "string") {
    const propertyName = property;
    property = entity.entityType.getNavigationProperty(propertyName);
    if (!property) {
      throw new Error(`Navigation property '${propertyName}' does not exist.`);
    }
  }
  if (property.isScalar) {
    throw new Error(`Navigation property '${property.name}' is not a collection.`);
  }

  return property;
}
