import alertDialogService from "AlertDialogService";
import { AuthenticationError } from "AuthenticationError";
import type { ClaimResponse } from "AuthenticationResponse";
import { AuthenticationResult } from "AuthenticationResult";
import { credentials } from "AuthenticationService";
import type { AuthenticationSource } from "AuthenticationSource";
import captionService from "CaptionService";
import contextChangeService from "ContextChangeService";
import global from "Global";
import type { AuthenticationContext } from "./AuthenticationContext.ts";

interface OAuthState {
  as: AuthenticationSource;
  p: string[];
  uk: string;
}

async function getSelectedAuthenticationContextAsync(): Promise<AuthenticationContext | undefined> {
  const { contexts } = await credentials.listContextsAsync();
  if (!contexts || !contexts.length) {
    await alertDialogService.errorWithOKButtonAsync(
      captionService.getString(
        "ef7fa04f-de73-44dc-ae7a-54d66ddc40b5",
        "You do not have permission to log in to this portal. Contact your system administrator to be granted appropriate permissions.",
      ),
      captionService.getString("7a76a8fe-56fd-4f45-bdc9-87e7f71d4061", "Unable to login"),
    );
    return undefined;
  }

  return await contextChangeService.selectUserAsync(contexts);
}

async function tryHandleAuthenticationErrorAsync(
  authenticationSource: AuthenticationSource,
  error: AuthenticationError,
): Promise<ClaimResponse | undefined> {
  if (error.authenticationResult !== AuthenticationResult.ContextChangeRequired) {
    await alertDialogService.errorWithOKButtonAsync(
      error.message,
      captionService.getString("7a76a8fe-56fd-4f45-bdc9-87e7f71d4061", "Unable to login"),
    );
    return undefined;
  }

  const authenticationContext = await getSelectedAuthenticationContextAsync();
  if (!authenticationContext) {
    return undefined;
  }

  const { userKey, branchKey, departmentKey } = authenticationContext;
  await credentials.selectUserContextAsync(userKey, branchKey, departmentKey);

  return { result: AuthenticationResult.Success, authenticationSource };
}

export function tryHandleThirdPartyLogon(claimDetails: ClaimResponse): void {
  if (!claimDetails || !claimDetails.oauthRedirectUri || !claimDetails.oauthSalientReturnedValues) {
    throw new Error("Could not construct third party redirect uri.");
  }

  const stateObject = {
    p: claimDetails.oauthSalientReturnedValues,
    uk: claimDetails.userKey,
    as: claimDetails.authenticationSource,
    url: global.windowLocation.origin + global.rootPathForModule + global.formFactorPath,
    portals: true,
    ap: claimDetails.oauthAdditionalParameter,
    hash: global.windowLocation.hash || undefined,
  };

  const state = JSON.stringify(stateObject);

  /*! StartNoStringValidationRegion - dealing with URI parameters */
  const callbackUri = global.getAbsoluteUrl(global.serviceUri + "signin-oidc");

  const redirectUri = claimDetails.oauthRedirectUri;
  const separator = redirectUri.indexOf("?") < 0 ? "?" : "&";
  const fullRedirectUri =
    redirectUri +
    separator +
    "redirect_uri=" +
    encodeURIComponent(callbackUri) +
    "&" +
    "state=" +
    encodeURIComponent(state);
  /*! EndNoStringValidationRegion */

  // After redirect and back, we continue with tryCompleteExternalLogonAsync.
  global.windowLocation.href = fullRedirectUri;
}

export async function tryCompleteExternalLogonAsync(): Promise<ClaimResponse | undefined> {
  const searchParams = new URL(global.windowLocation.href).searchParams;
  /*! SuppressStringValidation state is an oauth parameter name */
  const state = searchParams.get("state");
  if (!state) {
    throw new Error("No OAuth flow to finish.");
  }

  const stateObject = JSON.parse(state) as OAuthState;

  const listOfParameters = stateObject.p;
  const userKey = stateObject.uk;
  const authenticationSource = stateObject.as;
  const keysToReport: Record<string, string | null> = {};

  for (const idx in listOfParameters) {
    const key = listOfParameters[idx];
    const value = searchParams.get(key);
    keysToReport[key] = value;
  }

  /*! SuppressStringValidation well known path */
  keysToReport.redirect_uri = global.getAbsoluteUrl(global.serviceUri + "signin-oidc");

  try {
    return await credentials.externalAsync(authenticationSource, userKey, keysToReport);
  } catch (error) {
    if (error instanceof AuthenticationError) {
      return await tryHandleAuthenticationErrorAsync(authenticationSource, error);
    }
    throw error;
  }
}
