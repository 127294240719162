import DependencyExtractorVisitor from "DependencyExtractorVisitor";
import { DependencyError } from "Errors";
import { uniq } from "lodash-es";
import { ExpressionVisitor } from "wtg-expressions";
import { type Embedded_pathContext } from "wtg-expressions/lib/ExpressionParser";

interface Options {
  extractPathsOnly?: boolean;
}

export default class ExtractEmbeddedDependenciesVisitor extends ExpressionVisitor<unknown> {
  private readonly extractPathsOnly?: boolean;
  private readonly result: string[];

  private constructor(options?: Options) {
    super();
    this.result = [];
    this.extractPathsOnly = options?.extractPathsOnly;
  }

  static extractEmbeddedDependencies(dependency: string, options?: Options): string[] {
    if (!dependency) {
      return [];
    }

    const visitor = new ExtractEmbeddedDependenciesVisitor(options);
    const tree = this.getDependencyTree(dependency, ExpressionVisitor.TreeName.Dependency, onError(dependency));
    visitor.visit(tree);
    return uniq(visitor.result);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitEmbedded_path = (ctx: Embedded_pathContext): void => {
    const pathCtx = ctx.path();
    const depVisitor = new DependencyExtractorVisitor({ extractPathsOnly: this.extractPathsOnly });
    depVisitor.visit(pathCtx);
    this.result.push(...depVisitor.dependencyPaths);
  };
}

function onError(path: string): (msg: string) => never {
  return (msg: string) => {
    throw new DependencyError(`Error with ${path}: ${msg}`);
  };
}
