import global from 'Global';

export default function (registry)
{
	/*! StartNoStringValidationRegion route configurations */
	registry.get('#/login', { controller: 'Authentication', action: 'startLogonAsync', isPublic: true, skipAgreement: true });
	registry.get('#/login/:initialPage', { controller: 'Authentication', action: 'startLogonAsync', isPublic: true, skipAgreement: true });
	registry.get('#/login/:initialPage/:reason', { controller: 'Authentication', action: 'startLogonAsync', isPublic: true, skipAgreement: true });
	registry.post('#/impersonate', { controller: 'Authentication', action:'impersonateUserAsync' });
	registry.get('#/', { controller: 'Page', action: 'showDefaultPage' });
	registry.get(new RegExp('^' + global.rootPathForModule + global.formFactorPath + '/?$', 'i'), { controller: 'Page', action: 'showDefaultPage' });
	registry.get(new RegExp('^' + global.rootPathForModule + global.formFactorPath + '/Index/?$', 'i'), { controller: 'Page', action: 'showDefaultPage' });
	registry.get('#/index', { controller: 'Page', action: 'showInitialPage' });
	registry.get('#/page/:formId', { controller: 'Page', action: 'showPage', isPublic: true });

	registry.around({ controller: 'Authentication', action: 'ensureLoggedOn', shouldTriggerEvent: false });
	registry.around({ controller: 'UserAgreement', action: 'checkModule', shouldTriggerEvent: false });

	registry.post('#/logOff', { controller: 'Authentication', action: 'logOffAsync', isPublic: true, intercept: true, skipAgreement: true });

	registry.post('#/changePassword', { controller: 'Authentication', action: 'changePassword' });
	registry.get('#/resetPassword', { controller: 'ResetPassword', action: 'resetPassword', isPublic: true, skipAgreement: true });
	registry.get('#/forgotPassword', { controller: 'ResetPassword', action: 'forgotPassword', isPublic: true, skipAgreement: true });

	// Workflow items popout
	registry.get('#/workflow/tasks/:routeName/:entityType/:entityPK<guid>', { controller: 'WorkflowPopout', action: 'showTasksPopoutAsync' });
	registry.get('#/workflow/milestones/:routeName/:entityType/:entityPK<guid>', { controller: 'WorkflowPopout', action: 'showMilestonesPopoutAsync' });
	registry.get('#/workflow/auditLogs/:routeName/:entityType/:entityPK<guid>', { controller: 'WorkflowPopout', action: 'showAuditLogsPopoutAsync' });
	registry.get('#/workflow/events/:routeName/:entityType/:entityPK<guid>', { controller: 'WorkflowPopout', action: 'showEventsPopoutAsync' });
	registry.get('#/workflow/exceptions/:routeName/:entityType/:entityPK<guid>', { controller: 'WorkflowPopout', action: 'showExceptionsPopoutAsync' });
	registry.get('#/workflow/triggers/:routeName/:entityType/:entityPK<guid>', { controller: 'WorkflowPopout', action: 'showTriggersPopoutAsync' });

	// Form-flow
	registry.get('#/formFlow/:definitionPK<string>', { controller: 'FormFlow', action: 'startAsync' });
	registry.get('#/formFlow/:definitionPK<string>/:entityPK<guid>', { controller: 'FormFlow', action: 'startAsync' });
	registry.get('#/formFlow/:definitionPK<string>/m/', { controller: 'FormFlow', action: 'startMaintainMultipleAsync' });
	registry.get('#/formFlow/:definitionPK<string>/m/:entityPKs<guid[]>', { controller: 'FormFlow', action: 'startMaintainMultipleAsync' });
	registry.get('#/formFlow/default/:entityType/:entityPK<guid>', { controller: 'FormFlow', action: 'startDefaultAsync' });

	// Support
	registry.post('#/support/showHelpPage', { controller: 'Support', action: 'showHelpPage' });
	registry.post('#/support/showHelpPageDialog', { controller: 'Support', action: 'showHelpPageDialogAsync' });
	registry.post('#/support/showHelpPageWindow', { controller: 'Support', action: 'showHelpPageWindow' });
	registry.post('#/support/openMyAccountUrl', { controller: 'Support', action: 'openMyAccountUrl' });

	registry.get('#/support/helpPage/:linkedScreenIdentifier/:title', { controller: 'Support', action: 'loadHelpPageAsync' });
	registry.post('#/support/showConfigureHelp', { controller: 'Support', action: 'showConfigureHelpAsync' });
	registry.post('#/support/showAbout', { controller: 'Support', action: 'showAbout' });
	registry.post('#/support/showGPSDisclaimer', { controller: 'Support', action: 'showGPSDisclaimer' });

	registry.post('#/lastLoginDetails/changeBranchAndDepartment', { controller: 'LastLoginDetails', action: 'changeBranchAndDepartmentAsync' });

	registry.get('#/configuration/page/:configurationTmplPK<string>', { controller: 'Configuration', action: 'showPageAsync' });
	registry.get('#/configuration/formFlow/:configurationTmplPK<string>', { controller: 'Configuration', action: 'showFormFlowAsync' });
	registry.get('#/configuration/masterList/pages', { controller: 'Configuration', action: 'showMasterListPagesAsync' });
	registry.get('#/configuration/masterList/formFlows', { controller: 'Configuration', action: 'showMasterListFormFlowsAsync' });

	registry.post('#/configuration/showConfigureTheme', { controller: 'Configuration', action: 'showConfigureThemeAsync' });
	registry.post('#/configuration/showConfigurationList', { controller: 'Configuration', action: 'showConfigurationListAsync' });
	registry.post('#/configuration/showConfigureAnonymousTrackingFields', { controller: 'Configuration', action: 'showConfigureAnonymousTrackingFields' });
	registry.post('#/configuration/reloadTheme', { controller: 'Configuration', action: 'reloadTheme' });

	// Client Configurate
	registry.post('#/clientConfiguration/configure', { controller: 'ClientConfiguration', action: 'configure' });
	registry.post('#/clientConfiguration/reset', { controller: 'ClientConfiguration', action: 'reset' });
	registry.get('#/clientConfiguration/retrieve', { controller: 'ClientConfiguration', action: 'retrieve' });

	//Tracking
	registry.post('#/tracker', { controller: 'Tracking', action: 'showResult', isPublic: true });
	registry.get('#/tracker', { controller: 'Tracking', action: 'showResult', isPublic: true });

	// Documents
	registry.get('#/document/:name/:id/:tableCode/:entityPk<guid>', { controller: 'Document', action: 'showDocumentDeliveryPageAsync' });
	registry.post('#/document/showDocument', { controller: 'Document', action: 'showDocumentAsync' });
	registry.post('#/document/showDocumentTask', { controller: 'Document', action: 'showDocumentTaskAsync' });

	// eDocs
	registry.post('#/edocs', { controller: 'EDocs', action: 'showAsync' });
	registry.get('#/edocs/:name/:entityType/:entityPk<guid>/:includeDeleted', { controller: 'EDocs', action: 'showEDocsPageAsync' });

	// Conversation Messages
	registry.post('#/conversation/showMessages', { controller: 'Conversation', action: 'showMessages' });

	// Analytics Reports
	registry.get('#/analytics', { controller: 'Analytics', action: 'showModel' });
	registry.get('#/analytics/:model', { controller: 'Analytics', action: 'showReportsAsync' });
	registry.get('#/analytics/:model/:reportName/:reportType', { controller: 'Analytics', action: 'showReportAsync' });
	registry.get('#/analytics/System/:model/:reportName/:reportType', { controller: 'Analytics', action: 'showSystemReportAsync' });

	// Android staging
	registry.get('#/androidSettings', { controller: 'Android', action: 'openStagingRedirectAsync', isPublic: true });
	registry.get('#/androidStaging', { controller: 'Android', action: 'openStagingRedirectAsync', isPublic: true });
	/*! EndNoStringValidationRegion */
}
