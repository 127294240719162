import { DependencyGraph } from "DependencyGraph";
import type { EntityManager } from "breeze-client";

const dependencyGraphs = new WeakMap<EntityManager, DependencyGraph>();

export function getDependencyGraph(entityManager: EntityManager): DependencyGraph {
  let result = dependencyGraphs.get(entityManager);
  if (!result) {
    result = DependencyGraph.create();
    dependencyGraphs.set(entityManager, result);
  }

  return result;
}
