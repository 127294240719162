import appConfig from "AppConfig";
import type DependencyExpression from "DependencyExpression";
import type { DependencyResult, StrictDependencyResult } from "DependencyResult";
import { isEntity } from "EntityExtensions";
import { trackEntityManagerOperationAsync } from "EntityManagerExtensions";
import errorHandler from "ErrorHandler";
import { RuleInvocationException } from "Errors";
import { promiseObserver } from "KnockoutExtensions";
import LookupListItem from "LookupListItem";
import type Rule from "Rule";
import ruleDependencyValue, { type DependencyResults } from "RuleDependencyValue";
import RuleService from "RuleService";
import { State } from "StateConstants";
import type { RuleProcessResult } from "SubRule";
import ko, { type Observable } from "knockout";
import LRUCache from "lru-cache";

interface CacheItem {
  cacheValue?: ReturnType<typeof promiseObserver<StrictDependencyResult<LookupListItem[]>>>;
  hasErrorOccurred?: Observable<boolean>;
}

interface Dependency {
  type: string;
  value: string;
}

const cache = new LRUCache<string, CacheItem>({ max: appConfig.lookupCacheLimit });
const dynamicValuesMap = new WeakMap<object, Map<string, LookupListItem[] | undefined>>();

const lookupRuleEngine = {
  observeValue(entity: object, entityName: string, propertyName: string): LookupListItem[] {
    const rule = RuleService.get(entityName).lookupRule(propertyName, true);
    return rule ? this.observeValueForRule(entity, rule) : getNotLoadedValue(entity, propertyName);
  },

  observeValueForRule(entity: object, rule: Rule, emptyDefaultValue: boolean = false): LookupListItem[] {
    const dependencies = getDependencies(rule);
    let { value } = observeValue(entity, rule, dependencies);

    if (entity && dependencies.length) {
      value = handleDynamicValue(entity, rule.property, value);
    }

    return value || getNotLoadedValue(entity, rule.property, emptyDefaultValue);
  },

  observeValueWithState(entity: object, entityName: string, propertyName: string): DependencyResult<LookupListItem[]> {
    const rule = RuleService.get(entityName).lookupRule(propertyName, true);
    return rule ? this.observeValueWithStateForRule(entity, rule) : { state: State.NotAvailable };
  },

  observeValueWithStateForRule(entity: object, rule: Rule): DependencyResult<LookupListItem[]> {
    const dependencies = getDependencies(rule);
    const result = observeValue(entity, rule, dependencies);
    return result.state === State.Available ? result : { state: result.state };
  },

  async getValueAsync(entity: object, rule: Rule): Promise<LookupListItem[]> {
    const dependencies = getDependencies(rule);
    const getValueCoreAsync = async (): Promise<LookupListItem[]> => {
      const result = await ruleDependencyValue.getValuesAsync(entity, dependencies);
      if (result.state !== State.Available) {
        return [];
      }

      const { cacheValue } = getCacheValue(entity, rule, result);
      try {
        const { value } = (await cacheValue.getValueAsync()) ?? {};
        return value;
      } catch (error) {
        if (error instanceof RuleInvocationException) {
          /*! SuppressStringValidation Developer error message */
          errorHandler.reportError(error, "Error while invoking lookup rule.");
          return [];
        }
        throw error;
      }
    };

    return await trackPromiseAsync(entity, getValueCoreAsync());
  },

  reset(): void {
    cache.clear();
  },
};

function getDependencies(rule: Rule): Dependency[] {
  return rule.getAllDependencies().map(getDependency);
}

function observeValue(entity: object, rule: Rule, dependencies: Dependency[]): DependencyResult<LookupListItem[]> {
  let dependencyValues;
  if (dependencies.length) {
    dependencyValues = ruleDependencyValue.getValues(entity, dependencies);
    if (dependencyValues.state !== State.Available) {
      return { state: dependencyValues.state };
    }
  }

  const { cacheValue, hasErrorOccurred } = getCacheValue(entity, rule, dependencyValues);
  const valueWithState = cacheValue.read();
  if (valueWithState) {
    return valueWithState;
  }

  return { state: hasErrorOccurred() ? State.NotAvailable : State.NotLoaded };
}

function handleDynamicValue(
  entity: object,
  propertyName: string,
  value: LookupListItem[] | null | undefined,
): LookupListItem[] | undefined {
  let entityMap = dynamicValuesMap.get(entity);
  if (value) {
    if (!entityMap) {
      entityMap = new Map();
      dynamicValuesMap.set(entity, entityMap);
    }

    entityMap.set(propertyName, value);
    return value;
  } else {
    return entityMap?.get(propertyName);
  }
}

function getNotLoadedValue(entity: object, propertyName: string, emptyDefaultValue = false): LookupListItem[] {
  if (!entity || emptyDefaultValue) {
    return [];
  }

  const propertyValue = ko.utils.peekObservable((entity as Record<string, unknown>)[propertyName]) as string;
  return [new LookupListItem(propertyValue, propertyValue)];
}

function getDependency({ expression }: DependencyExpression): Dependency {
  return { type: "path", value: "<" + expression + ">" };
}

function getCacheValue(
  entity: object,
  rule: Rule,
  dependencyValues: DependencyResults<unknown[]> | undefined,
): Required<CacheItem> {
  const key = getCacheKey(rule, dependencyValues?.values);
  let { cacheValue, hasErrorOccurred } = cache.get(key) || {};

  if (!hasErrorOccurred) {
    hasErrorOccurred = ko.observable(false);
  }

  if (!cacheValue || cacheValue.hasError()) {
    const valueFactoryAsync = async (): Promise<StrictDependencyResult<LookupListItem[]>> => {
      try {
        const result = await rule.processAsync(entity);
        const value = getValueOrDefault(result);
        return value;
      } catch (ex) {
        hasErrorOccurred(true);
        throw ex;
      }
    };
    cacheValue = promiseObserver(trackPromiseAsync(entity, valueFactoryAsync()));
    cache.set(key, { cacheValue, hasErrorOccurred });
  }

  return { cacheValue, hasErrorOccurred };
}

function getCacheKey(rule: Rule, dependencyValues: unknown[] | undefined): string {
  /*! SuppressStringValidation Cache key */
  let result = "Rule_" + rule.ruleId;
  if (dependencyValues?.length) {
    result = dependencyValues.reduce((accumulator: string, value, index) => {
      if (value != null) {
        accumulator += "_" + index + "=" + value;
      }
      return accumulator;
    }, result);
  }

  return result;
}

function getValueOrDefault(result: RuleProcessResult): StrictDependencyResult<LookupListItem[]> {
  if (result.isSuccess) {
    const { value } = result;
    const list: LookupListItem[] = Array.isArray(value) ? value : [];
    return { state: State.Available, value: list };
  } else {
    return { state: State.NotAvailable, value: [] };
  }
}

async function trackPromiseAsync<T>(entity: unknown, promise: Promise<T>): Promise<T> {
  return await (isEntity(entity)
    ? trackEntityManagerOperationAsync(entity.entityAspect.entityManager, promise)
    : promise);
}

export default lookupRuleEngine;
