import { getDependencyValue, getDependencyValueAsync } from "Dependency2";
import type { StrictDependencyResult } from "DependencyResult";

class RuleExpressionCondition {
  constructor(readonly conditionExpression: string) {}

  evaluate(entity: unknown): StrictDependencyResult<boolean | null> {
    return getDependencyValue(entity, this.conditionExpression);
  }

  async evaluateAsync(entity: unknown): Promise<boolean | null> {
    const result = await getDependencyValueAsync<boolean | null>(entity, this.conditionExpression);
    return result.value;
  }
}

export default RuleExpressionCondition;
