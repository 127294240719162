import captionService from "CaptionService";

export const searchControl: Record<string, (params: unknown[]) => string> = {
  ariaLabelPrompter: () => captionService.getString("7f82d9a5-a359-4e24-b3b4-815e930bab5d", "Advanced search dialog"),
  noResults: () => captionService.getString("ae1d8011-b177-40ef-9def-cba5017688e6", "No records found"),
  notAvailable: () => captionService.getString("2cdb3a6e-b8e9-41d0-9f07-e868d2fa436c", "NOT AVAILABLE"),
  showing: (formatParams) =>
    captionService.getString(
      "5b1d522f-504a-4c88-91ef-3aad20497868",
      "Showing 1 to {0} of {1} results",
      formatParams[0],
      formatParams[1],
    ),
  showMore: () => captionService.getString("15370e20-48c5-4399-a35e-11bfb18f7ed4", "Show More"),
  selectRecord: () => captionService.getString("599654a7-a409-457f-923c-0b83d73fb570", "Select Record"),
  cancel: () => captionService.getString("9983683b-6e51-4939-83d2-c145e53e6e0f", "Cancel"),
  select: () => captionService.getString("faff2096-91ec-40cf-9d61-72e078fafe91", "Select"),
};
