import { openDB, type IDBPDatabase, type IDBPObjectStore } from "idb";
import { dbPrefix, storeName } from "./Constants.ts";
import { StorageError, StorageErrorType } from "./StorageError.ts";
import type { Item, Strategy } from "./Strategy.ts";

export class IdbStrategy implements Strategy {
  private dbPromise: Promise<IDBPDatabase>;
  private readonly name: string;
  private readonly registerDBNameAsync: ((name: string) => Promise<void>) | undefined;

  private constructor(
    name: string,
    registerDBNameAsync: ((name: string) => Promise<void>) | undefined,
    dbPromise: Promise<IDBPDatabase>,
  ) {
    this.name = name;
    this.registerDBNameAsync = registerDBNameAsync;
    this.dbPromise = dbPromise;
  }

  static async createAsync(
    name: string,
    registerDBNameAsync: ((name: string) => Promise<void>) | undefined,
  ): Promise<IdbStrategy> {
    const dbPromise = initializeDBAsync(name, registerDBNameAsync);
    await dbPromise;
    return new IdbStrategy(name, registerDBNameAsync, dbPromise);
  }

  async clearAsync(): Promise<void> {
    try {
      await this.executeWithRetryAsync((db) => db.clear(storeName));
    } catch (error) {
      throw new StorageError(`Failed to clear values for store '${this.name}'.`, error);
    }
  }

  async getAsync<T>(key: string): Promise<T | undefined> {
    try {
      return await this.executeWithRetryAsync((db) => db.get(storeName, key));
    } catch (error) {
      throw new StorageError(`Failed to get value for store '${this.name}' and key '${key}'.`, error);
    }
  }

  async deleteAsync(key: string): Promise<void> {
    try {
      return await this.executeWithRetryAsync((db) => db.delete(storeName, key));
    } catch (error) {
      throw new StorageError(`Failed to delete value for store '${this.name}' and key '${key}'.`, error);
    }
  }

  async deleteAllAsync(predicate: (item: Item) => boolean): Promise<void> {
    try {
      return await this.executeWithRetryAsync(async (db) => {
        /*! SuppressStringValidation Not a caption. */
        const transaction = db.transaction(storeName, "readwrite");
        const store = transaction.store;
        const elements = await getAllItemsCoreAsync(store);
        const elementsToDelete = elements.filter(predicate);
        await Promise.all(
          elementsToDelete.map((element) => {
            return store.delete(element.key);
          }),
        );
        await transaction.done;
      });
    } catch (error) {
      throw new StorageError(`Failed to delete values for store '${this.name}'.`, error);
    }
  }

  async getAllValuesAsync<T>(): Promise<T[]> {
    try {
      return await this.executeWithRetryAsync((db) => db.getAll(storeName));
    } catch (error) {
      throw new StorageError(`Failed to get all values for store '${this.name}'.`, error);
    }
  }

  async getAllItemsAsync<T>(): Promise<Item<T>[]> {
    try {
      return await this.executeWithRetryAsync(async (db) => {
        /*! SuppressStringValidation Not a caption. */
        const transaction = db.transaction(storeName, "readonly");
        const store = transaction.store;
        const result = await getAllItemsCoreAsync<T>(store);
        await transaction.done;
        return result;
      });
    } catch (error) {
      throw new StorageError(`Failed to get all items for store '${this.name}'.`, error);
    }
  }

  async replaceAllAsync<T>(items: Item<T>[]): Promise<void> {
    try {
      return await this.executeWithRetryAsync(async (db) => {
        /*! SuppressStringValidation Not a caption. */
        const transaction = db.transaction(storeName, "readwrite");
        const store = transaction.store;
        await store.clear();
        try {
          await Promise.all(items.map((item) => store.put(item.value, item.key)));
        } catch (error) {
          throw isWriteError(error) ? new IdbWriteError(error) : error;
        }
        await transaction.done;
      });
    } catch (error) {
      throw createWriteError(`Failed to replace all items for store '${this.name}'.`, error);
    }
  }

  async setAsync<T>(key: string, value: T): Promise<void> {
    try {
      await this.executeWithRetryAsync(async (db) => {
        try {
          await db.put(storeName, value, key);
        } catch (error) {
          throw isWriteError(error) ? new IdbWriteError(error) : error;
        }
      });
    } catch (error) {
      throw createWriteError(`Failed to set value for store '${this.name}' and key '${key}'.`, error);
    }
  }

  private async executeWithRetryAsync<T>(
    asyncOperation: (database: IDBPDatabase) => Promise<T>,
    retries = 3,
  ): Promise<T> {
    // We are retrying due to a behaviour in some browsers where it will close the connection to the DB when it goes to the background.
    // Apparently a lot of DB related issues have been reported by other libraries, like Firebase that heavily relies on this persistent storage.
    // This solution was inspired by Firebase fix in https://github.com/firebase/firebase-js-sdk/pull/4059/commits/0b73fbfad384387bfc0184758c4c67f51d7c6588
    const originalDbPromise = this.dbPromise;
    const database = await originalDbPromise;
    try {
      return await asyncOperation(database);
    } catch (error) {
      if (retries > 1) {
        if (this.dbPromise === originalDbPromise) {
          // We should only retry the initialization if another operation has not done that already
          database.close();
          this.dbPromise = initializeDBAsync(this.name, this.registerDBNameAsync);
        }
        return this.executeWithRetryAsync(asyncOperation, --retries);
      }
      throw error;
    }
  }
}

async function initializeDBAsync(
  name: string,
  registerDBNameAsync: ((name: string) => Promise<void>) | undefined,
): Promise<IDBPDatabase> {
  const dbName = `${dbPrefix}${name}`;
  try {
    const [db] = await Promise.all([
      openDB(dbName, 1, {
        upgrade(db) {
          db.createObjectStore(storeName);
        },
        blocking(_currentVersion, _blockedVersion, event) {
          const db = event.target as IDBPDatabase;
          db.close();
        },
      }),
      registerDBNameAsync?.(dbName),
    ]);

    return db;
  } catch (error) {
    throw new StorageError(`Failed to initialize DB for store '${name}'.`, error);
  }
}

async function getAllItemsCoreAsync<T>(
  /*! SuppressStringValidation idb literals */
  store: IDBPObjectStore<unknown, ["keyval"], "keyval", "readonly" | "readwrite">,
): Promise<Item<T>[]> {
  const results: { key: string; value: T }[] = [];
  let cursor = await store.openCursor();
  while (cursor) {
    results.push({
      key: cursor.key as string,
      value: cursor.value,
    });
    cursor = await cursor.continue();
  }

  return results;
}

function isWriteError(error: unknown): error is DOMException | null {
  return error instanceof DOMException || error === null;
}

function createWriteError(message: string, error: unknown): Error {
  if (error instanceof IdbWriteError) {
    return new StorageError(message, error.cause, StorageErrorType.Write);
  } else {
    return new StorageError(message, error);
  }
}

class IdbWriteError extends Error {
  override readonly name = "IdbWriteError";
  override readonly cause: Error | null;

  constructor(cause: Error | null) {
    super(undefined);
    this.cause = cause;
  }
}
