import alertDialogService from "AlertDialogService";
import alertResultsDeserializer from "AlertResultsDeserializer";
import captionService from "CaptionService";
import { addNewToCollectionAsync } from "EntityCollectionExtensions";
import { deleteEntityAsync } from "EntityDeletionExtensions";
import { getTableCode } from "EntityExtensions";
import * as entityManagerExtension from "EntityManagerExtensions";
import { isEqual, notifyChangedFieldValuesAsync } from "EntitySaveServiceConcurrencyMergeHelper";
import errorHandler from "ErrorHandler";
import { isDbUpgradeError, ResourceStreamError } from "Errors";
import { trackBusyStateAsync } from "GlobalBusyStateTracker";
import handleJobDocAddressDuplicateUniqueIndexConflictAsync from "JobDocAddressDuplicateUniqueIndexConflictHandler";
import NotificationSummary from "NotificationSummary";
import { compareNotificationTypes, NotificationType } from "NotificationType";
import Notifications, { type Alert, type AlertDetails } from "Notifications";
import { getODataErrorMessage, isODataValidationError, type ErrorObject } from "ODataUtils";
import type ResourceStreams from "ResourceStreams";
import { SaveEntityError, SaveEntityErrorType } from "SaveEntityError";
import { tryParseJson } from "StringUtils";
import systemAuditPropertiesHelper from "SystemAuditPropertiesHelper";
import validationEngine from "ValidationEngine";
import breeze, { type Entity, type EntityManager } from "breeze-client";
import ko from "knockout";

// eslint-disable-next-line @typescript-eslint/naming-convention
const MaxRecursionDepth = 3;

type ConcurrencyErrorMessageInfo = {
  conflictType: string;
  entityTypeName: string;
  entityKey: string;
};

export interface EntitySaveResult {
  isSaved: boolean;
  error: SaveEntityError | null;
}

export interface SaveOptions {
  ignoreWarnings?: boolean;
  ignoreWarningsProperties?: string[];
  shouldRefresh?: boolean;
  shouldReconcileConflicts?: boolean;
  shouldShowDialog?: (saveEntityErrorType: SaveEntityErrorType) => boolean;
}

export class EntitySaveService {
  saveAsync(entityManager: EntityManager, saveOptions?: SaveOptions): Promise<void> {
    const promise = saveCoreAsync(entityManager, saveOptions);
    return trackBusyStateAsync(promise);
  }

  async saveWithoutAlertsAsync(entityManager: EntityManager, saveOptions?: SaveOptions): Promise<EntitySaveResult> {
    try {
      await this.saveAsync(entityManager, saveOptions);
      return { isSaved: true, error: null };
    } catch (error) {
      if (error instanceof SaveEntityError) {
        return { isSaved: false, error };
      }
      throw error;
    }
  }

  saveWithAlertsAsync(entityManager: EntityManager, saveOptions?: SaveOptions): Promise<EntitySaveResult>;

  async saveWithAlertsAsync(entityManager: EntityManager, saveOptions?: SaveOptions): Promise<EntitySaveResult> {
    try {
      await this.saveAsync(entityManager, saveOptions);
      return { isSaved: true, error: null };
    } catch (error) {
      if (!(error instanceof SaveEntityError)) {
        throw error;
      }

      const shouldShowDialog = saveOptions?.shouldShowDialog ?? ((): boolean => true);

      if (error.type === SaveEntityErrorType.SaveValidation) {
        const ignoreWarnings = saveOptions?.ignoreWarnings;
        if (ignoreWarnings) {
          removeWarningsForProperties(entityManager, saveOptions.ignoreWarningsProperties);
        }

        const entities = ko.observable(entityManager.getEntities());
        const notificationSummary = new NotificationSummary(null, entities);
        if (ignoreWarnings && notificationSummary.all().length === 0) {
          return this.saveWithAlertsAsync(entityManager);
        }

        if (shouldShowDialog(error.type)) {
          const message = error.friendlyMessage();
          const title = error.friendlyCaption();

          await alertDialogService.showValidationResultsAsync(message, title, notificationSummary);
        }

        // given SaveValidation error, with only warning alerts, and all are acknowledged, should reattempt saving
        const isWarningOrLess = compareNotificationTypes(notificationSummary.level(), NotificationType.Warning) < 1;
        const areAllWarningsAcknowledged =
          notificationSummary.warnings().filter((x) => x.acknowledged?.()).length ===
          notificationSummary.warnings().length;
        if (isWarningOrLess && areAllWarningsAcknowledged) {
          return this.saveWithAlertsAsync(entityManager);
        }
      } else if (shouldShowDialog(error.type)) {
        await alertDialogService.errorWithOKButtonAsync(error.friendlyMessage(), error.friendlyCaption());
      }

      return { isSaved: false, error };
    }
  }
}

async function acknowledgeAndClearNotificationsAsync(entities: Entity[]): Promise<Entity[]> {
  let promises: Array<Promise<Entity>> = [];

  entities.map((entity) => {
    /*! SuppressStringValidation Suppress validation for property name */
    if (entity.entityType.getProperty("Acknowledgements") && !entity.entityAspect.entityState.isDeleted()) {
      const acknowledgementPromises = createWarningAcknowledgementsAsync(entity);
      if (acknowledgementPromises) {
        promises = promises.concat(acknowledgementPromises);
      }
    }
    Notifications.get(entity)?.removeAll(removeServerAndUnacknowledgedWarnings);
  });

  return await Promise.all(promises);
}

function removeServerAndUnacknowledgedWarnings(alert: Alert): boolean {
  return (
    (alert.isServerNotification as boolean) && !(alert.Level === NotificationType.Warning && alert.acknowledged?.())
  );
}

function clearAcknowledgedAlerts(entities: Entity[]): void {
  for (let i = 0; i < entities.length; i++) {
    Notifications.get(entities[i])?.clearAcknowledgedAlerts();
  }
}

async function createSingleWarningAcknowledgementAsync(entity: Entity, alert: Alert): Promise<Entity> {
  /*! SuppressStringValidation Suppress validation for property name */
  const acknowledgementsProperty = entity.entityType.getNavigationProperty("Acknowledgements");
  const acknowledgment = await addNewToCollectionAsync(entity, acknowledgementsProperty);
  acknowledgment.setProperty("XK_ParentID", alert.entityPK);
  acknowledgment.setProperty("XK_RuleID", alert.ruleId);
  return acknowledgment;
}

function createWarningAcknowledgementsAsync(entity: Entity): Promise<Entity>[] | undefined {
  const notifications = Notifications.get(entity);
  return notifications?.acknowledgedAlerts.map((alert: Alert) => {
    return createSingleWarningAcknowledgementAsync(entity, alert);
  });
}

async function deleteWarningAcknowledgementsAsync(acknowledgements: Entity[]): Promise<void> {
  await Promise.all(acknowledgements.map(async (acknowledgement) => await deleteEntityAsync(acknowledgement)));
}

function getChangedProperties(entity: Entity): Record<string, { original: unknown; changed: unknown }> {
  const values: Record<string, { original: unknown; changed: unknown }> = {};
  const tableCode = getTableCode(entity);

  for (const propertyName in entity.entityAspect.originalValues) {
    const isSystemManaged = systemAuditPropertiesHelper.isSystemAuditProperty(propertyName, tableCode);

    if (!isSystemManaged) {
      values[propertyName] = {
        original: (entity.entityAspect.originalValues as Record<string, unknown>)[propertyName],
        changed: entity.getProperty(propertyName),
      };
    }
  }

  return values;
}

function isAlertToIgnore(alert: Alert, ignoreWarningsProperties?: string[]): boolean {
  return (
    alert.isWarning() &&
    (ignoreWarningsProperties === undefined ||
      (ignoreWarningsProperties && ignoreWarningsProperties.includes(alert.propertyName)))
  );
}

function handleResourceStreamError(entityManager: EntityManager, error: SaveEntityError): boolean {
  const errorXmlMessage = $($.parseXML(error.responseText as string));
  const alerts = alertResultsDeserializer.fromErrorMessage(errorXmlMessage.text());
  return setNotificationAlerts(entityManager, alerts);
}

async function handleErrorAsync(
  entityManager: EntityManager,
  error: SaveEntityError,
  options?: SaveOptions,
  recursionDepth?: number,
): Promise<SaveEntityError> {
  let result;
  recursionDepth = recursionDepth || 0;

  switch (error.status as number) {
    case 0:
      result = new SaveEntityError(SaveEntityErrorType.NetworkFailure, { cause: error });
      break;

    case 400:
      if (handleValidationError(entityManager, error)) {
        result = new SaveEntityError(SaveEntityErrorType.SaveValidation);
      }
      break;

    case 401:
      result = new SaveEntityError(SaveEntityErrorType.Unauthorized);
      break;

    case 403:
      result = new SaveEntityError(SaveEntityErrorType.SecurityFailure);
      break;

    case 404:
      result = await handleNotFoundErrorAsync(entityManager, error.entity as Entity, options);
      break;

    case 412:
      result = await handleConcurrencyErrorAsync(entityManager, error, options, recursionDepth);
      break;

    case 503:
      if (isDbUpgradeError(error)) {
        result = new SaveEntityError(SaveEntityErrorType.DatabaseIsUpgrading, {
          cause: error,
        });
      }
      break;

    case undefined:
      result = error;
  }

  return result || handleGenericError(error);
}

async function handleNotFoundErrorAsync(
  entityManager: EntityManager,
  entity: Entity,
  options?: SaveOptions,
): Promise<SaveEntityError | void> {
  if (!entity) {
    return;
  }

  if (!entity.entityAspect.entityState.isDeleted()) {
    return new SaveEntityError(SaveEntityErrorType.NotFound, { entity });
  }

  entity.entityAspect.setDetached();

  try {
    await entityManager.saveChanges();
    return new SaveEntityError(SaveEntityErrorType.None);
  } catch (error: unknown) {
    return handleErrorAsync(entityManager, error as SaveEntityError, options);
  }
}

function handleGenericError(error: SaveEntityError): SaveEntityError {
  const isClientError = error.status && error.status >= 400 && error.status < 500;
  const friendlyMessageOverride =
    isClientError && !isODataValidationError(error.body) && getODataErrorMessage(error.body);
  return friendlyMessageOverride
    ? new SaveEntityError(SaveEntityErrorType.KnownRequestFailure, { friendlyMessageOverride })
    : new SaveEntityError(SaveEntityErrorType.UnknownRequestFailure, { cause: error });
}

function handleValidationError(entityManager: EntityManager, error: Error): boolean {
  const alerts = alertResultsDeserializer.fromError(error);
  return setNotificationAlerts(entityManager, alerts);
}

async function handleConcurrencyDeleteErrorAsync(entity: Entity): Promise<boolean> {
  const query = breeze.EntityQuery.fromEntityKey(entity.entityAspect.getKey());
  const entityManager = entityManagerExtension.createEntityManagerForMetadataStore(entity.entityType.metadataStore);

  const data = await entityManager.executeQuery(query);
  const reloadedEntity = data.results[0];
  if (reloadedEntity) {
    entity.entityAspect.extraMetadata = {
      ...entity.entityAspect.extraMetadata,
      etag: "etag" in reloadedEntity.entityAspect.extraMetadata && reloadedEntity.entityAspect.extraMetadata.etag,
    };
  }
  return !!reloadedEntity;
}

async function handleConcurrencyErrorAsync(
  entityManager: EntityManager,
  error: SaveEntityError,
  options?: SaveOptions,
  recursionDepth?: number,
): Promise<SaveEntityError | void> {
  const message = getODataErrorMessage(error.body as ErrorObject);
  const info = message && tryParseJson<ConcurrencyErrorMessageInfo>(message);
  if (!info) {
    return;
  }

  let entity;
  try {
    entity = entityManager.getEntityByKey(info.entityTypeName, info.entityKey);
  } catch (e: unknown) {
    const concurrencyError = new SaveEntityError(SaveEntityErrorType.Concurrency, {
      message,
      cause: e as Error,
    });
    errorHandler.reportError(
      concurrencyError,
      "Attempted call on entityManager.getEntityByKey with invalid info object: " + JSON.stringify(info),
    );

    return concurrencyError;
  }

  let handler: Promise<boolean>;
  if (info.conflictType) {
    if (info.conflictType === "JobDocAddressDuplicateUniqueIndexConflict") {
      handler = handleJobDocAddressDuplicateUniqueIndexConflictAsync(entityManager, entity, error, options);
    } else {
      const concurrencyError = new SaveEntityError(SaveEntityErrorType.Concurrency, {
        entity,
        message,
        cause: error,
      });
      errorHandler.reportError(
        concurrencyError,
        `Unknown conflictType ${JSON.stringify(info.conflictType)} in info object: ${message}`,
      );
      return concurrencyError;
    }
  } else {
    const entityState = entity && entity.entityAspect.entityState;
    const shouldReconcileConflicts = !options || options.shouldReconcileConflicts !== false;
    if (!shouldReconcileConflicts || !entityState || entityState.isAdded() || entityState.isUnchanged()) {
      return new SaveEntityError(SaveEntityErrorType.Concurrency, { entity });
    } else if (entity.entityAspect.entityState.isDeleted()) {
      handler = handleConcurrencyDeleteErrorAsync(entity);
    } else {
      handler = handleConcurrencyModifyErrorAsync(entity);
    }
  }

  try {
    const canSave = await handler;
    if (canSave && (recursionDepth ?? 0) < MaxRecursionDepth) {
      await entityManager.saveChanges();
      return new SaveEntityError(SaveEntityErrorType.None);
    } else {
      return new SaveEntityError(SaveEntityErrorType.Concurrency, { entity });
    }
  } catch (error: unknown) {
    return handleErrorAsync(entityManager, error as SaveEntityError, options, (recursionDepth ?? 0) + 1);
  }
}

async function handleConcurrencyModifyErrorAsync(entity: Entity): Promise<boolean> {
  const valuesBeforeReload = getChangedProperties(entity);
  const query = breeze.EntityQuery.fromEntityKey(entity.entityAspect.getKey()).using(
    breeze.MergeStrategy.OverwriteChanges,
  );

  await entity.entityAspect.entityManager.executeQuery(query);

  let mergedSuccessfully = true;
  await Promise.all(
    Object.entries(valuesBeforeReload).map(async ([propertyName, valueBeforeReload]) => {
      const reloadedValue = entity.getProperty(propertyName);

      if (isEqual(reloadedValue, valueBeforeReload.original, entity.entityType.getDataProperty(propertyName))) {
        entity.setProperty(propertyName, valueBeforeReload.changed);
      } else if (reloadedValue !== valueBeforeReload.changed) {
        mergedSuccessfully = false;
        await notifyChangedFieldValuesAsync(entity, propertyName, valueBeforeReload.changed, reloadedValue);
      }
    }),
  );

  return mergedSuccessfully;
}

async function saveCoreAsync(entityManager: EntityManager, options?: SaveOptions): Promise<void> {
  await entityManagerExtension.waitForEntityManagerOperationsAsync(entityManager);

  const entities = entityManager.getEntities();
  const isValid = await validateUnsavedEntitiesAsync(entityManager);
  if (!isValid) {
    throw new SaveEntityError(SaveEntityErrorType.SaveValidation);
  }

  const acknowledgements = await acknowledgeAndClearNotificationsAsync(entities);
  const suspension = validationEngine.suspendValidation(entities);

  try {
    try {
      await entityManager.saveChanges(undefined, constructSaveOptions(entityManager, options));
    } catch (error: unknown) {
      const handledError = await handleErrorAsync(entityManager, error as SaveEntityError, options);
      if (handledError.type !== SaveEntityErrorType.None) {
        await deleteWarningAcknowledgementsAsync(acknowledgements);
        throw handledError;
      }
    }

    clearAcknowledgedAlerts(entities);

    try {
      "resourceStreams" in entityManager &&
        (await (entityManager.resourceStreams as ResourceStreams).saveChangesAsync());
    } catch (e: unknown) {
      if (e instanceof ResourceStreamError) {
        const error = e.cause as SaveEntityError;
        const entity = e.entity;
        entity.entityAspect.setEntityState(breeze.EntityState.Modified);

        if (error.status === 400 || error.status === 403) {
          if (handleResourceStreamError(entityManager, error)) {
            throw new SaveEntityError(SaveEntityErrorType.SaveValidation);
          }
        } else if (error.status === 413) {
          throw new SaveEntityError(SaveEntityErrorType.KnownRequestFailure, {
            friendlyMessageOverride: captionService.getString(
              "9a93b251-4df5-4a10-970b-bcfcdf6449c0",
              "The amount of data you are saving is too large. Please check if you have any files, images or rich text content and reduce their size.",
            ),
          });
        }
        throw new SaveEntityError(SaveEntityErrorType.UnknownRequestFailure, {
          cause: e,
        });
      }
      throw e;
    }
  } finally {
    suspension.dispose();
  }

  function constructSaveOptions(entityManager: EntityManager, options?: SaveOptions): breeze.SaveOptions | undefined {
    if (!options || !options.shouldRefresh) {
      return undefined;
    }

    const baseOptions = entityManager.saveOptions ? entityManager.saveOptions : breeze.SaveOptions.defaultInstance;
    return new breeze.SaveOptions({
      ...baseOptions,
      shouldRefresh: options.shouldRefresh,
    } as breeze.SaveOptionsConfiguration);
  }
}

function removeWarningsForProperties(entityManager: EntityManager, ignoreWarningsProperties?: string[]): void {
  entityManager.getEntities().map((entity) => {
    const notifications = Notifications.get(entity);

    notifications
      ?.alerts()
      .filter((alert) => isAlertToIgnore(alert, ignoreWarningsProperties))
      .forEach((alert) => alert.acknowledged?.(true));
    notifications?.removeAll((alert) => isAlertToIgnore(alert, ignoreWarningsProperties));
  });
}

function setNotificationAlerts(entityManager: EntityManager, alerts: Record<string, AlertDetails[]>): boolean {
  let hasAlert = false;
  for (const bucketKey in alerts) {
    const bucket = alerts[bucketKey];
    const alert: AlertDetails = bucket[0];
    let entity;
    try {
      entity = entityManager.getEntityByKey(alert.entityName as string, alert.entityPK);
    } catch (e) {
      // Don't care.
    }

    if (!entity) {
      continue;
    }

    Notifications.get(entity)?.pushAll(bucket);
    hasAlert = true;
  }

  return hasAlert;
}

async function validateUnsavedEntitiesAsync(entityManager: EntityManager): Promise<boolean> {
  const entities = entityManager.getEntities(undefined, [breeze.EntityState.Modified, breeze.EntityState.Added]);
  const results = await Promise.all(
    entities.map(async (entity) => {
      return await validationEngine.validateEntityAsync(entity, undefined, [
        NotificationType.Error,
        NotificationType.Warning,
      ]);
    }),
  );

  return results.every(Boolean);
}

export default new EntitySaveService();
