import type { Entity } from "BreezeExtensions";
import { inject, provide, type Ref } from "vue";

type BindingContextRef = Ref<Entity>;
export const bindingContextInjectionKey = Symbol("bindingContext");

export function useBindingContext(): BindingContextRef | undefined {
  return inject<BindingContextRef | undefined>(bindingContextInjectionKey, undefined);
}

export function setBindingContext(value: BindingContextRef): void {
  provide(bindingContextInjectionKey, value);
}
