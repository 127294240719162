import base64 from 'base64';
import captionService from 'CaptionService';
import { DeferredPromise } from 'DeferredPromise';
import dialogService from 'DialogService';
import errorReportingHelper from 'ErrorReportingHelper';
import errorReportingService from 'ErrorReportingService';
import global from 'Global';
import { withErrorStateHandling } from 'GlobalBusyStateTracker';
import $ from 'jquery';
import ko from 'knockout';
import NotificationType from 'NotificationType';
import { htmlEscape } from 'StringUtils';
import materialDesignDialogService from 'MaterialDesignDialogService';

class ErrorReportingDialogService {
	showErrorDialog(error) {
		const errorID = errorReportingService.getErrorID();
		if (this.errorDeferred) {
			sendErrorReportWhenFinishedAsync(error, this.errorDeferred.promise, errorID);
		} else {
			this.errorDeferred = new DeferredPromise();
			sendErrorReportWhenFinishedAsync(error, this.errorDeferred.promise, errorID);
			showErrorReporterDialogAsync(this, error, errorID);
		}
	}
}

async function showErrorReporterDialogAsync(service, error, errorID) {
	return await withErrorStateHandling(async () => {
		if (materialDesignDialogService.canShowErrorReportingDialog()) {
			const description = await materialDesignDialogService.showErrorReportingDialogAsync(
			formatError(error),
			getEnvironment(),
			errorID
			);
			service.errorDeferred.resolve(description);
			service.errorDeferred = null;
		} else {
			await showKnockoutErrorDialogAsync(service, error, errorID);
		}
	});
  }

async function sendErrorReportWhenFinishedAsync(error, deferredPromise, errorID) {
	const description = await deferredPromise;
	errorReportingService.sendErrorReport(error, description, errorID);
}

function getEnvironment() {
	return global.environment.toLowerCase();
}

function formatError(error) {
	const messagePieces = errorReportingHelper.getReportableErrors(error).map((error) => {
		/*! SuppressStringValidation String validation suppressed in initial refactor */
		return 'Message: ' + error.message + '\r\n\r\nStack Trace:\r\n' + error.stack;
	});

	const fullMessage = messagePieces.join('\r\n\r\n\r\n');
	const messageBytes = [];
	const taintedBytes = base64.decode(base64.encode(fullMessage));
	for (let j = 1; j < taintedBytes.length; j += 2) {
		messageBytes.push(taintedBytes[j]);
	}
	return base64.encode(messageBytes);
}

async function showKnockoutErrorDialogAsync(service, error, errorID) {
	let dialogInfoPromise = null;
	let dialog = null;
	const environment = getEnvironment();
	const shouldShowDeveloperInfo = environment === 'development' || environment === 'uat';

	const viewModel = {
		displayDeveloperMessageDetails() {
			const base64String = formatError(error);
			this.debugBase64('data:text/plain;encoding=utf-8;base64,' + base64String);
		},
		// https://ourcodeworld.com/articles/read/682/what-does-the-not-allowed-to-navigate-top-frame-to-data-url-javascript-exception-means-in-google-chrome
		debugBase64(base64URL) {
			const win = window.open();
			win.document.write(
				'<iframe src="' +
				base64URL +
				'" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
			);
		},
		shouldShowDeveloperInfo,
		errorID,
		description: ko.observable(''),
		submit: () => {
			const description = reporterOptions.viewModel.description();
			if (description.length >= 10) {
				dialogService.hide(dialog);
				service.errorDeferred.resolve(description);
				service.errorDeferred = null;
			} else {
				const message = htmlEscape(captionService.getString('cfaffe73-00cd-4fa7-89fb-f877dad15c2f', 'Please describe, in as much detail as possible, what you were doing prior to this error occurring.')) + '<br>' +
					htmlEscape(captionService.getString('a96a7dad-ba95-49a8-87d5-3f5947646686', 'You must enter at least 10 characters.')) + '<br>' +
					htmlEscape(captionService.getString('888168e5-e993-438e-bc4d-c4d5c35c16a4', 'By doing this, WiseTech Global will be able to provide a resolution much more quickly and prevent this error from happening again.'));
				dialogService.showDialogAlertAsync(
					dialogInfoPromise,
					message,
					NotificationType.Error,
					true
				);
			}
		},
	};

	/*! SuppressStringValidation (html element tags) */
	const dialogContainer = 'body';

	const reporterOptions = {
		autoresize: true,
		viewModel,
		title: captionService.getString('dbda3f77-8547-4aa8-a8c0-5684f84da00a', 'An Error Has Occurred'),
		dialogType: dialogService.dialogTypes.ReportError,
		bodyAllowHtml: true,
		closeOnDismissOnly: true,
		dialogContainer,
	};

	const buttonOptions = [{
		bindingString: 'click: submit',
		caption: captionService.getString('740c8baa-0b30-415f-b2c3-cd0fbe7ea7ae', 'Continue'),
		isPrimary: true,
		isDefault: true
	}];

	reporterOptions.body = $('#ErrorReporter').html();
	reporterOptions.buttonOptions = buttonOptions;

	dialogInfoPromise = dialogService.showDialogAsync(reporterOptions);
	dialog = await dialogInfoPromise;
}

export default new ErrorReportingDialogService();
