/* eslint-disable rulesdir/async-function-suffix */
import type { ActivityBasedFormFlow, ActivityBasedFormFlowFactory, FormFlowVariable } from "@wtg-glow/form-flow-engine";
import AsyncLock from "AsyncLock";
import { type EntityManager } from "BreezeExtensions";
import FormFlowSession, { type SaveListener, type StateListener, type VariableListener } from "FormFlowSession";
import type { ExtendedActivityBasedFormFlowDefinition } from "FormFlowTypes";
import uiContextProvider, { Context } from "FormFlowUIContextProvider";
import formTemplateService, { type FormTemplate } from "FormTemplateService";
import type { TaskShellOptions } from "TaskPresenter";
import { isVariableStrategy, makeStrategy, type FormFlowVariableStrategy } from "VariableStrategyFactory";

export type FormFlowInstanceOptions = {
  uiContextOrOptions: Context | TaskShellOptions;
  entityManagers?: EntityManager[];
  entityManagersLock?: AsyncLock;
  sessionVariables?: { [key: string]: FormFlowVariable };
  abortToken?: { isAborted: boolean };
  semaphoreTokens?: Map<string, string>;
  rootSession?: FormFlowSession;

  // temporary flag to maintain backward compatibility with code that handles save explicitly
  shouldSaveOnComplete?: boolean;

  // todo: check if this is still used
  clientPK?: string;

  // todo: check if we can have a better solution than attaching listeners
  variableListener?: VariableListener;
  stateListener?: StateListener;
  saveListener?: SaveListener;
};

export class FormFlowSessionFactory implements ActivityBasedFormFlowFactory {
  private readonly _sessions: Set<FormFlowSession>;

  constructor() {
    this._sessions = new Set();
  }

  get sessions(): ReadonlyArray<FormFlowSession> {
    return Array.from(this._sessions);
  }

  build(
    definition: ExtendedActivityBasedFormFlowDefinition,
    input: Record<string, unknown> | undefined,
    options: FormFlowInstanceOptions | FormFlowSession,
  ): FormFlowSession {
    let session: FormFlowSession;

    if (isFormFlowSession(options)) {
      session = options;
    } else {
      session = buildFormFlowSession(definition, input, options);
      this._sessions.add(session);
    }

    return session;
  }

  async dispose(formFlow: ActivityBasedFormFlow): Promise<void> {
    if (!isFormFlowSession(formFlow)) {
      throw new Error("This type of form flow does not support dispose");
    }

    if (this._sessions.has(formFlow)) {
      this._sessions.delete(formFlow);
      await formFlow.dispose();
    }
  }
}

export function buildFormFlowSession(
  definition: ExtendedActivityBasedFormFlowDefinition,
  input: Record<string, unknown> | undefined,
  options: FormFlowInstanceOptions,
): FormFlowSession {
  const [uiContext, shouldDisposeUiContext] = buildUiContext(definition.PK, options);
  const inputStrategies = Object.values(input ?? {}).reduce<FormFlowVariableStrategy[]>((values, value) => {
    if (value) {
      values.push(isVariableStrategy(value) ? value : makeStrategy(value));
    }
    return values;
  }, []);

  return new FormFlowSession(
    definition,
    inputStrategies,
    options.entityManagers ?? [],
    options.entityManagersLock ?? new AsyncLock(),
    uiContext,
    options.sessionVariables ?? {},
    options.abortToken ?? { isAborted: false },
    options.semaphoreTokens ?? new Map(),
    options.rootSession,
    shouldDisposeUiContext,
    options.shouldSaveOnComplete,
    options.variableListener,
    options.stateListener,
    options.saveListener,
  );
}

export function isFormFlowSession(formFlow: object): formFlow is FormFlowSession {
  return formFlow instanceof FormFlowSession;
}

function buildUiContext(formFlowId: string, options: FormFlowInstanceOptions): [Context, boolean] {
  if (options.uiContextOrOptions instanceof Context) {
    return [options.uiContextOrOptions, false];
  }

  const formProviderAsync = (pk: string): Promise<FormTemplate> =>
    formTemplateService.getFormTemplateAsync(pk, { parentID: formFlowId, clientPK: options.clientPK });

  const context = options.uiContextOrOptions.isDialog
    ? uiContextProvider.createDialogContext(formProviderAsync, options.uiContextOrOptions)
    : uiContextProvider.createPageContext(formProviderAsync, options.uiContextOrOptions);

  return [context, true];
}
