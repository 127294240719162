import type { NotificationType } from "NotificationType";

export default class RuleValidationResult {
  readonly Text: string;
  readonly Level: NotificationType;

  constructor(text: string, level: NotificationType) {
    this.Text = text;
    this.Level = level;
  }
}
