import { type EntityQuery, type EntityType, FilterQueryOp, Predicate } from "breeze-client";

class EntityInfoActiveStateDecorator {
  decorateQuery(query: EntityQuery, entityType: EntityType): EntityQuery {
    if (entityType && entityType.isActivePropertyName) {
      const isCancellable = entityType.isCancellable as boolean;
      if (!containsActiveStatePredicate(query.wherePredicate)) {
        const activeStatePredicate = getActiveStatePredicate(isCancellable);
        if (activeStatePredicate) {
          query = query.where(activeStatePredicate);
        }
      }
    }

    return query;
  }

  decorateGlobalQuery(query: EntityQuery): EntityQuery {
    if (!containsActiveStatePredicate(query.wherePredicate)) {
      const globalActiveStatePredicate = getGlobalActiveStatePredicate();
      return query.where(globalActiveStatePredicate);
    }
    return query;
  }
}

const isActive = "ISACTIVE";
const isCancelled = "ISCANCELLED";

function containsActiveStatePredicate(predicate: Predicate): boolean {
  if (predicate) {
    if (predicate.preds) {
      return predicate.preds.some((inner: Predicate) => containsActiveStatePredicate(inner));
    }

    const expr1Source = predicate.expr1Source;
    if (expr1Source) {
      return expr1Source === isCancelled || expr1Source === isActive;
    }
  }

  return false;
}

function getActiveStatePredicate(isCancellable: boolean): Predicate {
  if (isCancellable) {
    return new Predicate(isCancelled, FilterQueryOp.Equals, false).or(isCancelled, FilterQueryOp.Equals, null);
  }

  return new Predicate(isActive, FilterQueryOp.Equals, true).or(isActive, FilterQueryOp.Equals, null);
}

function getGlobalActiveStatePredicate(): Predicate {
  const isActivePredicate = new Predicate(isActive, FilterQueryOp.Equals, true).and(
    isActive,
    FilterQueryOp.NotEquals,
    null,
  );
  const isCancelledPredicate = new Predicate(isCancelled, FilterQueryOp.Equals, false).and(
    isCancelled,
    FilterQueryOp.NotEquals,
    null,
  );
  const noActiveOrCancelledPredicate = new Predicate(isActive, FilterQueryOp.Equals, null).and(
    isCancelled,
    FilterQueryOp.Equals,
    null,
  );

  return isActivePredicate.or(isCancelledPredicate).or(noActiveOrCancelledPredicate);
}

export default new EntityInfoActiveStateDecorator();
