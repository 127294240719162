import captionService from 'CaptionService';
import dialogService from 'DialogService';
import materialDesignNotesDialogService from 'MaterialDesignNotesDialogService';
import { NotesEventType, NotesViewModel } from 'NotesViewModel';
import alertDialogService, { SaveDialogResult } from 'AlertDialogService';
import { format } from 'StringUtils';
import notesDialogTemplate from 'WidgetTemplates/NotesPage.html';
/*! SuppressStringValidation Property name */
const notesPropertyName = 'Notes';

function shouldShowNotes(entityType) {
	const notes = entityType.getNavigationProperty(notesPropertyName);
	return !!notes && notes.entityType.baseEntityType.interfaceName === 'INote';
}

async function showNotesAsync(entityKey, onClosed) {
	const viewModel = new NotesViewModel(entityKey);
	await viewModel.loadEntityAsync();

	const entity = viewModel.entity();

	if (entity) {
		if (materialDesignNotesDialogService.canShowDialog()) {
			const state = {};
			setUpMaterialDesignNotesDialogEvents(viewModel.messageBus, state);
			return materialDesignNotesDialogService.showNotesAsync(entity, viewModel, state);
		}

		const dialogInfo = await showNotesCoreAsync(viewModel, onClosed);
		setUpNotesViewModelEvents(viewModel, () => {
			dialogService.hide(dialogInfo);
		});

		return dialogInfo;
	} else {
		warnEntityNotLoadedAsync();
	}
}

function setUpMaterialDesignNotesDialogEvents(messageBus, state) {
	messageBus.subscribe(NotesEventType.Close, () => {
		materialDesignNotesDialogService.hideDialogVue(state);
	});

	messageBus.subscribe(NotesEventType.ConfirmClose, async () => {
		const leave = await alertDialogService.warnStayLeaveUnsavedChangesAsync();

		if (leave) {
			materialDesignNotesDialogService.hideDialogVue(state);
		}
	});
}

function setUpNotesViewModelEvents(viewModel, closeDialog) {
	viewModel.messageBus.subscribe(NotesEventType.Close, closeDialog);

	viewModel.messageBus.subscribe(NotesEventType.ConfirmClose, async () => {
		const result = await alertDialogService.confirmSaveDiscardCancelAsync();

		if (result === SaveDialogResult.Save) {
			return await viewModel.saveAndCloseAsync();
		} else if (result === SaveDialogResult.Discard) {
			closeDialog();
			return true;
		} else {
			return false;
		}
	});

	viewModel.messageBus.subscribe(NotesEventType.LoadFailure, () => {
		closeDialog();
		warnEntityNotLoadedAsync();
	});
}

function showNotesCoreAsync(viewModel, onClosed) {
	const entity = viewModel.entity();
	const noteType = entity.entityType.getNavigationProperty(notesPropertyName).entityType.interfaceName;

	return dialogService.showDialogAsync({
		title: captionService.getString('0BB72C65-AEDF-4270-89DB-38B2D7C70ADA', 'Notes'),
		autoresize: true,
		body: format(notesDialogTemplate, noteType),
		bodyAllowHtml: true,
		buttonOptions: [
			{
				caption: captionService.getString('30f5de01-4c11-49b6-abb2-ab82f73f4b81', 'Cancel'),
				bindingString: 'asyncClick: confirmCloseAsync.bind($data)', // .bind($data) here and below is required for Tablet.
				isVisible: viewModel.hasChanges,
			},
			{
				caption: captionService.getString('983fcb18-a64a-483a-9def-f4f9a67ea78a', 'Close'),
				isVisible: viewModel.hasChanges,
				invertIsVisible: true,
				isDismiss: true,
			},
			{
				caption: captionService.getString('e73687ae-dba7-4b42-b5a7-e0118ec8cd18', 'Save & Close'),
				bindingString: 'asyncClick: saveAndCloseAsync.bind($data)',
				isVisible: viewModel.hasChanges,
			},
			{
				caption: captionService.getString('0a25774a-632f-4842-bf1c-89918d0c6453', 'Save'),
				bindingString: 'asyncClick: saveAsync.bind($data)',
				isVisible: viewModel.hasChanges,
			},
		],
		closeOnDismissOnly: true,
		viewModel,
		includeValidationSummary: true,
		afterHideCallback: () => {
			if (onClosed) {
				onClosed(viewModel.hasSaved);
			}
		},
	});
}

function warnEntityNotLoadedAsync() {
	return alertDialogService.alertAsync(
		captionService.getString(
			'320aaf85-1ce5-4184-9c8f-95c12a208a28',
			'Notes could not be loaded. The parent record may have been deleted.',
		),
		captionService.getString('2715ef75-7f7b-4e18-8a56-e57cd6d32f55', 'Record not found'),
	);
}

// TODO: Change to export named functions once FFv2 stuff using NotesService is removed or converted to ES6.
export default {
	shouldShowNotes,
	showNotesAsync,
};
