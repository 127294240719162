import captionService from 'CaptionService';

export const dialog = {
	apply: () => captionService.getString('fd988512-0651-41d6-a459-47defbd08e5f', 'Apply'),
	cancel: () => captionService.getString('f9b73e66-af30-4e34-bc89-ce03739aab70', 'Cancel'),
	clear: () => captionService.getString('3b0edaf6-57b7-4fb0-a161-8a98aefb49c3', 'Clear'),
	ok: () => captionService.getString('5d884ad1-88ea-49e6-ab48-1874ee0d9347', 'OK'),
	publish: () => captionService.getString('9048834c-1d3f-4933-a766-04247776f91c', 'Publish for all users'),
	reset: () => captionService.getString('eb32a8b1-f5e2-4cdd-b57d-c9d3e701f04e', 'Reset'),
	save: () => captionService.getString('daa99e64-8e59-4812-8fac-4a0e679875c9', 'Save'),
	yes: () => captionService.getString('2d468abd-5d2b-43a8-bf8b-3b4afd1799a6', 'Yes'),
	no: () => captionService.getString('0fc4e9d1-ebe6-4e17-b382-524121e47dd1', 'No'),
	applyChanges: () => captionService.getString('3545d55f-dee1-41a5-8a28-941c143556d3', 'Apply changes'),
};
