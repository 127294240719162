import captionService from 'CaptionService';
import constants from 'Constants';
import global from 'Global';
import materialDesignVueDialogService from 'MaterialDesignVueDialogService';
import {
	loadWorkflowAuditLogsViewModelAsync,
	loadWorkflowEventsViewModelAsync,
	loadWorkflowExceptionsViewModelAsync,
	loadWorkflowMilestonesViewModelAsync,
	loadWorkflowTriggersViewModelAsync,
} from 'ModuleLoader';

class MaterialDesignWorkflowDialogService {
	constructor() {}

	showAuditLogsAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowAuditLogsViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowAuditLogs.Shell,
			captionService.getString('f243cbba-f45b-4ce2-9b1e-be51ff13c14f', 'Change Logs')
		);
	}

	showEventsAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowEventsViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowEvents.Shell,
			captionService.getString('83efa3d0-0bff-46bd-bc90-de62425a74d8', 'Workflow Events')
		);
	}

	showMilestonesAsync(entity, saveableViewModel) {
		const captions = {
			lastEventLabel: captionService.getString(
				'4c29e36d-3437-498c-890b-0a26e0c53ceb',
				'Last Event'
			),
			eventAt: captionService.getString('bc0c5abd-18cd-4e57-a2ae-26e62fb6463c', ' at '),
		};
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowMilestonesViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowMilestones.Shell,
			captionService.getString('180d2e9f-4687-4dc2-aefb-7e675bd6dae3', 'Workflow Milestones'),
			captions
		);
	}

	showWorkflowTriggersAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowTriggersViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowTriggers.Shell,
			captionService.getString('0f85d8b3-c5e6-4ef4-9a05-92573a6fe664', 'Workflow Triggers')
		);
	}

	showExceptionsAsync(entity, saveableViewModel) {
		return showWorkflowEntityDialogAsync(
			entity,
			saveableViewModel,
			loadWorkflowExceptionsViewModelAsync,
			constants.MaterialDesignPageTypes.WorkflowExceptions.Shell,
			captionService.getString('36756326-df92-40b3-92c5-2f3c056345d5', 'Exceptions')
		);
	}

	canShowDialog() {
		return Boolean(
			global.featureFlags.enableGridSupplyDialogs &&
			materialDesignVueDialogService.canShowDialog()
		);
	}
}

async function showWorkflowEntityDialogAsync(
	entity,
	saveableViewModel,
	loadWorkflowEntityAsync,
	template,
	title,
	captions
) {
	const state = {};
	const result = await trySaveIfRequired(saveableViewModel);
	if (result) {
		const viewModelLoader = await loadWorkflowEntityAsync();
		const viewModel = createViewModel(entity, viewModelLoader, state);
		return await materialDesignVueDialogService.showDialogAsync(
			entity,
			state,
			template,
			viewModel,
			title,
			captions
		);
	}
}

function trySaveIfRequired(saveableViewModel) {
	if (saveableViewModel) {
		return saveableViewModel.saveWithPromptAsync();
	}
	return Promise.resolve(true);
}

function createViewModel(entity, ViewModelCtor, state) {
	return new ViewModelCtor(entity.entityType, entity.entityAspect.getPrimaryKey(), {
		onCloseCallback() {
			materialDesignVueDialogService.hideDialogVue(state);
		},
	});
}

export default new MaterialDesignWorkflowDialogService();
