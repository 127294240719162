import type { AuthenticationSource } from "AuthenticationSource";
import type { Disposable } from "Disposable";
import global from "Global";
import log from "Log";
import { clearSessionStorageAsync } from "PersistentStorage";
import Storage, { clearDeprecatedStorage, clearOppositeModeStorage } from "Storage";
import { isNullOrWhitespace } from "StringUtils";
import { Subscribable } from "Subscribable";
import { UserType } from "UserTypeConstants";

export interface LatLng {
  lat: number;
  lng: number;
}

export interface User {
  userName: string;
  friendlyName?: string;
  fullName: string;
  email: string;
  title?: string;
  languageCode?: string;
  countryCode?: string;
  isController?: boolean;
  companyPK?: string;
  branchPK?: string;
  branchCode?: string;
  departmentPK?: string;
  departmentCode?: string;
  orgCode?: string;
  orgPK?: string;
  orgName?: string;
  homeLocation?: LatLng;
}

export interface SessionData {
  isAuthenticated?: boolean;
  loggedOnUser?: User | null;
  userName?: string | null;
  userPK?: string | null;
  userCode?: string | null;
  userType?: string | null;
  userEmailAddress?: string | null;
  sessionId?: string | null;
  licenceCode?: string | null;
  moduleAccess?: string[] | null;
  authenticationSource?: AuthenticationSource;
  languageCode?: string;
  entitySetRights?: unknown | null;
  branchPK?: string | null;
  branchCode?: string | null;
  departmentPK?: string | null;
  departmentCode?: string | null;
  homeLocation?: LatLng | null;
  agreements?: unknown | null;
}

export interface StoredSessionData {
  session: SessionData;
}

export class UserSession {
  private _isInvalidated: boolean;
  private _sessionData: SessionData;
  private readonly isLoggedOnSubscribable: Subscribable<boolean>;
  private previouslyShownHelpPages: string[];
  currentPage: Record<string, string>;
  /** @deprecated Use UserTypeConstants instead */
  readonly UserType = UserType;

  constructor() {
    this._isInvalidated = false;
    this._sessionData = getEmptySessionData();
    this.isLoggedOnSubscribable = new Subscribable();
    this.previouslyShownHelpPages = [];
    this.currentPage = {};
  }

  isInvalidated(): boolean {
    return this._isInvalidated;
  }

  sessionData(): SessionData {
    return this._sessionData;
  }

  isLoggedOn(): boolean {
    const sessionData = this._sessionData;
    return (
      !!sessionData.isAuthenticated &&
      !isNullOrWhitespace(sessionData.userCode) &&
      !isNullOrWhitespace(sessionData.sessionId)
    );
  }

  private setSessionData(sessionData: SessionData): void {
    const wasLoggedOn = this.isLoggedOn();
    this._sessionData = sessionData;
    const isLoggedOn = this.isLoggedOn();
    if (isLoggedOn !== wasLoggedOn) {
      this.isLoggedOnSubscribable.notifySubscribers(isLoggedOn);
    }
  }

  onIsLoggedOnChanged(callback: (value: boolean) => void): Disposable {
    return this.isLoggedOnSubscribable.subscribe(callback);
  }

  getSessionStorageKey(): string {
    return global.servicePath;
  }

  getEntitySetRightsStorageKey(): string {
    return global.servicePath + "entitySetRights";
  }

  clearSessionAsync(): Promise<void> {
    log.withTag("UserSession").info("UserSession.clearSessionAsync");

    Storage.clearAll();
    clearDeprecatedStorage();
    this.setSessionData(getEmptySessionData());
    this.previouslyShownHelpPages = [];
    this._isInvalidated = false;
    return clearSessionStorageAsync();
  }

  saveSession(sessionData: SessionData): void {
    log.withTag("UserSession").info("UserSession.saveSession");

    clearOppositeModeStorage();

    const session = Object.assign(getEmptySessionData(), sessionData);
    const sessionCopy = Object.assign({}, session, { entitySetRights: null });
    Storage.set(this.getSessionStorageKey(), { session: sessionCopy });
    Storage.set(this.getEntitySetRightsStorageKey(), {
      entitySetRights: session.entitySetRights,
    });

    this.setSessionData(session);
  }

  loadSession(): void {
    log.withTag("UserSession").info("UserSession.loadSession");

    const storedSessionData = Storage.get<StoredSessionData>(this.getSessionStorageKey());
    if (storedSessionData) {
      let sessionData = this.parseSessionData(storedSessionData);

      const entitySetRightsData = Storage.get<StoredSessionData>(this.getEntitySetRightsStorageKey());

      if (sessionData && entitySetRightsData) {
        sessionData = { ...sessionData, ...entitySetRightsData };
      }

      if (sessionData) {
        this.setSessionData({ ...getEmptySessionData(), ...sessionData });
      }
    }
  }

  parseSessionData(data: StoredSessionData): SessionData {
    return data ? data.session : data;
  }

  hasShownHelpPage(linkedScreenIdentifier: string): boolean {
    return this.previouslyShownHelpPages.some((id: string) => id === linkedScreenIdentifier);
  }

  markHelpPageAsShown(linkedScreenIdentifier: string): void {
    if (this.previouslyShownHelpPages.indexOf(linkedScreenIdentifier) === -1) {
      this.previouslyShownHelpPages.push(linkedScreenIdentifier);
    }
  }

  canAccessModule(): boolean {
    if (!this.isLoggedOn()) {
      return false;
    }

    const moduleAccess = this.sessionData().moduleAccess ?? [];
    const moduleName = global.moduleName.toUpperCase();

    return moduleAccess.some((m) => m.toUpperCase() === moduleName);
  }

  invalidate(): void {
    this._isInvalidated = true;
  }
}

function getEmptySessionData(): SessionData {
  const value = {
    isAuthenticated: undefined,
    loggedOnUser: null,
    userName: null,
    userPK: null,
    userCode: null,
    userType: null,
    userEmailAddress: null,
    sessionId: null,
    licenceCode: null,
    moduleAccess: null,
    languageCode: undefined,
    entitySetRights: null,
    branchPK: null,
    branchCode: "",
    departmentPK: null,
    departmentCode: "",
    homeLocation: null,
    agreements: null,
  };

  return value;
}

export default new UserSession();
