/* eslint-disable rulesdir/async-function-suffix */
import { ActivityBasedFormFlow } from "@wtg-glow/form-flow-engine";
import { getActivityInvokerAsync, type ActivityResult } from "ActivityInvokerProvider";
import { addWithoutMutating, removeWithoutMutating } from "ArrayUtils";
import type AsyncLock from "AsyncLock";
import { type Entity, type EntityManager } from "BreezeExtensions";
import captionService from "CaptionService";
import connection from "Connection";
import { getInterfaceName } from "EntityExtensions";
import { observeHasChanges } from "EntityManagerExtensions";
import { setSessionVariables } from "EntityManagerFormFlowSessionVariables";
import entitySaveService, { type EntitySaveResult } from "EntitySaveService";
import { getGenericTypeArgument } from "EntityTypeUtils";
import { AmbiguousModelError, FormFlowAbortError, type ErrorData } from "Errors";
import { FormFlowError, FormFlowErrorType, withFormFlowErrorStackAsync } from "FormFlowError";
import type { FormFlowInstanceOptions } from "FormFlowSessionFactory";
import {
  type ExtendedActivityBasedFormFlowDefinition,
  type FormFlowActivity,
  type FormFlowVariable,
} from "FormFlowTypes";
import { type Context } from "FormFlowUIContextProvider";
import log from "Log";
import ModelProvider from "ModelProvider";
import { NotificationType } from "NotificationType";
import { SaveEntityError, SaveEntityErrorType } from "SaveEntityError";
import semaphoreProvider, { type SemaphoreUsage } from "SemaphoreProvider";
import toastService from "ToastService";
import type { OkIfNotFound } from "UtilityTypes";
import { makeDefaultValueStrategy, makeStrategy, type FormFlowVariableStrategy } from "VariableStrategyFactory";

export interface VariableChangeEvent<T = unknown> {
  session: FormFlowSession;
  name: string;
  value: T;
}

export type VariableListener<T = unknown> = (event: VariableChangeEvent<T>) => void;
export type StateListener<T = unknown> = (key: string) => T;
export type SaveListener = (event: { entityManager: EntityManager }) => Promise<EntitySaveResult> | void;

export class FormFlowSession extends ActivityBasedFormFlow {
  private outParameterNames: string[];
  readonly rootSession: FormFlowSession | undefined;
  private entityManagers: EntityManager[];
  private entityManagersLock: AsyncLock;
  private startActivityId = "";
  readonly definitionPK: string;
  private sessionVariables: Record<string, FormFlowVariable> = {};
  private abortToken: { isAborted: boolean };
  private semaphoreTokens: Map<string, string>;
  private saveListeners: SaveListener[];
  private variableListeners: VariableListener[];
  private getStateListeners: StateListener[];
  private variableDefinitions: { [key: string]: FormFlowVariable } = {};
  private _uiContext: Context;
  private readonly shouldDisposeUiContext: boolean;
  private readonly shouldSaveOnComplete: boolean;
  readonly modelProvider: ModelProvider;
  private _drawerSession: FormFlowSession | undefined;

  get uiContext(): Context {
    return this._uiContext;
  }

  set uiContext(uiContext: Context) {
    this._uiContext = uiContext;
  }

  get drawerSession(): FormFlowSession | undefined {
    const rootSession = this.rootSession ?? this;
    return rootSession._drawerSession;
  }

  set drawerSession(session: FormFlowSession | undefined) {
    const rootSession = this.rootSession ?? this;
    rootSession._drawerSession = session;
  }

  constructor(
    definition: ExtendedActivityBasedFormFlowDefinition,
    argumentStrategies: FormFlowVariableStrategy[],
    entityManagers: EntityManager[],
    entityManagersLock: AsyncLock,
    uiContext: Context,
    sessionVariables: { [key: string]: FormFlowVariable } = {},
    abortToken: { isAborted: boolean },
    semaphoreTokens: Map<string, string>,
    rootSession?: FormFlowSession,
    shouldDisposeUiContext?: boolean,
    shouldSaveOnComplete?: boolean,
    variableListener?: VariableListener,
    stateListener?: StateListener,
    saveListener?: SaveListener,
  ) {
    super(definition);
    this.outParameterNames = [];

    if (definition.Parameters) {
      definition.Parameters.forEach((x) => {
        this.variableDefinitions[x.Name] = x;
      });
    }

    if (definition.LocalVariables) {
      definition.LocalVariables.forEach((x) => {
        this.variableDefinitions[x.Name] = x;
      });
    }
    if (definition.OutParameters) {
      definition.OutParameters.forEach((x) => {
        this.variableDefinitions[x.Name] = x;
        this.outParameterNames.push(x.Name);
      });
    }

    this.setArgumentStrategies(definition.Parameters || [], argumentStrategies);

    this.rootSession = rootSession;
    this.modelProvider = new ModelProvider(
      definition.DefaultModel,
      definition.EntityTypeName,
      rootSession && rootSession.modelProvider,
    );
    this.entityManagers = entityManagers;
    this.entityManagersLock = entityManagersLock;

    this.definitionPK = definition.PK;

    this._uiContext = uiContext;
    this.sessionVariables = sessionVariables;
    this.abortToken = abortToken;
    this.semaphoreTokens = semaphoreTokens;

    this.saveListeners = [];
    this.variableListeners = [];
    this.getStateListeners = [];

    this.shouldDisposeUiContext = shouldDisposeUiContext ?? false;
    this.shouldSaveOnComplete = shouldSaveOnComplete ?? false;

    if (variableListener) {
      this.addVariableListener(variableListener);
    }

    if (stateListener) {
      this.addGetStateListener(stateListener);
    }

    if (saveListener) {
      this.addSaveListener(saveListener);
    }
  }

  hasChanges(): boolean {
    return this.entityManagers.some((e) => observeHasChanges(e));
  }

  async getEntityManagerAsync<TOkIfNotFound extends boolean | undefined = undefined>(
    entityTypeName: string,
    optionalRouteName?: string,
    okIfNotFound?: TOkIfNotFound,
  ): Promise<OkIfNotFound<TOkIfNotFound, EntityManager>> {
    const { entityManagers: entityManagers, modelProvider } = this;

    return await this.entityManagersLock.doAsync(async () => {
      const parentType = getGenericTypeArgument(entityTypeName);
      entityTypeName = parentType ?? entityTypeName;
      let entityManager = getExistingEntityManager(entityTypeName);
      if (entityManager) {
        return entityManager;
      }

      try {
        entityManager = (await modelProvider.createEntityManagerAsync(
          entityTypeName,
          optionalRouteName,
          okIfNotFound,
        )) as OkIfNotFound<TOkIfNotFound, EntityManager>;
      } catch (error) {
        if (error instanceof AmbiguousModelError) {
          throw new FormFlowError(error.message);
        }
        throw error;
      }
      if (entityManager) {
        setSessionVariables(entityManager, this.sessionVariables);
        entityManagers.push(entityManager);
      }
      return entityManager as OkIfNotFound<TOkIfNotFound, EntityManager>;
    });

    function getExistingEntityManager(entityTypeName: string): EntityManager | undefined {
      return entityManagers
        .filter((e) => e.metadataStore.getEntityType(entityTypeName, /*okIfNotFound:*/ true))
        .sort((a, b) => getPriority(a) - getPriority(b))[0];
    }

    function getPriority(entityManager: EntityManager): 0 | 1 | 2 | 3 {
      const { metadataStore } = entityManager;
      if (optionalRouteName && metadataStore.getRouteName() === optionalRouteName) {
        return 0;
      } else if (modelProvider.routeName && metadataStore.getRouteName() === modelProvider.routeName) {
        return 1;
      } else if (
        modelProvider.mainEntityTypeName &&
        metadataStore.getEntityType(modelProvider.mainEntityTypeName, /*okIfNotFound:*/ true)
      ) {
        return 2;
      } else {
        return 3;
      }
    }
  }

  async discardEntityManagersAsync(): Promise<void> {
    return await this.entityManagersLock.doAsync(() => {
      this.entityManagers.length = 0;
    });
  }

  hasEntityManager(entityManager: EntityManager): boolean {
    return this.entityManagers.includes(entityManager);
  }

  async saveAsync({
    shouldShowAlert = true,
    shouldRefresh = true,
    shouldReconcileConflicts = true,
  } = {}): Promise<EntitySaveResult> {
    return await this.saveCoreAsync(shouldShowAlert, shouldRefresh, shouldReconcileConflicts);
  }

  private saveCoreAsync(
    shouldShowAlert: boolean,
    shouldRefresh: boolean,
    shouldReconcileConflicts: boolean,
  ): Promise<EntitySaveResult> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    return this.entityManagersLock.doAsync(async () => {
      return await this.saveOneAsync(shouldShowAlert, shouldRefresh, shouldReconcileConflicts, 0);
    });
  }

  private async saveOneAsync(
    shouldShowAlert: boolean,
    shouldRefresh: boolean,
    shouldReconcileConflicts: boolean,
    index: number,
  ): Promise<EntitySaveResult> {
    if (index >= this.entityManagers.length) {
      return { isSaved: true, error: null };
    }

    const saveFunction = shouldShowAlert
      ? entitySaveService.saveWithAlertsAsync
      : entitySaveService.saveWithoutAlertsAsync;

    const entityManager = this.entityManagers[index];

    log.info(
      `[Form-flow] saving form-flow ${this.definitionPK} - route: ${entityManager.metadataStore.getRouteName()}`,
    );

    let result: EntitySaveResult = { isSaved: false, error: null };
    try {
      result = await saveFunction.call(entitySaveService, entityManager, {
        shouldReconcileConflicts,
        shouldRefresh,
        shouldShowDialog: (errorType: SaveEntityErrorType) => knownSaveEntityErrorTypes.has(errorType),
      });
    } catch (error: unknown) {
      if (error instanceof connection.OfflineError) {
        throw error;
      } else if (error instanceof SaveEntityError) {
        handleUnknownSaveError(error);
      }
    }

    if (result.isSaved) {
      result = await this.notifySaveListenersAsync({ entityManager });
      if (result.isSaved) {
        // if should refresh, remove the head of the list, and continue to save the 0th item
        // else, keep the entity manager in the list, and continue to save the next item
        if (shouldRefresh) {
          this.entityManagers.shift();
        } else {
          ++index;
        }

        return this.saveOneAsync(shouldShowAlert, shouldRefresh, shouldReconcileConflicts, index);
      }
    } else if (result?.error && !knownSaveEntityErrorTypes.has(result.error.type)) {
      handleUnknownSaveError(result.error);
    }

    return result;

    function handleUnknownSaveError(error: SaveEntityError): void {
      const friendlyCaption = captionService.getString("6bf8b145-403d-429c-ad3d-48e3073787c0", "Form-flow save error");
      let friendlyMessage, type;
      if (error?.type === SaveEntityErrorType.DatabaseIsUpgrading) {
        type = FormFlowErrorType.NonReportableRuntimeError;
        friendlyMessage = captionService.getString(
          "700b4235-b314-4b36-9f18-beb3caff4668",
          "The database is currently being upgraded. The result of the save is unknown, and so the form-flow will exit. Please try again later.",
        );
      } else {
        type = FormFlowErrorType.ReportableRuntimeError;
        friendlyMessage = captionService.getString(
          "276ed5b4-2dbe-4dc2-a0b7-0b87c25f0239",
          "An unexpected error occurred while saving. The result of the save is unknown, and so the form-flow will exit. The error has been automatically reported.",
        );
      }
      /*! SuppressStringValidation Error message */
      throw new FormFlowError("Unhandled error occurred while saving form-flow session.", {
        type,
        cause: error,
        friendlyCaption,
        friendlyMessage,
      });
    }
  }

  override setVariableValue(name: string, value: unknown): void {
    super.setVariableValue(this.getVariable(name).Name, makeStrategy(value));
    this.notifyVariableListeners({ session: this, name, value });
  }

  setVariableStrategy(name: string, strategy: FormFlowVariableStrategy): void {
    super.setVariableValue(this.getVariable(name).Name, strategy);
  }

  unsetVariable(name: string): void {
    this.variables.delete(this.getVariable(name).Name);
  }

  async getVariableValueAsync<T = unknown>(name: string): Promise<T> {
    const variable = this.getVariable(name);
    const value = await this.getVariableStrategy(variable)(this, variable);
    this.notifyVariableListeners({ session: this, name, value });
    return value as T;
  }

  getVariableNames(): string[] {
    return Object.keys(this.variableDefinitions);
  }

  getVariableTypeName(name: string): string | undefined {
    const variable = this.getVariable(name);
    return variable.VariableTypeName;
  }

  getVariableStrategies(names: string[]): FormFlowVariableStrategy[] {
    return names.map((name) => this.getVariableStrategy(this.getVariable(name)));
  }

  getDefinitionId(): string {
    return this.definitionPK;
  }

  private getVariable(name: string): FormFlowVariable {
    const variable = this.variableDefinitions[name];
    if (variable) {
      return variable;
    } else {
      throw new FormFlowError(`Expected variable named ${name}, but did not exist.`);
    }
  }

  private getVariableStrategy(variable: FormFlowVariable): FormFlowVariableStrategy {
    const strategy = this.variables.get(variable.Name) as FormFlowVariableStrategy;
    if (strategy) {
      return strategy;
    } else {
      return makeDefaultValueStrategy();
    }
  }

  private setArgumentStrategies(parameters: FormFlowVariable[], strategies: FormFlowVariableStrategy[]): void {
    if (parameters.length !== strategies.length) {
      if (strategies.length > parameters.length) {
        strategies.slice(0, parameters.length);
      } else {
        // note that we could reach this point given a bad URI (#/formFlow/x/y where x is the id for a definition without a parameter)
        /*! SuppressStringValidation Error messages are not translatable */
        const message = `Expected matching parameter and argument counts, but was ${parameters.length} and ${strategies.length}, respectively.`;
        throw new FormFlowError(message, {
          type: FormFlowErrorType.NonReportableRuntimeError,
          friendlyMessage: captionService.getString(
            "c48f6c58-f3ec-4b77-8f97-05ec9c81c8c7",
            "An error occurred while running this form-flow. This may be due to an invalid URI.",
          ),
        });
      }
    }

    for (let i = 0; i < parameters.length; ++i) {
      const parameterName = parameters[i].Name;
      const strategy = strategies[i];
      this.setVariableStrategy(parameterName, strategy);
    }
  }

  invokeActivityAsync(activityId: string): Promise<ActivityResult | undefined> {
    return this.invokeActivity(activityId);
  }

  override findStartActivityId(): string {
    log.info(`[Form-flow] starting form-flow ${this.definitionPK}`);

    if (!this.startActivityId) {
      const startActivities = this.formFlowDefinition.Activities.filter((x) => x.Kind === "Start");
      if (startActivities.length !== 1) {
        /*! SuppressStringValidation Error messages are not translatable */
        throw new FormFlowError("Expected exactly one start activity.");
      }

      this.startActivityId = startActivities[0].Id;
    }

    return this.startActivityId;
  }

  override async invokeActivity(activityId: string): Promise<ActivityResult | undefined> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    const activity = this.activities.get(activityId);
    if (activity) {
      return await this.invokeActivityCore(activity);
    } else {
      const error = new FormFlowError(`Expected activity with ID ${activityId}, but did not exist.`);
      /*! StartNoStringValidationRegion No captions here! */
      error.getData = (): ErrorData[] => [
        { name: "definitionPK", value: this.definitionPK },
        { name: "activities", value: JSON.stringify(this.formFlowDefinition.Activities) },
      ];
      /*! EndNoStringValidationRegion */
      throw error;
    }
  }

  protected async invokeActivityCore(activity: FormFlowActivity): Promise<ActivityResult | undefined> {
    const activityInvoker = await getActivityInvokerAsync<FormFlowActivity>(activity.Kind);
    if (activityInvoker) {
      log.info(`[Form-flow] invoking form-flow ${this.definitionPK} - activity ${activity.Id} (${activity.Kind})`);
      const stackFrame = `in activity ${activity.Id} (${activity.Kind})`;
      return await withFormFlowErrorStackAsync(activityInvoker.bind(null, this, activity), stackFrame);
    } else {
      throw new FormFlowError(`Unexpected activity kind ${activity.Kind}.`);
    }
  }

  get cloneFormFlowOptions(): FormFlowInstanceOptions {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    // sub flow takes entity managers, etc by reference, such that this state and mutations are shared
    return {
      entityManagers: this.entityManagers,
      entityManagersLock: this.entityManagersLock,
      uiContextOrOptions: this._uiContext,
      sessionVariables: this.sessionVariables,
      abortToken: this.abortToken,
      semaphoreTokens: this.semaphoreTokens,
      rootSession: this.rootSession || this,
      variableListener: (event) => this.notifyVariableListeners(event),
      saveListener: (event) => this.notifySaveListenersAsync(event),
      stateListener: (key) => this.getStateAsync(key),
    };
  }

  addSaveListener(callback: SaveListener): void {
    this.saveListeners = addWithoutMutating(this.saveListeners, callback);
  }

  removeSaveListener(callback: SaveListener): void {
    this.saveListeners = removeWithoutMutating(this.saveListeners, callback);
  }

  private async notifySaveListenersAsync(event: { entityManager: EntityManager }): Promise<EntitySaveResult> {
    let errorResult;
    for (const listener of this.saveListeners) {
      if (errorResult) {
        return errorResult;
      }
      const saveResult = await listener(event);
      if (saveResult?.isSaved === false) {
        errorResult = saveResult;
      }
    }
    return errorResult ? errorResult : { isSaved: true, error: null };
  }

  addVariableListener(callback: VariableListener): void {
    this.variableListeners = addWithoutMutating(this.variableListeners, callback);
  }

  removeVariableListener(callback: VariableListener): void {
    this.variableListeners = removeWithoutMutating(this.variableListeners, callback);
  }

  private notifyVariableListeners(event: VariableChangeEvent): void {
    this.variableListeners.forEach((x) => x(event));
  }

  addGetStateListener(callback: StateListener): void {
    this.getStateListeners = addWithoutMutating(this.getStateListeners, callback);
  }

  removeGetStateListener(callback: StateListener): void {
    this.getStateListeners = removeWithoutMutating(this.getStateListeners, callback);
  }

  async getStateAsync<T>(key: string): Promise<T[]> {
    const states = await Promise.all(
      this.getStateListeners.map(async (listener) => {
        const state = await listener(key);
        return state as T;
      }),
    );
    return states.flatMap((value) => value).filter((value) => value != null);
  }

  abort(): void {
    this._uiContext.abort();
    this.abortToken.isAborted = true;
  }

  async acquireSemaphoreHandleAsync(entityPK: string): Promise<SemaphoreUsage[]> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    if (this.semaphoreTokens.has(entityPK)) {
      return [];
    }

    const semaphoreHandle = await semaphoreProvider.acquireHandleAsync(entityPK);
    if (!semaphoreHandle) {
      return [];
    }

    if (semaphoreHandle.Token) {
      this.semaphoreTokens.set(entityPK, semaphoreHandle.Token);
    }

    return semaphoreHandle.Usages;
  }

  async importEntitiesAsync(entities: Entity[], optionalModelName?: string): Promise<void> {
    if (!Array.isArray(entities)) {
      throw new Error("entities must be an array.");
    }

    const unchangedEntities: Entity[] = [];
    let entityTypeName = "";
    entities.forEach((e) => {
      if (e && e.entityAspect) {
        if (!entityTypeName) {
          entityTypeName = getInterfaceName(e);
        } else if (entityTypeName !== getInterfaceName(e)) {
          throw new Error("Collection must not contain entities with different data types.");
        }
        if (e.entityAspect.entityState.isUnchanged()) {
          unchangedEntities.push(e);
        }
      } else {
        throw new Error("Collection must not contain non-entity objects.");
      }
    });

    if (unchangedEntities.length > 0) {
      const entityManager = await this.getEntityManagerAsync(entityTypeName, optionalModelName);
      await entityManager?.importEntitiesFromOtherAsync(unchangedEntities);
    }
  }

  override async onComplete(): Promise<void> {
    if (this.abortToken.isAborted) {
      throw new FormFlowAbortError();
    }

    if (this.shouldSaveOnComplete) {
      const hasChanges = this.hasChanges();
      const saveResult = await this.saveAsync();

      if (hasChanges && saveResult.isSaved) {
        const toastMessage = captionService.getString(
          "c7b00380-e72a-46d5-8300-d1600a6a15fb",
          "Changes have been saved.",
        );
        toastService.showToastAlertAsync(toastMessage, NotificationType.Success);
      }
    }
  }

  async dispose(): Promise<void> {
    if (this.semaphoreTokens.size > 0) {
      const tokens = Array.from(this.semaphoreTokens.values());
      await semaphoreProvider.releaseHandlesAsync(tokens);
    }

    if (this.shouldDisposeUiContext) {
      this.drawerSession?.abort();
      this.drawerSession = undefined;
      this.uiContext.dispose();
    }
  }
}

const knownSaveEntityErrorTypes = new Set([
  SaveEntityErrorType.Concurrency,
  SaveEntityErrorType.KnownRequestFailure,
  SaveEntityErrorType.None,
  SaveEntityErrorType.NotFound,
  SaveEntityErrorType.SaveValidation,
  SaveEntityErrorType.SecurityFailure,
  SaveEntityErrorType.Unauthorized,
]);

export default FormFlowSession;
