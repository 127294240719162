import { type Entity } from "BreezeExtensions";
import captionService from "CaptionService";
import constants from "Constants";
import materialDesignVueDialogService, { type DialogState } from "MaterialDesignVueDialogService";
import { type BasePageViewModel } from "PageViewModel";

class MaterialDesignNotesDialogService {
  constructor() {}

  async showNotesAsync(
    entity: Entity,
    viewModel: BasePageViewModel,
    state: DialogState,
  ): Promise<[JQuery.PlainObject, ko.BindingContext<BasePageViewModel>]> {
    const title = await loadTitleAsync(entity);
    return await materialDesignVueDialogService.showDialogAsync(
      entity,
      state,
      constants.MaterialDesignPageTypes.Notes.Shell,
      viewModel,
      title,
      undefined,
    );
  }

  canShowDialog(): boolean {
    return materialDesignVueDialogService.canShowDialog();
  }

  hideDialogVue(state: DialogState): void {
    materialDesignVueDialogService.hideDialogVue(state);
  }
}

async function loadTitleAsync(entity: Entity): Promise<string> {
  const humanReadableName = await entity.entityAspect.humanReadableName.loadAsync();
  const jobName = humanReadableName.caption;
  const title = [jobName];
  if (humanReadableName.name !== jobName) {
    title.push(humanReadableName.name);
  }

  return captionService.getString("db7ff800-4ed3-4dd9-aae8-b434ed22226f", "{0} Notes", title.join(" "));
}

export default new MaterialDesignNotesDialogService();
