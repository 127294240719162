import { DeferredPromise } from "DeferredPromise";
import { ensureResourceName } from "EntityExtensions";
import {
  getMetadataStoreForEntityTypeAsync,
  getMetadataStoreForRouteAsync,
  getRouteNameForMetadataStore,
} from "MetadataStoreExtensions";
import { getODataRoute } from "ODataUtils";
import breeze, { type Entity, type EntityManager, type EntityType, type MetadataStore } from "breeze-client";
import immediate from "immediate";
import ko, { type Observable } from "knockout";

class EntityManagerExtensions {
  readonly observeHasChanges: Observable<boolean>;
  readonly observeAttachAndDetach: ObservableNotification;
  asyncOperationsPending: number;
  asyncOperationsInnerDeferred: DeferredPromise<void>;

  constructor(entityManager: EntityManager) {
    this.observeHasChanges = ko.observable(false);
    entityManager.hasChangesChanged.subscribe((e) => {
      this.observeHasChanges(e.hasChanges);
    });

    this.observeAttachAndDetach = new ObservableNotification();
    this.asyncOperationsPending = 0;
    this.asyncOperationsInnerDeferred = new DeferredPromise<void>();
  }
}

class ObservableNotification {
  public readonly observable: Observable<void>;
  constructor(public isNotificationQueued: boolean = false) {
    this.observable = ko.observable<void>();
  }
}

const entityManagerExtensionCache: WeakMap<EntityManager, EntityManagerExtensions> = new WeakMap();

export function createEntityManagerForEntityType(entityType: EntityType): EntityManager {
  return createEntityManagerForMetadataStore(entityType.metadataStore);
}

export async function createEntityManagerForEntityTypeAsync(entityInterfaceName: string): Promise<EntityManager>;
export async function createEntityManagerForEntityTypeAsync(
  entityInterfaceName: string,
  okIfNotFound: boolean,
): Promise<EntityManager | undefined>;
export async function createEntityManagerForEntityTypeAsync(
  entityInterfaceName: string,
  okIfNotFound: boolean = false,
): Promise<EntityManager | undefined> {
  const metadataStore = await getMetadataStoreForEntityTypeAsync(entityInterfaceName, okIfNotFound);
  return metadataStore && createEntityManagerForMetadataStore(metadataStore);
}

export function createEntityManagerForMetadataStore(metadataStore: MetadataStore): EntityManager {
  const routeName = getRouteNameForMetadataStore(metadataStore);
  if (!routeName) {
    throw new Error("Metadata store does not have a route.");
  }

  return new breeze.EntityManager({
    serviceName: getODataRoute(routeName),
    metadataStore,
  });
}

export async function createEntityManagerForRouteAsync(routeName: string): Promise<EntityManager> {
  const metadataStore = await getMetadataStoreForRouteAsync(routeName);
  return createEntityManagerForMetadataStore(metadataStore);
}

/**
 * Notifies subscribers when entities are created, loaded or attached/detached to the entity manager.
 *
 * @param entityManager breeze entity manager
 */
export function notifyAttachAndDetach(entityManager: EntityManager): void {
  const observeAttachAndDetach = getEntityManagerExtensions(entityManager).observeAttachAndDetach;
  if (!observeAttachAndDetach.isNotificationQueued) {
    observeAttachAndDetach.isNotificationQueued = true;
    immediate(() => {
      observeAttachAndDetach.isNotificationQueued = false;
      observeAttachAndDetach.observable.notifySubscribers();
    });
  }
}

/**
 * Observes when entities are created, loaded or attached/detached to the entity manager.
 *
 * @param entityManager breeze entity manager
 */
export function observeAttachAndDetach(entityManager: EntityManager): void {
  const extensions = getEntityManagerExtensions(entityManager);
  extensions.observeAttachAndDetach.observable();
}

/**
 * Observes any changes to the entity manager and returns the current state.
 *
 * @param entityManager breeze entity manager
 * @returns boolean - true if entity manager has changes, otherwise false.
 */
export function observeHasChanges(entityManager: EntityManager): boolean {
  const extensions = getEntityManagerExtensions(entityManager);
  return extensions.observeHasChanges();
}

/**
 * Associates promises to the entity manager for tracking.
 *
 * @param entityManager Breeze entity manager
 * @param promise Promise to track
 * @returns The tracked promise
 */
export async function trackEntityManagerOperationAsync<T>(
  entityManager: EntityManager,
  promise: Promise<T>,
): Promise<T> {
  const extensions = getEntityManagerExtensions(entityManager);
  extensions.asyncOperationsPending++;

  try {
    return await promise;
  } finally {
    extensions.asyncOperationsPending--;
    if (extensions.asyncOperationsPending === 0) {
      extensions.asyncOperationsInnerDeferred.resolve();
      extensions.asyncOperationsInnerDeferred = new DeferredPromise<void>();
    }
  }
}

/**
 * Waits for all entity manager associated promises to resolve.
 *
 * @param entityManager Breeze entity manager
 */
export async function waitForEntityManagerOperationsAsync(entityManager: EntityManager): Promise<void> {
  const extensions = getEntityManagerExtensions(entityManager);
  if (extensions.asyncOperationsPending > 0) {
    return await extensions.asyncOperationsInnerDeferred.promise;
  }
}

export async function fetchEntitiesByKeyAsync(
  entityManager: EntityManager,
  entityType: EntityType,
  keys: string[],
  expandPaths?: string[],
): Promise<Entity[]> {
  const groups = Array.from(new Set(keys)).reduce<string[][]>((acc, key, index) => {
    const groupIndex = Math.floor(index / 50);
    acc[groupIndex] = acc[groupIndex] || [];
    acc[groupIndex].push(key);
    return acc;
  }, []);

  const resourceName = ensureResourceName(entityType);
  const keyName = entityType.keyProperties[0].name;
  const queryResults = await Promise.all(
    groups.map(async (group) => {
      let query = new breeze.EntityQuery(resourceName).where(keyName, breeze.FilterQueryOp.In, group);
      if (expandPaths && expandPaths.length > 0) {
        query = query.expand(expandPaths);
      }

      const queryResult = await entityManager.executeQuery(query);
      return queryResult.results;
    }),
  );
  return queryResults.flat();
}

function getEntityManagerExtensions(entityManager: EntityManager): EntityManagerExtensions {
  let extensions = entityManagerExtensionCache.get(entityManager);
  if (!extensions) {
    extensions = new EntityManagerExtensions(entityManager);
    entityManagerExtensionCache.set(entityManager, extensions);
  }
  return extensions;
}
