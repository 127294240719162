import moment from 'moment';

const DateTimeType = {
	DateTimeUtc: 'DateTimeUtc',
	DateTime: 'DateTime',
};

export default {
	format(date, _useSeconds, dateTimeIsLocal) {
		let result = date;
		if (date) {
			const value = date;
			const momentValue = dateTimeIsLocal
				? moment.parse(value, DateTimeType.DateTime)
				: moment.parse(value, DateTimeType.DateTimeUtc);

			if (isValidDate(momentValue)) {
				result = isMinDate(momentValue)
					? ''
					: momentValue.toDateTimeTypeString(DateTimeType.DateTime);
			}
		}
		return result;
	},
	parse(date) {
		let result = date;
		if (date) {
			const value = date;
			let momentValue = moment.parseExpression(value, DateTimeType.DateTime);
			if (!momentValue.isValid()) {
				momentValue = moment.parse(value, DateTimeType.DateTime);
			}

			if (isValidDate(momentValue)) {
				result = momentValue.toISOString();
			}
		}
		return result;
	},
};

export function isValidDate(momentValue) {
	return momentValue.isValid() && !moment.minValue.isAfter(momentValue);
}

function isMinDate(momentValue) {
	return moment.minValue.isSame(momentValue);
}
