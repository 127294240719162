import { BooleanPredicate, IsNullPredicate } from "EntityPredicateExtensions";
import { DependencyError } from "Errors";
import { format } from "StringUtils";
import { type ParserRuleContext, type ParseTree } from "antlr4";
import breeze, { type EntityQuery, type EntityType, type FilterQueryOpSymbol, type Predicate } from "breeze-client";
import { ExpressionVisitor } from "wtg-expressions";
import {
  Embedded_pathContext,
  Expr_partContext,
  GuidContext,
  PathContext,
  WhereContext,
  type AllContext,
  type AnyContext,
  type ContainsContext,
  type DependencyContext,
  type EndswithContext,
  type EqualsOperatorContext,
  type Expr_part_no_operatorContext,
  type ExprContext,
  type GreaterOperatorContext,
  type GreaterOrEqualOperatorContext,
  type IndexofContext,
  type Int_func_argumentContext,
  type LengthContext,
  type LessOperatorContext,
  type LessOrEqualOperatorContext,
  type NotEqualsOperatorContext,
  type Orderby_coreContext,
  type Path_partContext,
  type SkipQContext,
  type StartswithContext,
  type String_func_argumentContext,
  type SubstringContext,
  type TakeContext,
  type TolowerContext,
  type ToupperContext,
} from "wtg-expressions/lib/ExpressionParser";

enum FunctionArgumentType {
  QuotedString = "QuotedString",
  Param = "Param",
  Path = "Path",
}

interface Options {
  embeddedDependenciesMap?: Record<string, unknown>;
  params?: unknown[];
}

interface State {
  exprPartPath: string | undefined;
  func: string | undefined;
  predicate: Predicate | undefined;
  skipSelect: boolean | undefined;
}

class QueryBuilderV2Visitor extends ExpressionVisitor<unknown> {
  private readonly embeddedDependenciesMap?: Record<string, unknown>;
  private readonly evaluationState: WeakMap<ParseTree, State>;
  private readonly paramDictionary: Map<string, unknown>;
  private readonly paramValues?: unknown[];
  private query: EntityQuery;
  private selectPath?: string;

  private constructor(query: EntityQuery, options: Options) {
    super();

    this.query = query;
    this.paramDictionary = new Map();
    this.evaluationState = new WeakMap();
    if (options) {
      if (options.params) {
        this.paramValues = options.params.slice(0);
      }
      if (options.embeddedDependenciesMap) {
        this.embeddedDependenciesMap = options.embeddedDependenciesMap;
      }
    }
  }

  static visit(dependency: string, query: EntityQuery, options: Options): unknown {
    const tree = this.getDependencyTree(dependency, this.TreeName.Dependency, (msg) => {
      throw new DependencyError(msg);
    });
    const visitor = new QueryBuilderV2Visitor(query, options);
    return visitor.visit(tree);
  }

  private getState(ctx: ParseTree): State {
    let result = this.evaluationState.get(ctx);
    if (!result) {
      result = {
        exprPartPath: undefined,
        skipSelect: undefined,
        predicate: undefined,
        func: undefined,
      };
      this.evaluationState.set(ctx, result);
    }
    return result;
  }

  override visitDependency = (ctx: DependencyContext): unknown => {
    this.visitChildren(ctx);
    if (this.selectPath) {
      this.query = this.query.select(this.selectPath).expand(this.selectPath);
    }
    return this.query;
  };

  override visitPath = (ctx: PathContext): void => {
    const parentCtx = getParentContext(ctx);
    this.getState(ctx).exprPartPath = this.getPathWithoutFuncSequence(ctx, this.getState(parentCtx).skipSelect);
    this.visitChildren(ctx);
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitEmbedded_path = (): void => {};

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitPath_part = (ctx: Path_partContext): void => {
    if (!this.getState(ctx).skipSelect) {
      const propertyName = ctx.PATH_PART().getText();
      if (this.selectPath) {
        this.selectPath += "." + propertyName;
      } else {
        this.selectPath = propertyName;
      }
    }
    this.visitChildren(ctx);
  };

  override visitExpr = (ctx: ExprContext): void => {
    this.visitChildren(ctx);
    this.getState(ctx).predicate = this.compositePredicate(ctx);
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitExpr_part = (ctx: Expr_partContext): void => {
    const ctxState = this.getState(ctx);
    ctxState.skipSelect = true;
    this.visitChildren(ctx);
    if (!ctxState.predicate) {
      ctxState.predicate = this.compositePredicate(ctx);
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitExpr_part_no_operator = (ctx: Expr_part_no_operatorContext): void => {
    const ctxState = this.getState(ctx);
    ctxState.skipSelect = true;
    this.visitChildren(ctx);
    if (!ctxState.predicate) {
      ctxState.predicate = this.compositePredicate(ctx);
    }
  };

  override visitWhere = (ctx: WhereContext): void => {
    if (this.selectPath) {
      throw new DependencyError("Where predicate is not supported on a child collection.");
    }

    this.visitChildren(ctx);
    const predicate = this.compositePredicate(ctx);
    if (predicate) {
      this.query = this.query.where(predicate);
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitOrderby_core = (ctx: Orderby_coreContext): void => {
    const orderBy = ctx.children?.map((ch) => ch.getText()).join(" ");
    if (orderBy) {
      this.query = this.query.orderBy(orderBy);
    }
  };

  override visitOriginalvalue = (): never => {
    throw new DependencyError("OriginalValue function is not supported in this context.");
  };

  override visitLength = (ctx: LengthContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    this.getState(parentPathCtx).func = "length({0})";
  };

  override visitIndexof = (ctx: IndexofContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    const text = ctx.string_func_argument().getText();
    let value = this.replaceParamAndUnquoteResult(text);
    if (getStringFunctionArgumentType(text) !== FunctionArgumentType.Path) {
      value = `'${value}'`;
    }
    this.getState(parentPathCtx).func = `indexof({0},${value})`;
  };

  override visitSubstring? = (ctx: SubstringContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    const funcArgumentsCtx = ctx.int_func_argument_list();

    const startIndexContextValue = funcArgumentsCtx[0].getText();
    let maxlengthContextValue = null;
    if (funcArgumentsCtx.length === 2) {
      maxlengthContextValue = funcArgumentsCtx[1].getText();
    }

    const parentState = this.getState(parentPathCtx);
    parentState.func = "substring({0}," + this.replaceParamAndUnquoteResult(startIndexContextValue);
    parentState.func += maxlengthContextValue ? `,${this.replaceParamAndUnquoteResult(maxlengthContextValue)})` : ")";
  };

  override visitTolower = (ctx: TolowerContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    this.getState(parentPathCtx).func = "tolower({0})";
  };

  override visitToupper = (ctx: ToupperContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    this.getState(parentPathCtx).func = "toupper({0})";
  };

  override visitUrlencode = (): never => {
    throw new DependencyError("UrlEncode is not supported in this context.");
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitInt_func_argument = (ctx: Int_func_argumentContext): void => {
    this.getState(ctx).skipSelect = true;
    this.visitChildren(ctx);
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitString_func_argument = (ctx: String_func_argumentContext): void => {
    this.getState(ctx).skipSelect = true;
    this.visitChildren(ctx);
  };

  override visitContains = (ctx: ContainsContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    this.getState(parentPathCtx).predicate = this.getBoolFuncPredicate(
      parentPathCtx,
      ctx.string_func_argument(),
      breeze.FilterQueryOp.Contains,
    );
  };

  override visitStartswith = (ctx: StartswithContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    this.getState(parentPathCtx).predicate = this.getBoolFuncPredicate(
      parentPathCtx,
      ctx.string_func_argument(),
      breeze.FilterQueryOp.StartsWith,
    );
  };

  override visitEndswith = (ctx: EndswithContext): void => {
    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    this.getState(parentPathCtx).predicate = this.getBoolFuncPredicate(
      parentPathCtx,
      ctx.string_func_argument(),
      breeze.FilterQueryOp.EndsWith,
    );
  };

  override visitAny = (ctx: AnyContext): void => {
    const parentWhereCtx = findClosestParentContext(ctx, WhereContext, true);
    if (!parentWhereCtx) {
      throw new DependencyError('Expected "where" clause but was not.');
    }

    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    const parentState = this.getState(parentPathCtx);
    const { exprPartPath } = parentState;
    if (!exprPartPath) {
      throw new DependencyError("Did not expect exprPartPath to be empty or undefined.");
    }

    const predicate = this.compositePredicate(ctx);
    if (!predicate) {
      parentState.predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.Any, new BooleanPredicate(true));
    } else {
      parentState.predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.Any, predicate);
    }
  };

  override visitAll = (ctx: AllContext): void => {
    const parentWhereCtx = findClosestParentContext(ctx, WhereContext, true);
    if (!parentWhereCtx) {
      throw new DependencyError('Expected "where" clause but was not.');
    }

    this.visitChildren(ctx);
    const parentPathCtx = findClosestParentContext(ctx, PathContext);
    const parentState = this.getState(parentPathCtx);
    const { exprPartPath } = parentState;
    if (!exprPartPath) {
      throw new DependencyError("Did not expect exprPartPath to be empty or undefined.");
    }

    const predicate = this.compositePredicate(ctx);
    parentState.predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.All, predicate);
  };

  override visitSum = (): never => {
    throw new DependencyError("Sum is not supported in this context.");
  };

  override visitFirst = (): void => {
    if (!this.selectPath) {
      this.query = this.query.take(1);
    } else {
      throw new DependencyError("First/Take/Skip on a child collection is not supported.");
    }
  };

  override visitTake = (ctx: TakeContext): void => {
    if (!this.selectPath) {
      const count = this.getValueFromInt_func_argument(ctx);
      this.query = this.query.take(count);
    } else {
      throw new DependencyError("First/Take/Skip on a child collection is not supported.");
    }
  };

  override visitSkipQ = (ctx: SkipQContext): void => {
    if (!this.selectPath) {
      const count = this.getValueFromInt_func_argument(ctx);
      this.query = this.query.skip(count);
    } else {
      throw new DependencyError("First/Take/Skip on a child collection is not supported.");
    }
  };

  override visitGreaterOrEqualOperator = (ctx: GreaterOrEqualOperatorContext): void => {
    const parentCtx = getParentContext(ctx, Expr_partContext);
    const exprPartPath = this.formatFuncForExpr(parentCtx);
    const value = this.getRightSideValue(ctx);

    this.getState(parentCtx).predicate = new breeze.Predicate(
      exprPartPath,
      breeze.FilterQueryOp.GreaterThanOrEqual,
      value,
    );
  };

  override visitLessOrEqualOperator = (ctx: LessOrEqualOperatorContext): void => {
    const parentCtx = getParentContext(ctx, Expr_partContext);
    const exprPartPath = this.formatFuncForExpr(parentCtx);
    const value = this.getRightSideValue(ctx);

    this.getState(parentCtx).predicate = new breeze.Predicate(
      exprPartPath,
      breeze.FilterQueryOp.LessThanOrEqual,
      value,
    );
  };

  override visitGreaterOperator = (ctx: GreaterOperatorContext): void => {
    const parentCtx = getParentContext(ctx, Expr_partContext);
    const exprPartPath = this.formatFuncForExpr(parentCtx);
    const value = this.getRightSideValue(ctx);

    this.getState(parentCtx).predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.GreaterThan, value);
  };

  override visitLessOperator = (ctx: LessOperatorContext): void => {
    const parentCtx = getParentContext(ctx, Expr_partContext);
    const exprPartPath = this.formatFuncForExpr(parentCtx);
    const value = this.getRightSideValue(ctx);

    this.getState(parentCtx).predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.LessThan, value);
  };

  override visitEqualsOperator = (ctx: EqualsOperatorContext): void => {
    const parentCtx = getParentContext(ctx, Expr_partContext);
    const pathCtx = parentCtx.path(0);
    const pathCtxState = this.getState(pathCtx);
    const parentState = this.getState(parentCtx);

    if (!pathCtxState.predicate) {
      const exprPartPath = this.formatFuncForExpr(parentCtx);
      const value = this.getRightSideValue(ctx);

      if (value === null) {
        parentState.predicate = equalsNullPredicate(this.query, exprPartPath);
      } else {
        parentState.predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.Equals, value);
      }
    } else if (parentCtx.FALSE_list().length > 0) {
      parentState.predicate = breeze.Predicate.not(pathCtxState.predicate);
    } else {
      parentState.predicate = pathCtxState.predicate;
    }
  };

  override visitNotEqualsOperator = (ctx: NotEqualsOperatorContext): void => {
    const parentCtx = getParentContext(ctx, Expr_partContext);
    const predicate = this.getState(parentCtx.path(0)).predicate;
    const parentState = this.getState(parentCtx);

    if (!predicate) {
      const exprPartPath = this.formatFuncForExpr(parentCtx);
      const value = this.getRightSideValue(ctx);

      if (value === null) {
        parentState.predicate = new IsNullPredicate(exprPartPath, false);
      } else {
        parentState.predicate = new breeze.Predicate(exprPartPath, breeze.FilterQueryOp.NotEquals, value);
      }
    } else if (parentCtx.TRUE_list().length > 0) {
      parentState.predicate = breeze.Predicate.not(predicate);
    } else {
      parentState.predicate = predicate;
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private getValueFromInt_func_argument(ctx: SkipQContext | TakeContext): number | undefined {
    const embeddedPath = ctx.int_func_argument().embedded_path();
    if (embeddedPath) {
      const dependency = embeddedPath.path().getText();
      const result = this.getEmbeddedDependency(dependency);
      if (typeof result === "number") {
        return result;
      } else if (result == null) {
        return undefined;
      } else {
        throw new DependencyError(`Expected value for '${dependency}' in embeddedDependenciesMap to be a number.`);
      }
    }

    const text = ctx.int_func_argument().getText();
    const value = this.replaceParamAndUnquoteResult(text);
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      return parseInt(value);
    }
    throw new DependencyError(`'${text}' did not evaluate to a string or number.`);
  }

  private getRightSideValue(ctx: ParserRuleContext): unknown {
    let rightSideValue;
    const rightSideContext = ctx.parentCtx!.children![2];
    if (rightSideContext instanceof GuidContext) {
      rightSideValue = rightSideContext.QUOTED_STRING().getText();
    } else if (rightSideContext instanceof Embedded_pathContext) {
      const dependency = rightSideContext.children![1].getText();
      return this.getEmbeddedDependency(dependency);
    } else {
      rightSideValue = rightSideContext.getText();
    }
    return this.replaceParamAndUnquoteResult(rightSideValue);
  }

  private formatFuncForExpr(ctx: Expr_partContext): string {
    const pathCtx = ctx.path(0);
    const pathState = this.getState(pathCtx);
    const { exprPartPath } = pathState;
    if (!exprPartPath) {
      throw new DependencyError("Did not expect exprPartPath to be empty or undefined.");
    }
    return pathState.func ? format(pathState.func, exprPartPath) : exprPartPath;
  }

  private formatFuncForStringFuncArg(ctx: String_func_argumentContext): unknown {
    let pathCtx: Embedded_pathContext | PathContext | undefined = ctx.path();
    if (pathCtx) {
      const pathState = this.getState(pathCtx);
      return pathState.func ? format(pathState.func, pathState.exprPartPath) : pathState.exprPartPath;
    } else {
      pathCtx = ctx.embedded_path();
      if (pathCtx) {
        const dependency = pathCtx.path().getText();
        return this.getEmbeddedDependency(dependency);
      }
    }

    return this.replaceParamAndUnquoteResult(ctx.getText());
  }

  private getBoolFuncPredicate(
    parentPathCtx: ParserRuleContext,
    argumentCtx: String_func_argumentContext,
    op: FilterQueryOpSymbol,
  ): Predicate {
    const { exprPartPath } = this.getState(parentPathCtx);
    if (!exprPartPath) {
      throw new DependencyError("Did not expect parentState.exprPartPath to be empty or undefined.");
    }

    this.visitChildren(argumentCtx);
    const argumentValue = this.formatFuncForStringFuncArg(argumentCtx);
    return new breeze.Predicate(exprPartPath, op, argumentValue);
  }

  private getPathWithoutFuncSequence(ctx: PathContext, skipSelect: boolean | undefined): string {
    let result = "";
    ctx.path_part_list().forEach((pathPartCtx) => {
      this.getState(pathPartCtx).skipSelect = skipSelect;
      const pathPart = pathPartCtx.PATH_PART().getText();
      result += result ? "." + pathPart : pathPart;
    });
    return result;
  }

  private compositePredicate(ctx: ParserRuleContext): Predicate | undefined {
    let children = ctx.children?.filter((child) => {
      return "ruleIndex" in child;
    });
    if (!children?.length) {
      return undefined;
    }

    let not;
    if (children[0].getText() === "!") {
      not = true;
      children = children.slice(1);
    }

    let result = this.getState(children[0]).predicate ?? new breeze.Predicate(children[0].getText(), "==", true);

    for (let i = 1; i < children.length; i++) {
      if (!this.getState(children[i]).predicate) {
        const nextState = this.getState(children[i + 1]);
        if (!nextState.predicate) {
          throw new DependencyError("Did not expect nextState.predicate to be undefined.");
        }

        if (children[i].getText() === "&&") {
          result = result.and(nextState.predicate);
        } else {
          result = result.or(nextState.predicate);
        }
      }
    }
    if (not) {
      result = breeze.Predicate.not(result);
    }

    return result;
  }

  private replaceParamAndUnquoteResult(value: string): unknown {
    if (value[0] === "@") {
      let paramValue = this.paramDictionary.get(value);
      if (paramValue === undefined) {
        if (!this.paramValues || this.paramValues.length === 0) {
          throw new DependencyError("Number of parameters does not match the number of values provided.");
        }

        paramValue = this.paramValues.shift();
        this.paramDictionary.set(value, paramValue);
      }
      return paramValue;
    }

    return unwrapQuotedString(value);
  }

  private getEmbeddedDependency(key: string): unknown {
    const { embeddedDependenciesMap } = this;
    if (!embeddedDependenciesMap) {
      throw new DependencyError("embeddedDependenciesMap was not provided.");
    }
    if (!(key in embeddedDependenciesMap)) {
      throw new DependencyError(`Value was not provided for '${key}' in embeddedDependenciesMap.`);
    }

    return embeddedDependenciesMap[key];
  }
}

function equalsNullPredicate(query: EntityQuery, exprPartPath: string): Predicate {
  const { metadataStore } = query.entityManager;
  const entityTypeName = metadataStore.getEntityTypeNameForResourceName(query.resourceName);
  const entityType = metadataStore.getEntityType(entityTypeName) as EntityType;
  const dataProperty = entityType.getDataProperty(exprPartPath);
  const isEqualityRedundant = dataProperty ? !dataProperty.isNullable : false;

  if (isEqualityRedundant) {
    return new BooleanPredicate(false);
  }

  return new IsNullPredicate(exprPartPath, true);
}

function findClosestParentContext<T>(ctx: ParserRuleContext, type: new () => T): T;
function findClosestParentContext<T>(ctx: ParserRuleContext, type: new () => T, optional: true): T | undefined;
function findClosestParentContext<T>(ctx: ParserRuleContext, type: new () => T, optional = false): T | undefined {
  let currentCtx: ParserRuleContext | undefined = ctx;
  while ((currentCtx = currentCtx.parentCtx)) {
    if (currentCtx instanceof type) {
      return currentCtx;
    }
  }

  if (!optional) {
    throw new DependencyError("Could not find valid closest parent context.");
  }

  return undefined;
}

function getParentContext(ctx: ParserRuleContext): ParserRuleContext;
function getParentContext<T extends ParserRuleContext>(ctx: ParserRuleContext, type: new () => T): T;
function getParentContext<T extends ParserRuleContext>(ctx: ParserRuleContext, type?: new () => T): T {
  const { parentCtx } = ctx;
  if (parentCtx && (!type || parentCtx instanceof type)) {
    return parentCtx as T;
  }
  throw new DependencyError("Could not get valid parent context.");
}

function getStringFunctionArgumentType(text: string): FunctionArgumentType {
  switch (text[0]) {
    case "@":
      return FunctionArgumentType.Param;
    case '"':
      return FunctionArgumentType.QuotedString;
    default:
      return FunctionArgumentType.Path;
  }
}

function unwrapQuotedString(quotedString: string): string | null {
  if (quotedString === "null") {
    return null;
  }

  const { length } = quotedString;
  if (quotedString[0] === '"' && quotedString[length - 1] === '"') {
    return quotedString.substring(1, quotedString.length - 1);
  }

  return quotedString;
}

export default QueryBuilderV2Visitor;
