import calculatedProperty, { type CalculatedProperty } from "CalculatedProperty";
import captionService from "CaptionService";
import { getInterfaceName } from "EntityExtensions";
import { newGuid } from "GuidGenerator";
import Rule from "Rule";
import RuleService from "RuleService";
import { RuleType } from "RuleType";
import { type Entity } from "breeze-client";

type EntityCalculatedPropertyExtensions = {
  humanReadableName?: CalculatedProperty<HumanReadableName | null>;
};

const entityCalculatedPropertyCache: WeakMap<Entity, EntityCalculatedPropertyExtensions> = new WeakMap();

export interface HumanReadableName {
  readonly isGeneric: boolean;
  readonly name: string;
  readonly caption: string;
}

/**
 * Returns the human readable name for a given entity as a calulated property.
 * If the human readable name is not defined, calculated property will return null.
 *
 * @param entity breeze entity
 * @returns Calculated property of type HumanReadableName. If not defined, calculated property will return null
 */
export function getHumanReadableName(entity: Entity): CalculatedProperty<HumanReadableName | null> {
  const extensions = getCalculatedPropertyExtensions(entity);
  if (!extensions.humanReadableName) {
    extensions.humanReadableName = getHumanReadableNameProperty(entity);
  }

  return extensions.humanReadableName;
}

function getCalculatedPropertyExtensions(entity: Entity): EntityCalculatedPropertyExtensions {
  let extensions = entityCalculatedPropertyCache.get(entity);
  if (!extensions) {
    extensions = {};
    entityCalculatedPropertyCache.set(entity, extensions);
  }
  return extensions;
}

function getHumanReadableNameProperty(entity: Entity): CalculatedProperty<HumanReadableName | null> {
  const rules = RuleService.get(getInterfaceName(entity));
  const parameters = [rules.codeProperty(), rules.descriptionProperty(), rules.typeDescriptionProperty()].map(
    (propertyName) => {
      return { value: propertyName ? `<${propertyName}>` : "" };
    },
  );

  parameters.unshift({ value: "<.>" });
  return calculatedProperty(
    entity,
    new Rule({
      id: newGuid(),
      ruleType: RuleType.Property,
      property: "humanReadableName",
      components: [{ parameters, func: calculateHumanReadableName }],
    }),
  );
}

function calculateHumanReadableName(
  entity: Entity,
  code: string,
  description: string,
  typeDescription: string,
): HumanReadableName {
  const caption = typeDescription || captionService.getCaptionFromInterfaceName(getInterfaceName(entity)).caption;
  const result = {
    isGeneric: !code && !description,
    name: code || description || caption,
    caption,
  };

  return result;
}
