import breeze, { type EntityManager, type EntityType, type MetadataStore } from "breeze-client";

const entityTypeMetadata = new WeakMap<EntityType, EntityTypeMetadata>();

export function getEntityType(
  entityManagerOrMetadataStore: EntityManager | MetadataStore,
  entityType: string,
): EntityType {
  const metadataStore =
    entityManagerOrMetadataStore instanceof breeze.EntityManager
      ? entityManagerOrMetadataStore.metadataStore
      : entityManagerOrMetadataStore;
  const structuralType = metadataStore.getEntityType(entityType);
  if (structuralType.isComplexType) {
    throw new Error(`${entityType} is a complex type but expected an entity type.`);
  }

  return structuralType as unknown as EntityType;
}

export function getExcludeReadOnlyProperty(entityType: EntityType): boolean {
  return !!entityTypeMetadata.get(entityType)?.excludeReadOnlyProperty;
}

export function setExcludeReadOnlyProperty(entityType: EntityType, value: boolean): void {
  getMetadata(entityType).excludeReadOnlyProperty = value;
}

export function getMeasureProperties(entityType: EntityType): MeasureProperty[] | undefined {
  return entityTypeMetadata.get(entityType)?.measureProperties;
}

export function setMeasureProperties(entityType: EntityType, value: MeasureProperty[]): void {
  getMetadata(entityType).measureProperties = value;
}

function getMetadata(entityType: EntityType): EntityTypeMetadata {
  let metadata = entityTypeMetadata.get(entityType);
  if (!metadata) {
    metadata = {};
    entityTypeMetadata.set(entityType, metadata);
  }

  return metadata;
}

interface EntityTypeMetadata {
  excludeReadOnlyProperty?: boolean;
  measureProperties?: MeasureProperty[];
}

export interface MeasureProperty {
  magnitudeProperty: string;
  unitProperty: string;
  unitType: string;
}
