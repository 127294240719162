import { getRuleAlertDetails } from "AlertDetailsFactory";
import captionService from "CaptionService";
import { createDisposable, type Disposable } from "Disposable";
import { getDependencyGraph } from "EntityDependencyExtensions";
import { getInterfaceName } from "EntityExtensions";
import { trackEntityManagerOperationAsync } from "EntityManagerExtensions";
import { getPropertyValueAsync } from "EntityPropertyExtensions";
import { findError, RuleInvocationException, UnavailableArgumentsOrSecurityError } from "Errors";
import global from "Global";
import log from "Log";
import { compareNotificationTypes, NotificationType } from "NotificationType";
import Notifications, { type Alert, type AlertDetails } from "Notifications";
import PropertyVertex from "PropertyVertex";
import type Rule from "Rule";
import RuleService from "RuleService";
import type RuleValidationResult from "RuleValidationResult";
import type { RuleProcessResult } from "SubRule";
import ValidationRuleVertex from "ValidationRuleVertex";
import type { Entity, EntityManager } from "breeze-client";
import { type IAcknowledgement } from "wtg-entity-type-definitions";
import type { EntityAdapter } from "../Breeze/EntityAdapter";

const suspendedMap = new WeakMap<Entity | EntityManager, number>();
const versionMap = new WeakMap<Entity, VersionTable>();

const validationEngine = {
  suspendValidation(entities: Entity[]): Disposable {
    entities.forEach((entity) => {
      const semaphore = suspendedMap.get(entity) || 0;
      suspendedMap.set(entity, semaphore + 1);
    });

    return createDisposable(() => {
      entities.forEach((entity) => {
        const semaphore = suspendedMap.get(entity);
        if (semaphore && semaphore > 1) {
          suspendedMap.set(entity, semaphore - 1);
        } else {
          suspendedMap.delete(entity);
        }
      });
    });
  },

  suspendValidationEntityManager(entityManager: EntityManager): Disposable {
    const semaphore = suspendedMap.get(entityManager) || 0;
    suspendedMap.set(entityManager, semaphore + 1);

    return createDisposable(() => {
      const semaphore = suspendedMap.get(entityManager);
      if (semaphore && semaphore > 1) {
        suspendedMap.set(entityManager, semaphore - 1);
      } else {
        suspendedMap.delete(entityManager);
      }
    });
  },

  isSuspended(entity: Entity): boolean {
    return (
      suspendedMap.has(entity) ||
      (!!entity.entityAspect.entityManager && suspendedMap.has(entity.entityAspect.entityManager))
    );
  },

  async validateEntityAsync(entity: Entity, propertyNames?: string[], levels?: NotificationType[]): Promise<boolean> {
    if (this.isSuspended(entity)) {
      return true;
    }

    const versionTable = getVersionTable(entity);
    const version = propertyNames
      ? versionTable.incrementVersionForProperties(propertyNames)
      : versionTable.incrementVersionForEntity();
    const rules = getAllRules(entity, propertyNames, levels);

    const filters: Filter[] = [];
    if (propertyNames) {
      filters.unshift((a) => propertyNames.includes(a.propertyName));
    }
    if (levels) {
      filters.push((a) => levels.includes(a.Level));
    }
    if (!supportsAcknowledgements(entity)) {
      filters.push((a) => !isWarning(a));
    }

    const promise = (async (): Promise<boolean> => {
      await runRulesAsync(versionTable, version, entity, rules, filters);
      const alerts = getNotifications(entity)
        .alerts()
        .filter((a) => a.ruleId && filters.every((f) => f(a)));
      return compareNotificationTypes(Notifications.getLevel(alerts), NotificationType.Information) < 1;
    })();

    return await addOperationPromiseAsync(entity, promise);
  },

  async validatePropertyAndDependentsAsync(entity: Entity, propertyName: string): Promise<void> {
    if (this.isSuspended(entity)) {
      return;
    }
    log.info(
      `[ValidationEngine] starting validation on property and dependents, ${entity.entityType.name}.${propertyName}`,
    );

    const promise = (async (): Promise<void> => {
      const { entityManager } = entity.entityAspect;
      const dependencyGraph = entityManager && getDependencyGraph(entityManager);
      const versionTable = getVersionTable(entity);
      const allRules = RuleService.get(getInterfaceName(entity)).validationRules();

      await validatePropertyAsync(versionTable, entity, allRules, propertyName);

      const validationNodes = new Set<ValidationRuleVertex>();
      dependencyGraph?.visitDependents(new PropertyVertex(entity, propertyName), (d) => {
        if (d instanceof ValidationRuleVertex && !d.isVertexFor(entity, propertyName)) {
          validationNodes.add(d);
        }
      });

      await Promise.all([...validationNodes].map((v) => this.validateRuleAsync(v.entity, v.rule)));
    })();

    return await addOperationPromiseAsync(entity, promise);
  },

  async validateRuleAsync(entity: Entity, rule: Rule): Promise<void> {
    if (this.isSuspended(entity)) {
      return;
    }

    const promise = (async (): Promise<void> => {
      const versionTable = getVersionTable(entity);
      const rules = [rule];
      const version = versionTable.incrementVersionForRules(rules);
      const filters: Filter[] = [(a): boolean => a.ruleId === rule.ruleId];
      await runRulesAsync(versionTable, version, entity, rules, filters);
    })();

    return await addOperationPromiseAsync(entity, promise);
  },
};

function getNotifications(entity: Entity): Notifications {
  const result = Notifications.get(entity);
  if (!result) {
    throw new Error("Expected Notifications to exist.");
  }
  return result;
}

function getVersionTable(entity: Entity): VersionTable {
  let versionTable = versionMap.get(entity);
  if (!versionTable) {
    versionTable = new VersionTable();
    versionMap.set(entity, versionTable);
  }

  return versionTable;
}

function getAllRules(entity: Entity, propertyNames?: string[], levels?: NotificationType[]): Rule[] {
  let result: Rule[] = [];
  const rules = RuleService.get(getInterfaceName(entity)).validationRules();

  for (const propertyName in rules) {
    if (!propertyNames || propertyNames.indexOf(propertyName) > -1) {
      result = result.concat(rules[propertyName]);
    }
  }

  if (levels) {
    result = result.filter((rule) => {
      return rule.notificationType == null || levels.indexOf(rule.notificationType) > -1;
    });
  }

  return result;
}

async function addOperationPromiseAsync<T>(entity: Entity, promise: Promise<T>): Promise<T> {
  const { entityManager } = entity.entityAspect;
  if (entityManager) {
    promise = trackEntityManagerOperationAsync(entityManager, promise);
  }
  return await promise;
}

async function runRuleAsync(entity: Entity, rule: Rule, result: AlertDetails[]): Promise<void> {
  try {
    const ruleResult = (await rule.processAsync(entity)) as RuleProcessResult<RuleValidationResult>;
    if (ruleResult.isSuccess) {
      const item = ruleResult.value;
      if (item && item.Level !== NotificationType.None && item.Level !== NotificationType.Success) {
        result.push(getRuleAlertDetails(rule.ruleId, item.Text, item.Level, entity, rule.property));
      }
    }
  } catch (error) {
    if (error instanceof Error) {
      if (findError(error, UnavailableArgumentsOrSecurityError)) {
        return;
      }
      if (error instanceof RuleInvocationException && global.isPreviewMode()) {
        throw error;
      }
    }

    result.push(
      getRuleAlertDetails(
        rule.ruleId,
        captionService.getString(
          "06445a07-1175-49e3-b77e-f1e10dc9fd84",
          "An error occurred while validating this property.",
        ),
        NotificationType.Error,
        entity,
        rule.property,
      ),
    );
  }
}

function getRuleIdFromAcknowledgment(ack: EntityAdapter<Entity, IAcknowledgement>): string {
  return ack.XK_RuleID();
}

function isNonCancelledAcknowledgment(ack: EntityAdapter<Entity, IAcknowledgement>): boolean {
  return !ack.XK_IsCancelled();
}

function supportsAcknowledgements(entity: Entity): boolean {
  return "Acknowledgements" in entity;
}

async function runRulesAsync(
  versionTable: VersionTable,
  version: number,
  entity: Entity,
  rules: Rule[],
  baseFilters: Filter[],
): Promise<void> {
  let result: AlertDetails[] = [];
  const notifications = getNotifications(entity);

  await Promise.all(rules.map((rule) => runRuleAsync(entity, rule, result)));

  const acknowledgedRuleIds =
    supportsAcknowledgements(entity) && result.some(isWarning)
      ? await getAcknowledgedRuleIdsAsync(entity, notifications)
      : undefined;

  if (acknowledgedRuleIds?.length) {
    result = result.filter((alert) => {
      return !isPriorAcknowledgement(alert, acknowledgedRuleIds);
    });
  }

  const filters: Filter[] = [(a): boolean => versionTable.getVersion(a) <= version, ...baseFilters];
  result = result.filter((a) => filters.every((f) => f(a)));

  result.forEach((a) => {
    log.info(`[ValidationEngine] Alert: ${a.entityName}.${a.propertyName}: ${a.Text}`);
  });

  if (!supportsAcknowledgements(entity)) {
    result.forEach((alert) => {
      if (isWarning(alert)) {
        alert.requiresAcknowledgement = false;
      }
    });
  }

  // TODO: Consider whether the version filter is necessary for control-level or server-side alerts. Update the code or
  // remove this comment accordingly.
  clearNotifications(notifications, filters);

  if (result.length > 0) {
    notifications.pushAll(result);
  }
}

async function validatePropertyAsync(
  versionTable: VersionTable,
  entity: Entity,
  allRules: Record<string, Rule[]>,
  propertyName: string,
): Promise<Rule[]> {
  const version = versionTable.incrementVersionForProperties([propertyName]);
  const propertyRules = allRules[propertyName] || [];
  const filters: Filter[] = [(a): boolean => a.propertyName === propertyName];
  await runRulesAsync(versionTable, version, entity, propertyRules, filters);
  return propertyRules;
}

async function getAcknowledgedRuleIdsAsync(entity: Entity, notifications: Notifications): Promise<string[]> {
  let result: string[] = [];
  notifications.acknowledgedAlerts.forEach((alert) => {
    if (alert.ruleId) {
      result.push(alert.ruleId);
    }
  });

  const acknowledgements = await getPropertyValueAsync<EntityAdapter<Entity, IAcknowledgement>[]>(
    entity,
    /*! SuppressStringValidation property name */
    "Acknowledgements",
  );
  const previouslyAcknowledgedRuleIds = acknowledgements
    .filter(isNonCancelledAcknowledgment)
    .map(getRuleIdFromAcknowledgment);
  result = previouslyAcknowledgedRuleIds.concat(result);

  return result;
}

function isWarning(alert: Alert | AlertDetails): boolean {
  return alert.Level === NotificationType.Warning;
}

function isPriorAcknowledgement(alert: AlertDetails, acknowledgedRuleIds: string[]): boolean {
  return (
    alert.Level === NotificationType.Warning &&
    acknowledgedRuleIds.some((ruleId) => {
      return alert.ruleId === ruleId;
    })
  );
}

function clearNotifications(notifications: Notifications, filters: ((alert: Alert) => boolean)[]): void {
  notifications.removeAll((alert) => {
    return filters.every((f) => f(alert)) && !!(alert.isServerNotification || alert.ruleId);
  });
}

class VersionTable {
  private globalVersion: number;
  private nextVersion: number;
  private readonly properties: Map<string, { version: number; rules?: Map<string, number> }>;

  constructor() {
    this.properties = new Map();
    this.nextVersion = 1;
    this.globalVersion = 0;
  }

  getVersion(alert: Alert | AlertDetails): number {
    let version;
    const property = this.properties.get(alert.propertyName);
    if (property) {
      version = (alert.ruleId && property.rules?.get(alert.ruleId)) || property.version;
    }

    return version || this.globalVersion;
  }

  incrementVersionForEntity(): number {
    const version = this.nextVersion++;
    this.globalVersion = version;
    this.properties.clear();

    return version;
  }

  incrementVersionForProperties(propertyNames: string[]): number {
    const version = this.nextVersion++;
    propertyNames.forEach((name) => {
      this.properties.set(name, { version, rules: undefined });
    });

    return version;
  }

  incrementVersionForRules(rules: Rule[]): number {
    const version = this.nextVersion++;
    rules.forEach((rule) => {
      let property = this.properties.get(rule.property);
      if (!property) {
        property = { version: 0, rules: undefined };
        this.properties.set(rule.property, property);
      }
      if (!property.rules) {
        property.rules = new Map();
      }

      property.rules.set(rule.ruleId, version);
    });

    return version;
  }
}

interface Filter {
  (alert: Alert | AlertDetails): boolean;
}

export default validationEngine;
