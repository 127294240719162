import ajaxService, { AjaxError } from "AjaxService";
import { DataServiceRequestError } from "DataServiceRequestError";
import type { HttpOData } from "ts-odatajs";
import oData4 from "ts-odatajs/lib/odata";

function getExtendedOData(): typeof oData4 {
  const defaultHttpClient: HttpOData.HttpClient = getDefaultHttpClientAsync();
  oData4.net.defaultHttpClient = defaultHttpClient;
  return oData4;
}

function getDefaultHttpClientAsync(): HttpOData.HttpClient {
  return {
    request(
      request: HttpOData.Request,
      success: (response: HttpOData.Response) => void,
      error: (error: HttpOData.Error | Error) => void,
    ): HttpOData.RequestWithAbort {
      (async (): Promise<void> => {
        try {
          const result = await ajaxService.ajaxAsync({
            type: request.method,
            url: request.requestUri,
            data: request.body,
            dataType: "text",
            headers: request.headers,
            allowQueryStringInUrl: true,
            excludeComplementedHeaders: true,
            includeResponseDetails: true,
          });
          success({
            requestUri: request.requestUri,
            statusCode: result.status.toString(),
            statusText: result.statusText,
            headers: result.getAllResponseHeaders(),
            body: result.data as string,
          });
        } catch (err) {
          if (err instanceof AjaxError) {
            error(
              new DataServiceRequestError(
                request.requestUri,
                {
                  message: "HTTP request failed",
                  request,
                  response: {
                    requestUri: request.requestUri,
                    statusCode: err.status.toString(),
                    statusText: err.statusText,
                    headers: err.getAllResponseHeaders(),
                    body: err.responseText,
                  },
                },
                err,
              ),
            );
          } else {
            error(err as Error | HttpOData.Error);
          }
        }
      })();

      return {
        abort(): void {
          throw new Error("abort is not implemented.");
        },
      };
    },
  };
}

export default getExtendedOData();
