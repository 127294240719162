import global from "Global";
import { type ThemeOptions, type AppColorOptions } from "wtg-material-ui";

export default {
  convertDrawerOptionsToBrandOptions(
    themeOptions?: ThemeOptions,
    allowBrandedLoginColor?: boolean,
  ): ThemeOptions | undefined {
    const convertOptions = (appOptions?: AppColorOptions, defaultBrandColor?: string): void => {
      if (appOptions?.navigationDrawer?.background) {
        const brandColor = appOptions.navigationDrawer.background;
        const brandColorDark = appOptions.navigationDrawer.dark;

        appOptions.brandColor = brandColor;
        appOptions.brandColorDark = brandColorDark;
        appOptions.brandLoginColor = allowBrandedLoginColor ? brandColor : defaultBrandColor;
        appOptions.brandLoginColorDark = allowBrandedLoginColor ? brandColorDark : true;

        appOptions.navigationDrawer = undefined;
      }
    };

    const portalThemeConfiguration = global.portalInfo.themeConfiguration;
    convertOptions(
      themeOptions?.colors?.light?.app,
      themeOptions?.colors?.light?.primary ?? portalThemeConfiguration?.brandColor ?? "#371ee1",
    );
    convertOptions(themeOptions?.colors?.dark?.app, themeOptions?.colors?.dark?.primary ?? "#181C33");

    return themeOptions;
  },
};
