import type { CalculatedProperty } from "CalculatedProperty";
import { createDisposable, type Disposable } from "Disposable";
import type { DynamicMetadataProvider } from "DynamicMetadataProvider";
import { getInterfaceName } from "EntityExtensions";
import type { ProposedValuesStorage } from "ProposedValueEngine";
import RuleService from "RuleService";
import validationEngine from "ValidationEngine";
import type { Entity, EntityAspect } from "breeze-client";

export interface EntityWithProposedValues extends Entity {
  entityAspect: EntityAspect & { proposedValues: ProposedValuesStorage };
}

export async function enableProposedValuesAsync(entity: Entity): Promise<Disposable> {
  if (!supportsProposedValues(entity)) {
    throw new Error("Entity does not support proposed values");
  }

  const { proposedValues } = entity.entityAspect;
  await proposedValues.startListeningAsync();
  return createDisposable(() => proposedValues.stopListening());
}

export function suspendValidation(entity: Entity): Disposable {
  return validationEngine.suspendValidation([entity]);
}

export async function decorateEntityWithDynamicMetadataAsync(
  entity: Entity,
  dynamicMetadataProviders: DynamicMetadataProvider[],
): Promise<Entity> {
  const entityCasted = entity as unknown as Record<string, unknown>;

  const calculatedPropertiesMaps = await Promise.all(
    dynamicMetadataProviders.map((provider) => provider.getCalculatedPropertiesAsync(entity)),
  );

  calculatedPropertiesMaps.forEach((calculatedProperties: Map<string, CalculatedProperty<unknown>>) => {
    for (const [propertyName, calculatedProperty] of calculatedProperties) {
      entityCasted[propertyName] = calculatedProperty;
    }
  });

  return entityCasted as unknown as Entity;
}

export async function decorateEntitiesWithDynamicMetadataAsync(
  entities: Entity[],
  dynamicMetadataProviders?: DynamicMetadataProvider[],
): Promise<void> {
  if (!entities.length) {
    return;
  }

  if (!dynamicMetadataProviders) {
    const ruleService = await RuleService.loadAsync(getInterfaceName(entities[0]));
    dynamicMetadataProviders = await ruleService.getDynamicMetadataProvidersAsync();
  }

  if (dynamicMetadataProviders.length) {
    await Promise.all(
      entities.map((entity) => decorateEntityWithDynamicMetadataAsync(entity, dynamicMetadataProviders)),
    );
  }
}

function supportsProposedValues(entity: Entity): entity is EntityWithProposedValues {
  return "proposedValues" in entity.entityAspect;
}
