import type { Entity } from "breeze-client";
import RuleVertex from "RuleVertex";

export default class ValidationRuleVertex extends RuleVertex {
  async reportChangedAsync(): Promise<void> {
    this.wireDependencies();
    await Promise.resolve();
  }

  protected override shouldCreateDependencyVertex(entity: Entity, propertyName: string): boolean {
    return (
      super.shouldCreateDependencyVertex(entity, propertyName) ||
      !!entity.entityType.getNavigationProperty(propertyName)
    );
  }
}
