import ajaxService, { AjaxError } from "AjaxService";
import entityMappingService from "EntityMappingService";
import { LoadRulesetException } from "Errors";
import global from "Global";
import type { LookupMetadata } from "Lookups/LookupMetadataFactory";
import { loadRulesetAsync } from "ModuleLoader";
import Rule from "Rule";
import { extractDerivedRulesets } from "RuleExtractor";
import ruleFunction, { type RuleFunction } from "RuleFunction";
import type { RuleType } from "RuleType";
import type { RuleDefinitionBase, RulesetMetadata, RulesetMetadataBase } from "RulesetMetadata";

export interface RuleRepository {
  clear(): void;
  addRegistrar<T extends keyof Ruleset>(
    name: T,
    registrar: (ruleset: RulesetMetadata) => Record<string, unknown>,
  ): void;
  get(entityName: string, okIfNotFound?: boolean): Ruleset | null;
  loadEntityAsync(entityName: string): Promise<Ruleset | undefined>;
  loadRouteAsync(routeName: string): Promise<void>;
}

export interface Ruleset extends RulesetMetadataBase {
  colorSchemeProperty?: string;
  command?: Record<string, Rule[]>;
  dynamicMetadataProviderRules?: RuleFunction[];
  lookup?: Record<string, Rule[]>;
  lookupMetadata?: Record<string, LookupMetadata>;
  property?: Record<string, Rule[]>;
  proposedValue?: Record<string, Rule[]>;
  validation?: Record<string, Rule[]>;
}

export class DefaultRuleRepository implements RuleRepository {
  private readonly entities: Map<string, Ruleset | undefined> = new Map<string, Ruleset | undefined>();
  private readonly registrars: Map<keyof Ruleset, (ruleset: RulesetMetadata) => Record<string, unknown>> = new Map();
  private readonly loadedRoutes: Set<string> = new Set<string>();

  clear(): void {
    this.entities.clear();
    this.loadedRoutes.clear();
  }

  addRegistrar<T extends keyof Ruleset>(
    name: T,
    registrar: (ruleset: RulesetMetadata) => Record<string, unknown>,
  ): void {
    this.registrars.set(name, registrar);
  }

  get(entityName: string, okIfNotFound?: boolean): Ruleset | null {
    const result = this.entities.get(entityName);
    if (!result && !okIfNotFound) {
      throw new Error(`Rules have not been loaded for "${entityName}" yet.`);
    }
    return result || null;
  }

  async loadEntityAsync(entityName: string): Promise<Ruleset> {
    const result = this.entities.get(entityName);
    if (result) {
      return result;
    } else {
      const routeName = entityMappingService.getRouteNames(entityName)?.[0];
      if (!routeName) {
        throw new Error(`No route is defined for "${entityName}"`);
      }
      await this.loadRouteAsync(routeName);

      const ruleset = this.entities.get(entityName);
      if (!ruleset) {
        throw new Error(`Could not load ruleset for entity "${entityName}"`);
      }
      return ruleset;
    }
  }

  async loadRouteAsync(routeName: string): Promise<void> {
    if (this.loadedRoutes.has(routeName)) {
      return;
    } else {
      const rulesets = await getRuleSetsAsync(routeName);
      const entitiesOut: Map<string, Ruleset> = new Map<string, Ruleset>();

      extractDerivedRulesets(rulesets);

      for (let i = 0; i < rulesets.length; i++) {
        const ruleset = rulesets[i];
        entitiesOut.set(ruleset.entityName, this.getRulesForRuleset(ruleset));
      }

      entitiesOut.forEach((value, key) => {
        this.entities.set(key, value);
      });
      this.loadedRoutes.add(routeName);
    }
  }

  private getRulesForRuleset(ruleSet: RulesetMetadata): Ruleset {
    const result: Ruleset = {
      entityName: ruleSet.entityName,
      addressEditMode: ruleSet.addressEditMode,
      allColumns: ruleSet.allColumns,
      allFilterKeys: ruleSet.allFilterKeys,
      attachable: ruleSet.attachable,
      barcodeParsing: ruleSet.barcodeParsing,
      characterCasing: ruleSet.characterCasing,
      charBoolean: ruleSet.charBoolean,
      codeProperty: ruleSet.codeProperty,
      colorSchemeProperty: ruleSet.colourSchemeProperty,
      conditions: ruleSet.conditions,
      docManagerCode: ruleSet.docManagerCode,
      hideEDocs: ruleSet.hideEDocs,
      defaultDerivedTypeName: ruleSet.defaultDerivedTypeName,
      defaultDisplayMode: ruleSet.defaultDisplayMode,
      defaultMaintainFormFlow: ruleSet.defaultMaintainFormFlow,
      defaultRemoveFormFlow: ruleSet.defaultRemoveFormFlow,
      defaultActivateFormFlow: ruleSet.defaultActivateFormFlow,
      defaultRemoveFormFlowForProperties: ruleSet.defaultRemoveFormFlowForProperties,
      defaultAdvancedSearchMode: ruleSet.defaultAdvancedSearchMode,
      descriptionProperty: ruleSet.descriptionProperty,
      dateTimeType: ruleSet.dateTimeType,
      dbMappingOverride: ruleSet.dbMappingOverride,
      documentContext: ruleSet.documentContext,
      documentSources: ruleSet.documentSources,
      dynamicMetadataProviders: ruleSet.dynamicMetadataProviders,
      dynamicMetadataProviderRules: ruleSet.dynamicMetadataProviders?.map((name) => ruleFunction(name)),
      entityFieldConfigurations: ruleSet.entityFieldConfigurations,
      eventSources: ruleSet.eventSources,
      expandPaths: ruleSet.expandPaths || {},
      propertiesFieldConfigurations: ruleSet.propertiesFieldConfigurations,
      formFlowActions: ruleSet.formFlowActions,
      hierarchy: ruleSet.hierarchy,
      icon: ruleSet.icon,
      isActiveProperty: ruleSet.isActiveProperty,
      isConversationProvider: ruleSet.isConversationProvider,
      isImportable: ruleSet.isImportable,
      isReadOnly: ruleSet.isReadOnly,
      maxLength: ruleSet.maxLength,
      nonExpandable: ruleSet.nonExpandable,
      noteSources: ruleSet.noteSources,
      numericSize: ruleSet.numericSize,
      numericRange: ruleSet.numericRange,
      propertyReadOnly: ruleSet.propertyReadOnly,
      quickSearchPaths: ruleSet.quickSearchPaths,
      removable: ruleSet.removable,
      removalMode: ruleSet.removalMode,
      typeDescriptionProperty: ruleSet.typeDescriptionProperty,
      unitFilter: ruleSet.unitFilter,
      unitStrategy: ruleSet.unitStrategy,
      userActivityNotification: ruleSet.userActivityNotification,
      availableNoteTypes: ruleSet.availableNoteTypes,
      allowEntityActionsWhenInactive: ruleSet.allowEntityActionsWhenInactive,
      workflow: ruleSet.workflow,
      hideWorkflow: ruleSet.hideWorkflow,
    };

    addRules(result, ruleSet.rules);
    this.invokeRegistrars(result, ruleSet);

    return result;
  }

  private invokeRegistrars(container: Ruleset, ruleSet: RulesetMetadata): void {
    this.registrars.forEach((value, key) => {
      const item = value(ruleSet);
      if (item) {
        container[key] = item;
      }
    });
  }
}

async function getRuleSetsAsync(routeName: string): Promise<RulesetMetadata[]> {
  if (global.useCompiledRulesets) {
    return await loadRulesetAsync(routeName);
  } else {
    const ajaxSettings = {
      /*! SuppressStringValidation (No caption here) */
      url: global.serviceUri + "api/rulesets/route/" + routeName,
      dataType: "json",
    };

    try {
      return await ajaxService.ajaxAsync<RulesetMetadata[]>(ajaxSettings);
    } catch (error) {
      if (error instanceof AjaxError) {
        throw new LoadRulesetException(routeName, error);
      }
      throw error;
    }
  }
}

function addRule(rulesForType: Record<string, Rule[]>, propertyName: string, rule: Rule): void {
  let propertyRules = rulesForType[propertyName];

  if (!propertyRules) {
    rulesForType[propertyName] = propertyRules = [];
  }

  propertyRules.push(rule);
}

function addRules(container: Ruleset, ruleDefinitions?: Record<Capitalize<RuleType>, RuleDefinitionBase[]>): void {
  if (!ruleDefinitions) {
    return;
  }

  Object.entries(ruleDefinitions).forEach(([ruleType, ruleDefinitionsForType]) => {
    const localRuleType = (ruleType.charAt(0).toLowerCase() + ruleType.slice(1)) as RuleType;
    let rulesForType = container[localRuleType];
    if (!rulesForType) {
      container[localRuleType] = rulesForType = {};
    }

    ruleDefinitionsForType.forEach((item) => {
      const rule = new Rule({
        ...item,
        expandPaths: container.expandPaths?.[item.property],
        ruleType: localRuleType,
      });
      addRule(rulesForType, item.property, rule);
    });
  });
}

export default new DefaultRuleRepository();
