import { DropDownDisplayMode } from "Constants";
import type { DateTimeType } from "DateTimeConstants";
import { promiseObserver } from "KnockoutExtensions";
import type { LookupMetadata } from "Lookups/LookupMetadataFactory";
import type Rule from "Rule";
import ruleRepository, { type Ruleset } from "RuleRepository";
import { RuleType } from "RuleType";
import type {
  AttachableMetadata,
  AvailableColumnMetadata,
  BarcodeParsingMetadata,
  DbMappingOverrideMetadata,
  FieldConfiguration,
  FilterKeyMetadata,
  FormFlowActionDefinition,
  NumericRangeMetadata,
  NumericSizeMetadata,
  PropertyReadOnly,
  RemovableMetadata,
  RemovalModeMetadata,
  UnitFilterMetaData,
  WorkflowMetadata,
} from "RulesetMetadata";
import type { DynamicMetadataProvider } from "./DynamicMetadataProvider";

export default class RuleService {
  constructor(
    readonly entityName: string,
    private readonly rules: Ruleset,
  ) {}

  static get(entityName: string): RuleService;
  static get(entityName: string, okIfNotFound: boolean): RuleService | null;

  static get(entityName: string, okIfNotFound?: boolean): RuleService | null {
    const rules = ruleRepository.get(entityName, okIfNotFound);
    return rules && new RuleService(entityName, rules);
  }

  static async loadAsync(entityName: string): Promise<RuleService> {
    const rules = await ruleRepository.loadEntityAsync(entityName);
    return new RuleService(entityName, rules);
  }

  static observe(entityName: string): RuleService | null {
    let rule = RuleService.get(entityName, true);
    if (!rule) {
      rule = promiseObserver(RuleService.loadAsync(entityName)).read() || null;
    }
    return rule;
  }

  allColumns(): AvailableColumnMetadata[] {
    return this.rules.allColumns || [];
  }

  allFilterKeys(): FilterKeyMetadata[] {
    return this.rules.allFilterKeys || [];
  }

  entityFieldConfigurations(): FieldConfiguration[] {
    return this.rules.entityFieldConfigurations || [];
  }

  propertyFieldConfiguration(propertyName: string): FieldConfiguration[] | null {
    const fieldConfigurations = this.rules.propertiesFieldConfigurations;
    return (fieldConfigurations && fieldConfigurations[propertyName]) || null;
  }

  isActiveProperty(): string | undefined {
    return this.rules.isActiveProperty;
  }

  isConversationProvider(): boolean {
    return !!this.rules.isConversationProvider;
  }

  isImportable(): boolean {
    return this.rules.isImportable || false;
  }

  docManagerCode(): string | undefined {
    return this.rules.docManagerCode;
  }

  hideEDocs(): boolean {
    return this.rules.hideEDocs === true;
  }

  canViewDocuments(): boolean {
    return !!this.rules.documentSources || !!this.rules.documentContext;
  }

  defaultMaintainFormFlow(): string | undefined {
    return this.rules.defaultMaintainFormFlow;
  }

  defaultDerivedTypeName(): string | undefined {
    return this.rules.defaultDerivedTypeName;
  }

  codeProperty(): string | null {
    return this.rules.codeProperty || null;
  }

  colorSchemeProperty(): string | null {
    return this.rules.colorSchemeProperty || null;
  }

  commandRule(propertyName: string): Rule;
  commandRule(propertyName: string, okIfNotFound: boolean): Rule | null;
  commandRule(propertyName: string, okIfNotFound?: boolean): Rule | null {
    /*! SuppressStringValidation String validation suppressed in initial refactor */
    return this.getRule(RuleType.Command, propertyName, okIfNotFound);
  }

  condition(id: string): string;
  condition(id: string, okIfNotFound: boolean): string | null;
  condition(id: string, okIfNotFound?: boolean): string | null {
    const conditions = this.rules.conditions;
    const result = conditions?.[id] || null;

    if (!result && !okIfNotFound) {
      /*! SuppressStringValidation Exception message */
      throw new Error(`Could not find condition with id "${id}" on ${this.entityName}.`);
    }

    return result;
  }

  dbMappingOverride(propertyName: string): DbMappingOverrideMetadata | null {
    const items = this.rules.dbMappingOverride;
    return items ? items[propertyName] || null : null;
  }

  defaultDisplayMode(): DropDownDisplayMode {
    return this.rules.defaultDisplayMode || DropDownDisplayMode.Unspecified;
  }

  descriptionProperty(): string | null {
    return this.rules.descriptionProperty || null;
  }

  isReadOnly(): boolean | { Condition: string } {
    return this.rules.isReadOnly || false;
  }

  expandPaths(propertyName: string): string[] | null {
    const items = this.rules.expandPaths;
    return items ? items[propertyName] || null : null;
  }

  defaultAdvancedSearchMode(): string | undefined {
    return this.rules.defaultAdvancedSearchMode;
  }

  formFlowActions(propertyName: string, formFlowPK: string): FormFlowActionDefinition | undefined {
    const actions = this.rules.formFlowActions;
    if (actions) {
      const actionsForProperty = actions[propertyName];
      if (actionsForProperty) {
        const formFlowActionDefinitions = actionsForProperty.formFlowActionDefinitions;
        if (formFlowActionDefinitions) {
          return formFlowActionDefinitions.find((a) => a.formFlowPK === formFlowPK);
        }
      }
    }
    return undefined;
  }

  unitFilter(propertyName: string): UnitFilterMetaData | null {
    const items = this.rules.unitFilter;
    return items ? items[propertyName] || null : null;
  }

  lookupMetadata(propertyName: string): LookupMetadata | null {
    const items = this.rules.lookupMetadata;
    return items ? items[propertyName] || null : null;
  }

  lookupRule(propertyName: string): Rule;
  lookupRule(propertyName: string, okIfNotFound: boolean): Rule | null;
  lookupRule(propertyName: string, okIfNotFound?: boolean): Rule | null {
    /*! SuppressStringValidation String validation suppressed in initial refactor */
    return this.getRule(RuleType.Lookup, propertyName, okIfNotFound);
  }

  lookupRuleById(ruleId: string): Rule;
  lookupRuleById(ruleId: string, okIfNotFound: boolean): Rule | null;
  lookupRuleById(ruleId: string, okIfNotFound?: boolean): Rule | null {
    const rules = this.rules.lookup;
    if (rules) {
      for (const propertyName in rules) {
        if (Object.prototype.hasOwnProperty.call(rules, propertyName)) {
          const propertyRules = rules[propertyName];
          const result = propertyRules.find((rule) => rule.ruleId === ruleId);
          if (result) {
            return result;
          }
        }
      }
    }

    if (okIfNotFound) {
      return null;
    }

    /*! SuppressStringValidation Exception message */
    throw new Error(`Could not find rule with id "${ruleId}" on ${this.entityName}.`);
  }

  maxLength(propertyName: string): number | null {
    const maxLength = this.rules.maxLength;
    return maxLength ? maxLength[propertyName] || null : null;
  }

  propertyReadOnly(propertyName: string): PropertyReadOnly | null {
    const propertyReadOnly = this.rules.propertyReadOnly;
    const propertyReadOnlyValue = propertyReadOnly ? propertyReadOnly[propertyName] : null;
    return propertyReadOnlyValue !== undefined ? propertyReadOnlyValue : null;
  }

  nonExpandable(): boolean {
    return !!this.rules.nonExpandable;
  }

  numericSize(propertyName: string): NumericSizeMetadata | null {
    return this.rules.numericSize ? this.rules.numericSize[propertyName] || null : null;
  }

  numericRange(propertyName: string): NumericRangeMetadata | null {
    return this.rules.numericRange ? this.rules.numericRange[propertyName] || null : null;
  }

  hierarchy(): Record<string, unknown> | undefined {
    return this.rules.hierarchy;
  }

  unitStrategy(propertyName: string): string | undefined {
    return this.rules.unitStrategy?.[propertyName];
  }

  eventSources(): string[] | null {
    return this.rules.eventSources || null;
  }

  noteSources(): string[] {
    return this.rules.noteSources || [];
  }

  conditions(): Record<string, string> | null {
    return this.rules.conditions || null;
  }

  documentSources(): string[] | null {
    return this.rules.documentSources || null;
  }

  documentContext(): string | undefined {
    return this.rules.documentContext;
  }

  icon(): string | null {
    return this.rules.icon || null;
  }

  dateTimeType(propertyName: string): DateTimeType | null {
    return this.rules.dateTimeType ? this.rules.dateTimeType[propertyName] || null : null;
  }

  propertyRule(propertyName: string): Rule;
  propertyRule(propertyName: string, okIfNotFound: boolean): Rule | null;
  propertyRule(propertyName: string, okIfNotFound?: boolean): Rule | null {
    /*! SuppressStringValidation String validation suppressed in initial refactor */
    return this.getRule(RuleType.Property, propertyName, okIfNotFound);
  }

  propertyRules(): Rule[] {
    let result: Rule[] = [];
    const rules = this.rules.property;

    if (rules) {
      for (const name in rules) {
        result = result.concat(rules[name]);
      }
    }

    return result;
  }

  dependentProperties(propertyName: string): string[] {
    const result = new Set<string>();
    result.add(propertyName);

    const dependentPropertiesMap = this.propertyRules().reduce((map, rule) => {
      rule.getAllDependencies().forEach((dependency) => {
        dependency.getAllDependencyPaths().forEach((dependencyPath) => {
          const separatorIndex = dependencyPath.indexOf(".");
          const dependentProperty = separatorIndex > -1 ? dependencyPath.slice(0, separatorIndex) : dependencyPath;
          let properties = map.get(dependentProperty);
          if (!properties) {
            properties = new Set<string>();
            map.set(dependentProperty, properties);
          }
          properties.add(rule.property);
        });
      });
      return map;
    }, new Map<string, Set<string>>());

    this.findDependentProperties(dependentPropertiesMap, propertyName, result);
    return Array.from(result);
  }

  private findDependentProperties(
    dependentPropertiesMap: Map<string, Set<string>>,
    propertyName: string,
    result: Set<string>,
  ): void {
    const entry = dependentPropertiesMap.get(propertyName);
    if (entry) {
      entry.forEach((nextProperty) => {
        if (!result.has(nextProperty)) {
          result.add(nextProperty);
          this.findDependentProperties(dependentPropertiesMap, nextProperty, result);
        }
      });
    }
  }

  proposedValueRules(): Record<string, Rule[]> {
    return this.rules.proposedValue || {};
  }

  removalMode(propertyName: string): RemovalModeMetadata | null {
    const removalMode = this.rules.removalMode;
    return (removalMode && removalMode[propertyName]) || null;
  }

  removable(): RemovableMetadata | null {
    return this.rules.removable || null;
  }

  validationRules(): Record<string, Rule[]> {
    return this.rules.validation || {};
  }

  typeDescriptionProperty(): string | null {
    return this.rules.typeDescriptionProperty || null;
  }

  quickSearchPaths(): string[] {
    return this.rules.quickSearchPaths || [];
  }

  addressEditMode(propertyName: string): string | null {
    const addressEditMode = this.rules.addressEditMode;
    return (addressEditMode && addressEditMode[propertyName]) || null;
  }

  barcodeParsing(propertyName: string): BarcodeParsingMetadata | null {
    const barcodeParsing = this.rules.barcodeParsing;
    return (barcodeParsing && barcodeParsing[propertyName]) || null;
  }

  characterCasing(propertyName: string): string {
    const characterCasing = this.rules.characterCasing;
    return (characterCasing && characterCasing[propertyName]) || "";
  }

  charBoolean(propertyName: string): boolean {
    const charBoolean = this.rules.charBoolean;
    return (charBoolean && charBoolean[propertyName]) || false;
  }

  attachable(propertyName: string): AttachableMetadata | null {
    const attachable = this.rules.attachable;
    return (attachable && attachable[propertyName]) || null;
  }

  allowEntityActionsWhenInactive(): boolean {
    return !!this.rules.allowEntityActionsWhenInactive;
  }

  userActivityNotification(): boolean {
    return !!this.rules.userActivityNotification;
  }

  defaultRemoveFormFlow(): string | undefined {
    return this.rules.defaultRemoveFormFlow;
  }

  defaultRemoveFormFlowForProperty(propertyName: string): string | undefined {
    return this.rules.defaultRemoveFormFlowForProperties && this.rules.defaultRemoveFormFlowForProperties[propertyName];
  }

  defaultActivateFormFlow(): string | undefined {
    return this.rules.defaultActivateFormFlow;
  }

  workflow(): WorkflowMetadata | undefined {
    return this.rules.workflow;
  }

  hideWorkflow(): boolean {
    return this.rules.hideWorkflow === true;
  }

  async getDynamicMetadataProvidersAsync(): Promise<DynamicMetadataProvider[]> {
    const dynamicMetadataProvidersRules = this.rules.dynamicMetadataProviderRules;
    if (!dynamicMetadataProvidersRules) {
      return [];
    }
    const promises = dynamicMetadataProvidersRules.map((rule) => {
      const provider = rule() as unknown as Promise<DynamicMetadataProvider>;
      return provider;
    });

    const providers = await Promise.all(promises);
    return providers;
  }

  private getRule(ruleType: RuleType, propertyName: string, okIfNotFound?: boolean): Rule | null {
    const result = this.rules[ruleType]?.[propertyName]?.[0];

    if (!result && !okIfNotFound) {
      throw new Error(`Could not find ${ruleType} rule for property "${propertyName}" on ${this.entityName}.`);
    }

    return result || null;
  }
}
