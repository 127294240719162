import captionService from 'CaptionService';

export const conversation = {
	follow: () => captionService.getString('b3aebbde-3a79-46d7-96cb-b84f0f34a8fa', 'Follow'),
	followers: () => captionService.getString('74edd416-08f7-46db-af73-30353ec49880', 'Followers'),
	placeholder: () => captionService.getString('141f2312-9697-4266-86ae-c40fe04a29c0', 'Write your message here...'),
	send: () => captionService.getString('57fca019-26de-4c68-93c5-6eabd38c6e17', 'Send'),
	following: () => captionService.getString('039ff0ff-f9bf-45f1-b396-acc25b2df748', 'Following'),
	addFollowers: () => captionService.getString('f3e34d05-c1db-4f63-ab5c-ab550486f50d', 'Add followers'),
	searchPotentialFollowers: (params) => captionService.getString('093c948b-219d-4d7b-b281-e3864b659d11', 'Search for {0}', ...params),
	dialog: {
		close: () => captionService.getString('507699a1-9a23-494c-880f-8171cedf3726', 'Close'),
		messages: () => captionService.getString('0a09669e-9699-42a8-a4bf-5454a00499f6', 'Messages'),
		removeFollower: {
			areYourSure: (params) => captionService.getString('fafb2f9b-eb47-4004-a2b6-63e51f389101', 'Are you sure you want to remove follower {0}?', ...params),
			no: () => captionService.getString('daab9e5a-ba65-432c-bc35-cc9291e8ade6', 'No'),
			title: () => captionService.getString('764a592c-fd09-44f6-a3d3-1a0dbeb1ffe1', 'Remove follower'),
			yes: () => captionService.getString('c7df7948-c772-49e5-86e6-2de072d99fe2', 'Yes'),
		},
		unsentMessage: {
			cancel: () => captionService.getString('f415821c-f9cc-45fa-8c33-dab41027df07', 'Cancel'),
			discard: () => captionService.getString('7409e643-56eb-4a58-9324-9e786f82f586', 'Discard'),
			haveUnsentMessage: () => captionService.getString('2fa0ed3f-e70c-437b-80ee-602efb911ba5', 'You have an unsent message.'),
			send: () => captionService.getString('707dd02a-ed20-4ae4-b68e-2c9a6329235f', 'Send'),
			title: () => captionService.getString('95fec54b-3301-474f-adc2-fa34295aac4e', 'Unsent message'),
		},
		addFollowersFailure: {
			title: () => captionService.getString('ee9ea23c-5f75-4340-a830-f0c35e54a54a', 'Error adding followers'),
			message: (params) => captionService.getString('e9387a68-f8a1-44df-b03a-c1a08568c7a4', 'An error occurred while adding followers. We were unable to add the {0} followers.', ...params),
		},
	}
};
