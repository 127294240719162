import ajaxService, { AjaxError } from "AjaxService";
import type { DependencyResult } from "DependencyResult";
import { getInterfaceName, isEntity } from "EntityExtensions";
import { RuleInvocationException, UnavailableArgumentsOrSecurityError } from "Errors";
import global from "Global";
import type { RuleComponent } from "Rule";
import ruleDependencyValue from "RuleDependencyValue";
import type { RuleParameter } from "RulesetMetadata";
import type { ValueConverter } from "ServerSideRuleValueConverter";
import { State } from "StateConstants";
import type { RuleDescriptor, RuleProcessResult, SubRule } from "SubRule";
import { joinUri } from "UriUtils";

class ServerSideRule implements SubRule {
  readonly dependencies: string[];
  readonly hasCacheKeyFunc: boolean;
  readonly parameters: RuleParameter[];
  readonly uri: string;
  private readonly returnType?: string;
  private readonly valueConverter?: ValueConverter;

  constructor(component: RuleComponent, valueConverter: ValueConverter | undefined, returnType: string | undefined) {
    if (!component.uri) {
      throw new Error("component must have a uri.");
    }

    this.uri = component.uri;
    this.valueConverter = valueConverter;
    this.parameters = component.parameters || [];
    this.returnType = returnType;
    this.hasCacheKeyFunc = false;
    this.dependencies = component.dependencies || [];
  }

  async loadCacheKeyFuncAsync(): Promise<void> {
    await Promise.resolve();
  }

  getCacheKey(): unknown {
    return;
  }

  async processAsync(rule: RuleDescriptor, entity: unknown): Promise<RuleProcessResult> {
    let loadingDependencies;
    if (this.dependencies.length) {
      const dependencies = this.dependencies.map((path) => {
        return { value: `<${path}>` };
      });
      loadingDependencies = ruleDependencyValue.getValuesAsync(entity, dependencies);
    }

    await loadingDependencies;
    const result = await ruleDependencyValue.getSerializableValuesByNameAsync(entity, this.parameters);
    if (result.state === State.Available) {
      return await this.processCoreAsync(result.values, rule, entity);
    } else {
      throw new UnavailableArgumentsOrSecurityError();
    }
  }

  tryProcessSync(): DependencyResult {
    return { state: State.NotLoaded };
  }

  hasSetter(): boolean {
    return false;
  }

  async invokeSetterAsync(): Promise<boolean> {
    return await Promise.resolve(false);
  }

  private async processCoreAsync(
    valuesByName: Record<string, unknown>,
    rule: RuleDescriptor,
    entity: unknown,
  ): Promise<RuleProcessResult> {
    const uri = joinUri(global.serviceUri, this.uri);
    const query: Record<string, unknown> = {};

    for (const name in valuesByName) {
      const value = valuesByName[name];
      if (value != null) {
        query[name] = value;
      }
    }

    try {
      const value = await ajaxService.getAsync(uri, query);
      return { isSuccess: true, value: this.convertValue(value) };
    } catch (error) {
      if (error instanceof AjaxError) {
        /*! SuppressStringValidation no entity type indicator */
        const entityInterfaceName = isEntity(entity) ? getInterfaceName(entity) : "N/A";
        throw new RuleInvocationException(entityInterfaceName, rule.ruleId, rule.property, error);
      }
      throw error;
    }
  }

  private convertValue(value: unknown): unknown {
    const converter = this.valueConverter;
    return converter ? converter(value, this.returnType) : value;
  }
}

export default ServerSideRule;
