import { AjaxError } from "AjaxService";
import type { ValueConverter } from "ClientSideRuleValueConverter";
import connection from "Connection";
import type { DependencyResult } from "DependencyResult";
import { getInterfaceName as getInterfaceNameCore, isEntity } from "EntityExtensions";
import {
  DataServiceRequestError,
  findError,
  isCritical,
  RuleInvocationException,
  UnavailableArgumentsOrSecurityError,
} from "Errors";
import global from "Global";
import log from "Log";
import type { RuleComponent } from "Rule";
import ruleDependencyValue from "RuleDependencyValue";
import ruleFunction, { type RuleFunction } from "RuleFunction";
import type { RuleParameter, RuleSetterParameter } from "RulesetMetadata";
import { State } from "StateConstants";
import type { RuleDescriptor, RuleProcessResult, SubRule } from "SubRule";

class ClientSideRule implements SubRule {
  readonly dependencies: string[];
  readonly hasCacheKeyFunc: boolean;
  readonly parameters: RuleParameter[];
  private readonly cacheKeyFunc?: RuleFunction;
  private readonly func: RuleFunction;
  private readonly setterFunc?: RuleFunction;
  private readonly setterParameters: RuleSetterParameter[];
  private readonly valueConverter?: ValueConverter;

  constructor(component: RuleComponent, valueConverter: ValueConverter | undefined) {
    if (!component.func) {
      throw new Error("component must have a func.");
    }

    this.func = ruleFunction(component.func);
    this.parameters = component.parameters || [];
    this.setterFunc = component.setterFunc ? ruleFunction(component.setterFunc) : undefined;
    this.setterParameters = component.setterParameters || [];
    this.valueConverter = valueConverter;
    this.cacheKeyFunc = component.cacheKeyFunc ? ruleFunction(component.cacheKeyFunc) : undefined;
    this.hasCacheKeyFunc = !!this.cacheKeyFunc;
    this.dependencies = component.dependencies || [];
  }

  async loadCacheKeyFuncAsync(): Promise<void> {
    await this.cacheKeyFunc?.loadAsync();
  }

  getCacheKey(entity: unknown): unknown {
    if (this.cacheKeyFunc) {
      const args = ruleDependencyValue.getValues(entity, this.parameters);
      if (args.state === State.Available) {
        return this.cacheKeyFunc.apply(null, args.values);
      }
    }
    return;
  }

  async processAsync(rule: RuleDescriptor, entity: unknown, context?: object): Promise<RuleProcessResult> {
    const startTime = performance.now();
    let loadingDependencies;
    if (this.dependencies.length) {
      const dependencies = this.dependencies.map((path) => {
        return { value: `<${path}>` };
      });
      loadingDependencies = ruleDependencyValue.getValuesAsync(entity, dependencies);
    }

    await loadingDependencies;
    if (global.featureFlags.logRuleInvocationTimings) {
      log.info(`[Rule] Dependencies loaded for ${rule.ruleId} in ${performance.now() - startTime}ms.`);
    }

    const { state, values } = await ruleDependencyValue.getValuesAsync(entity, this.parameters);
    if (state !== State.Available) {
      throw new UnavailableArgumentsOrSecurityError();
    }

    return await this.processCore(rule, entity, context, values, async (value: unknown): Promise<RuleProcessResult> => {
      try {
        value = await value;
      } catch (error) {
        if (error instanceof Error) {
          if (error instanceof connection.OfflineError || isCritical(error)) {
            throw error;
          }
          assertIsNotForbidden(error);
        }
        throw new RuleInvocationException(getInterfaceName(entity), rule.ruleId, rule.property, error);
      }

      return {
        isSuccess: true,
        value: this.convertValue(value, values, rule.returnType),
      };
    });
  }

  tryProcessSync(rule: RuleDescriptor, entity: unknown, context?: object): DependencyResult {
    if (this.dependencies.length > 0) {
      const dependencies = this.dependencies.map((path) => {
        return { value: `<${path}>` };
      });

      if (ruleDependencyValue.getValues(entity, dependencies).state === State.NotLoaded) {
        return { state: State.NotLoaded };
      }
    }

    if (!this.func.isLoaded()) {
      return { state: State.NotLoaded };
    }

    const { state, values } = ruleDependencyValue.getValues(entity, this.parameters);

    if (state === State.NotAvailable) {
      throw new UnavailableArgumentsOrSecurityError();
    }

    if (state === State.Available) {
      return this.processCore(rule, entity, context, values, (value) => {
        if (value instanceof Promise) {
          throw new Error("tryProcessSync should not be used for async rule: " + rule.ruleId);
        }

        return {
          state: State.Available,
          value: this.convertValue(value, values, rule.returnType),
        };
      });
    } else {
      return { state };
    }
  }

  hasSetter(): boolean {
    return !!this.setterFunc;
  }

  async invokeSetterAsync(entity: unknown, value: unknown): Promise<boolean> {
    if (!this.setterFunc) {
      throw new Error("There is no setterFunc.");
    }

    const result = await ruleDependencyValue.getValuesAsync(entity, this.setterParameters);
    if (result.state === State.Available) {
      const values = result.values;
      values.unshift(entity, value);
      try {
        await this.setterFunc(...values);
        return true;
      } catch (error) {
        if (error instanceof Error) {
          assertIsNotForbidden(error);
        }
        throw error;
      }
    } else if (result.state === State.NotAvailable) {
      throw new UnavailableArgumentsOrSecurityError();
    } else {
      return false;
    }
  }

  private processCore<T>(
    rule: RuleDescriptor,
    entity: unknown,
    context: object | undefined,
    values: unknown[],
    finalize: (value: unknown) => T,
  ): T {
    const extendedContext = {
      entity,
      entityManager: isEntity(entity) ? entity.entityAspect.entityManager : null,
      ...context,
    };
    try {
      const value = this.func.apply(extendedContext, values);
      return finalize(value);
    } catch (error) {
      if (error instanceof Error && isCritical(error)) {
        throw error;
      }
      throw new RuleInvocationException(getInterfaceName(entity), rule.ruleId, rule.property, error);
    }
  }

  private convertValue(value: unknown, values: unknown[], returnType: string | undefined): unknown {
    const converter = this.valueConverter;
    if (converter) {
      value = converter(value, values, returnType);
    }

    return value;
  }
}

function getInterfaceName(entity: unknown): string {
  /*! SuppressStringValidation no entity type indicator */
  return isEntity(entity) ? getInterfaceNameCore(entity) : "N/A";
}

function assertIsNotForbidden(error: Error): void {
  const networkError = findError(error, DataServiceRequestError) || findError(error, AjaxError);
  if (networkError?.status === 403) {
    throw new UnavailableArgumentsOrSecurityError(error);
  }
}

export default ClientSideRule;
