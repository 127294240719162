import { type ActivityInvoker, type ActivityResult } from "ActivityInvokerProvider";
import captionService from "CaptionService";
import { isEntity } from "EntityExtensions";
import { isNetworkError, RetryableActivityInvocationError } from "Errors";
import { FormFlowError, FormFlowErrorType } from "FormFlowError";
import { type FormFlowSession } from "FormFlowSession";
import { type FormFlowActivity } from "FormFlowTypes";
import { type EntityCollection } from "VariableStrategyFactory";
import { type Entity } from "breeze-client";

type GetInvokerWithEntitiesCallbackFn<T extends FormFlowActivity> = (
  session: FormFlowSession,
  activity: T,
  entities: EntityCollection<Entity>,
) => Promise<ActivityResult | undefined>;

type GetInvokerWithEntityCallbackFn<T extends FormFlowActivity> = (
  session: FormFlowSession,
  activity: T,
  entity: Entity,
) => Promise<ActivityResult | undefined>;

type GetInvokerWithoutEntityCallbackFn<T extends FormFlowActivity> = (
  session: FormFlowSession,
  activity: T,
) => Promise<ActivityResult | undefined>;

type GetInvokerWithoutVariableCallbackFn<T extends FormFlowActivity> = (
  session: FormFlowSession,
  activity: T,
) => Promise<ActivityResult | undefined>;

type GetStringProps<T> = Omit<
  {
    [K in keyof T as T[K] extends string | undefined ? K : never]: T[K];
  },
  keyof FormFlowActivity
>;

class ActivityInvokerHelper {
  /**
   * Helper to create an activity invoker that will invoke the correct callback depends on the state of nominated entity. If appropriate callback is not provided, it will throw an error instead.
   *
   * @param activityVariablePropertyName Activity's property name that stores the variable name of the nominated entity.
   * @param withEntityCallback Callback to invoke when a variable is nominated as an entity, is found and also of Entity type).
   * @param withoutEntityCallback Callback to invoke when the variable value is undefined
   * @param withoutVariableCallback Callback to invoke when no variable is nominated as an entity.
   * @returns ActivityInvoker
   */
  getInvokerWithEntity<T extends FormFlowActivity>(
    activityVariablePropertyName: keyof GetStringProps<T>,
    withEntityCallback: GetInvokerWithEntityCallbackFn<T>,
    withoutEntityCallback?: GetInvokerWithoutEntityCallbackFn<T>,
    withoutVariableCallback?: GetInvokerWithoutVariableCallbackFn<T>,
  ): ActivityInvoker<T> {
    return async (session: FormFlowSession, activity: T): Promise<ActivityResult | undefined> => {
      const variableName = activity[activityVariablePropertyName];
      if (variableName && typeof variableName === "string") {
        try {
          const entity = await session.getVariableValueAsync(variableName);
          if (!entity) {
            if (withoutEntityCallback) {
              return withoutEntityCallback(session, activity);
            } else {
              throw new FormFlowError(
                captionService.getString(
                  "be73d540-571c-4d5d-858a-c56eec694dcf",
                  "A record was not found, and so the form-flow will exit. The record may have been deleted.",
                ),
                {
                  type: FormFlowErrorType.NonReportableRuntimeError,
                  friendlyCaption: captionService.getString("5f9206f3-c2d4-45bf-9e7b-b968baa4665e", "Record not found"),
                },
              );
            }
          } else {
            if (isEntity(entity)) {
              return withEntityCallback(session, activity, entity);
            } else {
              /*! SuppressStringValidation Error messages are not translatable */
              throw new FormFlowError("Expected variable of entity type.");
            }
          }
        } catch (error: unknown) {
          if (error instanceof Error && isNetworkError(error)) {
            throw new RetryableActivityInvocationError(error);
          }

          throw error;
        }
      } else {
        if (withoutVariableCallback) {
          return withoutVariableCallback(session, activity);
        } else {
          /*! SuppressStringValidation Error messages are not translatable */
          throw new FormFlowError("Expected not null/empty variable name.");
        }
      }
    };
  }

  /**
   * Helper to create an activity invoker that will invoke the correct callback depends on the state of nominated entity collection
   *
   * @param activityVariablePropertyName Activity's property name that stores the variable name of the nominated entity collection
   * @param withEntitiesCallback Callback to invoke when a variable is nominated as an entity collection and have value.
   * @param withoutEntitiesCallback Callback to invoke when the variable value is undefined or the length of the entity collection is 0.
   * @param withoutVariableCallback Callback to invoke when no variable is nominated as an entity collection.
   * @returns
   */
  getInvokerWithEntities<T extends FormFlowActivity>(
    activityVariablePropertyName: keyof T & string,
    withEntitiesCallback: GetInvokerWithEntitiesCallbackFn<T>,
    withoutEntitiesCallback: GetInvokerWithoutEntityCallbackFn<T>,
    withoutVariableCallback?: GetInvokerWithoutVariableCallbackFn<T>,
  ): ActivityInvoker<T> {
    return async (session: FormFlowSession, activity: T): Promise<ActivityResult | undefined> => {
      const variableName = activity[activityVariablePropertyName];
      if (variableName && typeof variableName === "string") {
        try {
          const entities = await session.getVariableValueAsync<EntityCollection<Entity>>(variableName);
          if (!entities || entities.length === 0) {
            return withoutEntitiesCallback(session, activity);
          } else {
            if (entities.length && "entityTypeName" in entities && !!entities.entityTypeName) {
              const filteredEntities: EntityCollection<Entity> = entities.filter?.((entity: Entity) => entity !== null);
              if (filteredEntities) {
                filteredEntities.entityTypeName = entities.entityTypeName;
                filteredEntities.metadataStore = entities.metadataStore;
              }

              if (filteredEntities?.length === 0) {
                return withoutEntitiesCallback(session, activity);
              } else {
                return withEntitiesCallback(session, activity, filteredEntities);
              }
            } else {
              /*! SuppressStringValidation Error messages are not translatable */
              throw new FormFlowError("Expected variable of entity collection type.");
            }
          }
        } catch (error: unknown) {
          if (error instanceof Error && isNetworkError(error)) {
            throw new RetryableActivityInvocationError(error);
          }

          throw error;
        }
      } else {
        if (withoutVariableCallback) {
          return withoutVariableCallback(session, activity);
        } else {
          /*! SuppressStringValidation Error messages are not translatable */
          throw new FormFlowError("Expected not null/empty variable name.");
        }
      }
    };
  }

  ensureNotNullRecord(entity: unknown): void {
    if (!entity) {
      throw new FormFlowError(
        captionService.getString(
          "be73d540-571c-4d5d-858a-c56eec694dcf",
          "A record was not found, and so the form-flow will exit. The record may have been deleted.",
        ),
        {
          type: FormFlowErrorType.NonReportableRuntimeError,
          friendlyCaption: captionService.getString("5f9206f3-c2d4-45bf-9e7b-b968baa4665e", "Record not found"),
        },
      );
    }
  }
}

export default new ActivityInvokerHelper();
