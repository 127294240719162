import $ from 'jquery';
import ko from 'knockout';
import Promise from 'bluebird';
import captionService from 'CaptionService';
import dialogService from 'DialogService';
import entityActionsChecker from 'EntityActionsChecker';
import {
	loadWorkflowAuditLogsViewModelAsync,
	loadWorkflowTasksViewModelAsync,
	loadWorkflowMilestonesViewModelAsync,
	loadWorkflowEventsViewModelAsync,
	loadWorkflowExceptionsViewModelAsync,
	loadWorkflowTriggersViewModelAsync,
	loadTemplateAsync
} from 'ModuleLoader';
import ModelProvider from 'ModelProvider';
import materialDesignWorkflowDialogService from 'MaterialDesignWorkflowDialogService';

function showWorkflowAuditLogsAsync(entity, saveableViewModel) {
	if (materialDesignWorkflowDialogService.canShowDialog()) {
		return materialDesignWorkflowDialogService.showAuditLogsAsync(entity, saveableViewModel);
	}

	return saveThenShowModalAsync(entity, saveableViewModel, loadWorkflowAuditLogsViewModelAsync());
}

function getWorkflowOptions(entity, saveableViewModel) {
	if (entity) {
		const options = [];
		const entityType = entity.entityType;

		if (entityActionsChecker.canShowWorkflowOption(entityType, 'WorkflowTasks')) {
			options.push({
				caption: captionService.getString('DF57865E-A40A-48F9-B9EE-E5119392F6D8', 'Tasks'),
				clickHandler: showWorkflowTasksAsync.bind(null, entity, saveableViewModel)
			});
		}
		if (entityActionsChecker.canShowWorkflowOption(entityType, 'WorkflowMilestonesSummary')) {
			options.push({
				caption: captionService.getString('C9AB98DE-B594-43EE-B67D-B1E739059D27', 'Milestones'),
				clickHandler: showWorkflowMilestonesAsync.bind(null, entity, saveableViewModel)
			});
		}
		if (entityActionsChecker.canShowWorkflowOption(entityType, 'WorkflowEventsSummary')) {
			options.push({
				caption: captionService.getString('95EE960E-29EB-409B-9A8A-B5F9A0E42D66', 'Events'),
				clickHandler: showWorkflowEventsAsync.bind(null, entity, saveableViewModel)
			});
		}
		if (entityActionsChecker.canShowWorkflowOption(entityType, 'WorkflowExceptionsSummary')) {
			options.push({
				caption: captionService.getString('36756326-DF92-40B3-92C5-2F3C056345D5', 'Exceptions'),
				clickHandler: showWorkflowExceptionsAsync.bind(null, entity, saveableViewModel),
				alertCount: ko.pureComputed(() => getActionableWorkflowExceptions(entity))
			});
		}
		if (entityActionsChecker.canShowWorkflowOption(entityType, 'WorkflowTriggers')) {
			options.push({
				caption: captionService.getString('4052A3FD-5C2F-4676-930B-851899CBA059', 'Triggers'),
				clickHandler: showWorkflowTriggersAsync.bind(null, entity, saveableViewModel)
			});
		}

		return options;
	}
}

function getActionableWorkflowExceptions(entity) {
	if (entity && entity.WorkflowExceptionsSummary) {
		const exceptions = ko.unwrap(entity.WorkflowExceptionsSummary());
		if (exceptions && exceptions.length) {
			return exceptions.filter((exception) => exception.IsResolved() === false).length;
		}
	}
	return 0;
}

function showWorkflowTasksAsync(entity, saveableViewModel) {
	return saveThenShowModalAsync(entity, saveableViewModel, loadWorkflowTasksViewModelAsync());
}

function showWorkflowMilestonesAsync(entity, saveableViewModel) {
	if (materialDesignWorkflowDialogService.canShowDialog()) {
		return materialDesignWorkflowDialogService.showMilestonesAsync(entity, saveableViewModel);
	}
	return saveThenShowModalAsync(entity, saveableViewModel, loadWorkflowMilestonesViewModelAsync());
}

function showWorkflowEventsAsync(entity, saveableViewModel) {
	if (materialDesignWorkflowDialogService.canShowDialog()) {
		return materialDesignWorkflowDialogService.showEventsAsync(entity, saveableViewModel);
	}

	return saveThenShowModalAsync(entity, saveableViewModel, loadWorkflowEventsViewModelAsync());
}

function showWorkflowExceptionsAsync(entity, saveableViewModel) {
	if (materialDesignWorkflowDialogService.canShowDialog()) {
		return materialDesignWorkflowDialogService.showExceptionsAsync(entity, saveableViewModel);
	}
	return saveThenShowModalAsync(entity, saveableViewModel, loadWorkflowExceptionsViewModelAsync());
}

function showWorkflowTriggersAsync(entity, saveableViewModel) {
	if (materialDesignWorkflowDialogService.canShowDialog()) {
		return materialDesignWorkflowDialogService.showWorkflowTriggersAsync(entity, saveableViewModel);
	}
	return saveThenShowModalAsync(entity, saveableViewModel, loadWorkflowTriggersViewModelAsync());
}

async function saveThenShowModalAsync(entity, saveableViewModel, viewModelPromise) {
	const [result, ViewModelCtor] = await Promise.all([saveAsync(saveableViewModel), viewModelPromise]);

		if (result) {
			return showModalAsync(ViewModelCtor, entity.entityType, entity.entityAspect.getPrimaryKey());
		}
}

function saveAsync(saveableViewModel) {
	if (saveableViewModel) {
		return saveableViewModel.saveWithPromptAsync();
	}

	return Promise.resolve(true);
}

async function showModalAsync(ViewModelCtor, entityType, entityPK) {
	let dialogInfo = null;

	const viewModel = new ViewModelCtor(entityType, entityPK, {
		onCloseCallback() {
			this._skipCloseConfirmation = true;
			dialogService.hide(dialogInfo);
		}
	});

	viewModel.pageExtensions = { modelProvider: new ModelProvider(entityType.metadataStore.getRouteName()) };

	const popupType = viewModel.popupType();

	const view = await loadTemplateAsync(popupType.ModalName);

	const $view = $(view);
	viewModel.onBeforePageBinding.call(viewModel, $view);

	const dialogOptions = {
		autoresize: true,
		title: popupType.Title,
		body: $view[0].outerHTML,
		viewModel,
		includeValidationSummary: true,
		preHideCallback(event) {
			if (!viewModel._skipCloseConfirmation) {
				event.preventDefault();
				viewModel.cancelAsync();
			}
		},
		dialogCss: 'g-workflow-menu-dialog',
		bodyAllowHtml: true,
		headerButtonsDeferred: Promise.resolve($view.find('#workflowModalHeaderButtonsTmpl').html()),
		footerDeferred: Promise.resolve($view.find('#workflowModalFooterButtonsTmpl').html())
	};

	dialogInfo = await dialogService.showDialogAsync(dialogOptions);
	return dialogInfo;
}

export default { showWorkflowAuditLogsAsync, getWorkflowOptions, getActionableWorkflowExceptions, showWorkflowExceptionsAsync };
