import { initializeFormFlowEngine } from "@wtg-glow/form-flow-engine";
import { getFormFlowDefinitionAsync } from "FormFlowDefinitionProvider";
import { FormFlowSessionFactory, type FormFlowInstanceOptions } from "FormFlowSessionFactory";
import type { FormFlowDefinition } from "FormFlowTypes";

export function loadFormFlowDefinitionAsync(
  formFlowId: string,
  options?: FormFlowInstanceOptions,
): Promise<FormFlowDefinition> {
  return getFormFlowDefinitionAsync(formFlowId, options?.clientPK);
}

initializeFormFlowEngine({
  definitionLoader: { load: loadFormFlowDefinitionAsync },
  activityBasedFormFlowFactory: new FormFlowSessionFactory(),
  disableStrictParameterTypeChecking: true, // can be enabled once we stop passing variable strategies and pass values instead.
});
