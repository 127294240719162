import $ from 'jquery';
import ko from 'knockout';
import global from 'Global';
import Promise from 'bluebird';
import modalService from 'ModalService';
import materialDesignDialogService from 'MaterialDesignDialogService';
import 'Widgets/gwLoadingAnimation';

function AsyncIndicator(splashText) {
	this._$element = null;
	this._splashDisposable = null;
	this._splashText = splashText;
	this._progressDialogPromise = null;
}

AsyncIndicator.prototype.show = function (anchor) {
	const $element = getOrCreateElement(this);
	$element.css('z-index', getMaxZIndex());
	$element.appendTo(anchor || document.body);

	if ($element[0] && !!ko.dataFor($element[0])) {
		$element.show();
	} else if ($element[0]) {
		ko.applyBindings({ loadingText: this._splashText }, $element[0]);
	}

	let focusTrapElement = modalService.getFocusTrap($element[0]);
	if (!focusTrapElement) {
		focusTrapElement = modalService.createFocusTrap($element[0]);
		focusTrapElement.activate();
	}

	if (global.materialDesign) {
		if (!this._progressDialogPromise && this._splashText && materialDesignDialogService.canShowProgressDialog()) {
			focusTrapElement.deactivate();
			this._progressDialogPromise = materialDesignDialogService.showProgressDialogAsync(this._splashText);
		}
	} else {
		if (this._splashText) {
			const $splash = $element.find('.g-async-splash');
			$splash.show();
			$splash.css('display', 'flex');

			const startTime = Date.now();
			this._splashDisposable = {
				disposeAsync() {
					const millisecondsTaken = Date.now() - startTime;
					const timeout = Math.max(350 - millisecondsTaken, 0);
					return Promise.delay(timeout).then(() => {
						if ($element[0]) {
							ko.removeNode($element[0]);
						}
					});
				}
			};
		}
	}
};

function getOrCreateElement(self) {
	let $element = self._$element;

	if (!$element) {
		self._$element = $element = $($('#g-async-indicator').html());

		const splashText = self._splashText;
		if (splashText) {
			const $splashContent = $element.find('.g-async-splash-content');
			if ($splashContent[0]) {
				$splashContent.attr('data-bind', 'component: { name: "gwLoadingAnimation", params: { loadingText: loadingText }}');
			}
		}
	}

	return $element;
}

function getMaxZIndex() {
	return (
		Math.max(
			197,
			...[
				...$('.v-menu__content--active:not(script .v-menu__content--active)'),
				...$('.v-dialog__content--active:not(script .v-dialog__content--active)'),
				...$('.g-modal:visible'),
			].map((i) => $(i).css('z-index') || 0)
		) + 2
	);
}

AsyncIndicator.prototype.hideAsync = function () {
	const $element = this._$element;
	if ($element) {
		if (this._splashDisposable) {
			return this._splashDisposable.disposeAsync();
		}
		else {
			if ($element[0]) {
				ko.removeNode($element[0]);
			}
			if (this._progressDialogPromise) {
				return this._progressDialogPromise.then((dialog) => {
					dialog.hide();
					this._progressDialogPromise = null;
				});
			} else {
				return Promise.resolve();
			}
		}
	}
};

export default AsyncIndicator;
