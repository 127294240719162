import alertDialogService from 'AlertDialogService';
import appConfig from 'AppConfig';
import 'BreezeInit';
import captionService from 'CaptionService';
import 'Controllers/AuthenticationController';
import 'Controllers/UserAgreementController';
import { DeferredPromise } from 'DeferredPromise';
import entityMappingService from 'EntityMappingService';
import errorHandlerProxy from 'ErrorHandler';
import errorHandler from 'ErrorHandlerCore';
import global from 'Global';
import { waitForIdleAsync } from 'GlobalBusyStateTracker';
import 'KOBindingContextExtensions';
import 'KOComponents';
import 'KOCustomBindings';
import languageService from 'LanguageService';
import nativeBridge from 'NativeBridge';
import navigationService from 'NavigationService';
import notificationType from 'NotificationType';
import ruleRepository from 'RuleRepository';
import screenResizer from 'ScreenResizer';
import semaphoreProvider from 'SemaphoreProvider';
import sessionExpirationService from 'SessionExpirationService';
import Storage from 'Storage';
import uiService from 'UIService';
import userSession from 'UserSession';
import 'Widgets/gwGroupBox2';
import 'Widgets/gwLabel';
import 'Widgets/gwShellContainer';
import 'Widgets/gwTextBox';
import windowManager from 'WindowManager';
import Promise from 'bluebird';
import $ from 'jQueryExtensions';
import 'jquery.url';
import modernizr from 'modernizr';
import _ from 'underscore';
import 'FormFlowInitializer';

function reload() {
	global.windowLocation.reload();
}

function addErrorHandlerToAjaxService() {
	sessionExpirationService.setAjaxServiceErrorHandler();
}

function restrictOnResize() {
	restrictOnResizeToBeNull(window);
	restrictOnResizeToBeNull(document.body);
}

function restrictOnResizeToBeNull(container) {
	/*! SuppressStringValidation event handler property */
	Object.defineProperty(container, 'onresize', {
		set: () => {},
		get: () => null,
		configurable: true,
	});
}

function addNamespace() {
	/*! SuppressStringValidation No captions here */
	const namespace = '.glow-bootstrapper';
	const result = Array.prototype.slice.call(arguments).map((eventName) => eventName + namespace);

	return result.join(' ');
}

function setUpDocumentEventHandlers() {
	/*! SuppressStringValidation No captions here */
	const mouseEvents = addNamespace('mousedown', 'mousewheel', 'wheel', 'DOMMouseScroll');
	/*! SuppressStringValidation No captions here */
	const clickEvent = addNamespace('click');
	$(document)
		.off(mouseEvents)
		.on(mouseEvents, (e) => {
			if ($(e.target).closest('.hideable-dropdown-menu').length === 0) {
				$('.hideable-dropdown-menu').trigger('hideDropdownMenu', e);
			}
		})
		.off(clickEvent)
		.on(clickEvent, '.g-click-stop-propagation', (e) => {
			e.stopPropagation();
		});
}

function setUpDropdownEventHandlers() {
	/*! SuppressStringValidation No captions here */
	const event = addNamespace('click');
	$(document.body)
		.off(event, '.dropdown-menu.g-popover-dropdown')
		.on(event, '.dropdown-menu.g-popover-dropdown', (e) => {
			if (!$(e.target).is('a')) {
				e.stopPropagation();
			}
		})
		.off(event, '.dropdown-submenu > a')
		.on(event, '.dropdown-submenu > a', (e) => {
			e.preventDefault();
			e.stopPropagation();

			const $main = $(e.currentTarget).parent();
			$main.toggleClass('open');

			/*! SuppressStringValidation No captions here */
			const $otherSubmenus = $main.siblings('.dropdown-submenu:not(.keep-open)');
			$otherSubmenus.removeClass('open');
		});
}

function setUpScreenOrientationEventHandlers() {
	if ('onorientationchange' in window) {
		$(window).on('orientationchange', reshowTooltipsAfterRotation);
	} else {
		const lazyLayout = _.debounce(reshowTooltipsAfterRotation, 300);
		$(window).on('resize', lazyLayout);
	}
}

function reshowTooltipsAfterRotation() {
	const tooltips = [];
	$(':data(bs.tooltip)[aria-describedby]').each((_, element) => {
		const $element = $(element);
		$element.tooltip('hide');
		tooltips.push($element);
	});
	setTimeout(() => {
		tooltips.forEach(($tooltip) => {
			$tooltip.tooltip('show');
		});
	}, 500);
}

function setUpModernizr() {
	/*! SuppressStringValidation (Not a caption) */
	modernizr.addTest('outline-offset', () => modernizr.testProp('outlineOffset'));

	/*! SuppressStringValidation (Not a caption) */
	modernizr.addTest('embed-overlay', () => !('ActiveXObject' in window));
}

function setUpUserChangedHandler() {
	const deferred = new DeferredPromise();
	const debouncedShowUserChangedDialog = _.debounce(
		showUserChangedDialogAsync,
		appConfig.storageChangedTimeout
	);
	const deferredOnUserChangedAsync = async (e) => {
		if (deferred.isPending) {
			await deferred.promise;
		}
		onUserChanged(e, debouncedShowUserChangedDialog);
	};

	const sessionKey = userSession.getSessionStorageKey();
	Storage.off(sessionKey, deferredOnUserChangedAsync).on(sessionKey, deferredOnUserChangedAsync);

	return deferred;
}

function onUserChanged(e, showUserChangedDialog) {
	if (userSession.isLoggedOn()) {
		const newSessionData = userSession.parseSessionData(e.newValue);
		if (
			!newSessionData ||
			newSessionData.userPK !== userSession.sessionData().userPK ||
			userSession.isInvalidated()
		) {
			userSession.invalidate();
			showUserChangedDialog(newSessionData);
		}
	}
}

async function showUserChangedDialogAsync(newSessionData) {
	const title = newSessionData
		? captionService.getString('5bcb1dc5-4f17-471d-be2c-5dcf09464552', 'User Has Changed')
		: captionService.getString('c0725c1d-7d2d-4d6e-9b18-2ae77b823fb5', 'User Logged Out');
	const messagePrefix = newSessionData
		? captionService.getString(
				'a94e80e5-aa54-4eaf-9ac3-6ced655971ba',
				'You have logged in as a different user in another window.'
			)
		: captionService.getString(
				'ff6b376d-d038-4005-af38-3f3d93264e4c',
				'You have logged out in another window.'
			);
	const message = captionService.getString(
		'41545634-4f24-4b8a-83c2-20fd1ca2287e',
		'{0} Please reload this page in order to continue working.',
		messagePrefix
	);
	const reloadCaption = captionService.getString(
		'81a37f8a-6691-4680-917a-518cc08aafde',
		'Reload'
	);
	navigationService.onNavigating(reload);

	if (newSessionData) {
		await alertDialogService.alertAsync(
			message,
			title,
			{ notificationType: notificationType.Information },
			reloadCaption
		);
		reload();
	} else if (await alertDialogService.confirmOkCancelAsync(message, title, reloadCaption, undefined, notificationType.Information)) {
		reload();
	}
}

function setUpPageLifecycle() {
	windowManager.addCloseListener(() => {
		semaphoreProvider.dispose();
	});
}

function handlePageNotFound() {
	uiService.loadUnavailablePageAsync();
}

function loadEntityPrereqsAsync() {
	/*! SuppressStringValidation (Not a caption) */
	const routeName = 'Logon';
	return entityMappingService.loadAsync().then(() => ruleRepository.loadRouteAsync(routeName));
}

function setupGlobals() {
	global.getWindow().glow.waitForIdleAsync = waitForIdleAsync;
}

export default function coreBootstrapper() {
	screenResizer.setupScreenSize();
	restrictOnResize();
	errorHandler.setupGlobalEventHandlers();
	errorHandlerProxy.init(errorHandler);
	navigationService.init({ onNotFound: handlePageNotFound });
	windowManager.init();
	setUpDocumentEventHandlers();
	setUpDropdownEventHandlers();
	setUpScreenOrientationEventHandlers();
	const deferredUserChangedHandler = setUpUserChangedHandler();
	setUpModernizr();
	setUpPageLifecycle();
	addErrorHandlerToAjaxService();
	userSession.loadSession();
	nativeBridge.initialize(window);
	nativeBridge.webAppIsReady();
	setupGlobals();

	const loadResourceStringsPromise = captionService
		.getRepository()
		.loadStringsAsync(languageService.getLanguageCode());
	loadResourceStringsPromise.then(() => deferredUserChangedHandler.resolve());

	return Promise.all([loadResourceStringsPromise, loadEntityPrereqsAsync()]);
}
