import { avoidLoading } from "Dependency2";
import type { EntityPropertyPredicate } from "DependencyExpression";
import type { DependencyGraph, DependencyVertex } from "DependencyGraph";
import { getDependencyGraph } from "EntityDependencyExtensions";
import { getInterfaceName, getPrimaryKey, isDeletedOrDetached } from "EntityExtensions";
import type Rule from "Rule";
import type { Entity } from "breeze-client";
import ko from "knockout";

export default abstract class RuleVertex implements DependencyVertex {
  private readonly id: string;

  constructor(
    readonly entity: Entity,
    readonly rule: Rule,
  ) {
    this.id = getInterfaceName(entity) + getPrimaryKey(entity) + rule.ruleId;
  }

  getId(): string {
    return this.id;
  }

  isValid(): boolean {
    return !isDeletedOrDetached(this.entity);
  }

  abstract reportChangedAsync(graph: DependencyGraph, loadedOnly: boolean, source?: DependencyVertex): Promise<void>;

  isVertexFor(entity: Entity, propertyName: string): boolean {
    return entity === this.entity && propertyName === this.rule.property;
  }

  clearDependencies(): void {
    getDependencyGraph(this.entity.entityAspect.entityManager).addOrReplaceDependencies(this, []);
  }

  wireDependencies(): void {
    getDependencyGraph(this.entity.entityAspect.entityManager).addOrReplaceDependencies(this, this._getDependencies());
  }

  protected shouldCreateDependencyVertex(entity: Entity, propertyName: string): boolean {
    return !this.isVertexFor(entity, propertyName);
  }

  private _getDependencies(): DependencyVertex[] {
    return avoidLoading(() => {
      return ko.ignoreDependencies(() => {
        let cachedShouldCreateVertex: EntityPropertyPredicate | undefined;
        return this.rule.getDependencies(this.entity).flatMap((item) => {
          let shouldCreateVertex: EntityPropertyPredicate | undefined;
          if (!item.isCondition) {
            if (!cachedShouldCreateVertex) {
              cachedShouldCreateVertex = this.shouldCreateDependencyVertex.bind(this);
            }
            shouldCreateVertex = cachedShouldCreateVertex;
          }

          return item.dependency.getDependencyGraphVertexes(this.entity, shouldCreateVertex);
        });
      });
    });
  }
}
