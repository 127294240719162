import createGraph, { type Graph } from "ngraph.graph";

export interface DependencyVertex {
  getId(): string;
  isValid(): boolean;
  reportChangedAsync(graph: DependencyGraph, loadedOnly: boolean, source?: DependencyVertex): Promise<void>;
}

export class DependencyGraph {
  private constructor(
    private readonly graph: Graph,
    private readonly visitedNodesSet?: Set<string>,
  ) {}

  static create(): DependencyGraph {
    return new DependencyGraph(createGraph());
  }

  addOrReplaceDependencies(target: DependencyVertex, sources: DependencyVertex[]): void {
    if (!target) {
      throw new Error("A target must be provided.");
    }

    const targetId = target.getId();
    const { graph } = this;
    if (!graph.hasNode(targetId)) {
      graph.addNode(targetId, target);
    } else {
      graph.forEachLinkedNode(
        targetId,
        (_linkedNode, link) => {
          if (link.toId === targetId) {
            graph.removeLink(link);
          }
        },
        false,
      );
    }

    sources.forEach((s) => {
      const sourceId = s.getId();
      graph.addNode(sourceId, s);
      graph.addLink(sourceId, targetId);
    });
  }

  async notifyDependentsAsync(source: DependencyVertex, loadedOnly = false): Promise<void> {
    if (!source.isValid()) {
      return;
    }

    const sourceId = source.getId();
    let { visitedNodesSet } = this;
    if (visitedNodesSet) {
      ensureNotCircular(sourceId, visitedNodesSet);
    }

    const outboundNodes = getOutboundNodes(this.graph, sourceId);
    if (!outboundNodes.length) {
      return;
    }

    visitedNodesSet = new Set(visitedNodesSet);
    visitedNodesSet.add(sourceId);
    const notifyingGraph = new DependencyGraph(this.graph, visitedNodesSet);

    await Promise.all(outboundNodes.map((n) => n.reportChangedAsync(notifyingGraph, loadedOnly, source)));
  }

  removeNode(node: DependencyVertex): void {
    this.graph.removeNode(node.getId());
  }

  visitDependents(source: DependencyVertex, callback: (outboundNode: DependencyVertex) => void): void {
    if (!source.isValid()) {
      return;
    }

    const { graph } = this;
    return visitDependentsCore(source);

    function visitDependentsCore(node: DependencyVertex, visitedNodes?: Set<string>): void {
      const nodeId = node.getId();
      if (visitedNodes) {
        ensureNotCircular(nodeId, visitedNodes);
      }

      const outboundNodes = getOutboundNodes(graph, nodeId);
      if (!outboundNodes.length) {
        return;
      }

      visitedNodes = new Set(visitedNodes);
      visitedNodes.add(nodeId);
      outboundNodes.forEach((outboundNode) => {
        callback(outboundNode);
        visitDependentsCore(outboundNode, visitedNodes);
      });
    }
  }
}

function ensureNotCircular(currentNodeId: string, visitedNodes: Set<string>): void {
  if (visitedNodes.has(currentNodeId)) {
    const dependents = Array.from(visitedNodes).join(", ");
    throw new Error(
      `We were trying to visit a dependency graph with circular dependencies, the visited nodes were: ${dependents}, ${currentNodeId}`,
    );
  }
}

function getOutboundNodes(graph: Graph, sourceId: string): DependencyVertex[] {
  const result: DependencyVertex[] = [];
  graph.forEachLinkedNode(
    sourceId,
    (node) => {
      if (node.data.isValid()) {
        result.push(node.data);
      }
    },
    true, // enumerate only outbound links
  );

  return result;
}

/** @deprecated use named import instead */
export default DependencyGraph;
