import { getDependencyValue } from "Dependency2";
import dependencyExtractor from "DependencyExtractorVisitor";
import type { DependencyVertex } from "DependencyGraph";
import { getDependencyGraph } from "EntityDependencyExtensions";
import embeddedDependenciesExtractor from "ExtractEmbeddedDependenciesVisitor";
import { getGlowMacroVertex } from "GlowMacroVertex";
import PropertyVertex from "PropertyVertex";
import { State } from "StateConstants";
import type { Entity } from "breeze-client";
import { uniq } from "lodash-es";

export type EntityPropertyPredicate = (entity: Entity, propertyName: string) => boolean;

export default class DependencyExpression {
  constructor(readonly expression: string) {}

  getAllDependencyPaths(): string[] {
    return uniq(
      getDependencyPaths(this.expression).concat(getEmbeddedDependencyPaths(this.expression)).filter(isPropertyPath),
    );
  }

  getDependencyGraphVertexes(entity: Entity, predicate?: EntityPropertyPredicate): DependencyVertex[] {
    const result: DependencyVertex[] = [];
    const { entityManager } = entity.entityAspect;
    getDependencyValue(entity, this.expression, {
      onVisitMacro(path: string, state: State): void {
        if (state === State.NotLoaded) {
          getDependencyPaths(path).forEach((path) => {
            const vertex = getGlowMacroVertex(getDependencyGraph(entityManager), path);
            vertex.loadMacroAsync();
            result.push(vertex);
          });
        }
      },
      onVisitProperty(propertyEntity: Entity, propertyName: string): void {
        if (
          propertyEntity.entityAspect.entityManager === entityManager &&
          (!predicate || predicate(propertyEntity, propertyName))
        ) {
          result.push(new PropertyVertex(propertyEntity, propertyName));
        }
      },
    });

    return result;
  }
}

function getDependencyPaths(expression: string): string[] {
  return dependencyExtractor.visitDependency(expression, { extractPathsOnly: true });
}

function getEmbeddedDependencyPaths(expression: string): string[] {
  return embeddedDependenciesExtractor.extractEmbeddedDependencies(expression, {
    extractPathsOnly: true,
  });
}

function isPropertyPath(dependency: string): boolean {
  return !dependency.startsWith("%");
}
