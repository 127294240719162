import Promise from 'bluebird';
import dependency from 'Dependency2';
import constants from 'Constants';
import { restrictedPlaceholder } from 'StringUtils';

function LookupDisplayModeHelper() {
}

LookupDisplayModeHelper.prototype.getDisplayMode = (rules) => {
	let displayMode = rules.defaultDisplayMode();

	if (displayMode === constants.DropDownDisplayModes.Unspecified) {
		const codePath = rules.codeProperty();
		const descriptionPath = rules.descriptionProperty();
		displayMode = codePath ?
			(descriptionPath ? constants.DropDownDisplayModes.CodeDesc : constants.DropDownDisplayModes.CodeOnly) :
			(descriptionPath ? constants.DropDownDisplayModes.DescOnly : constants.DropDownDisplayModes.Unspecified);
	}

	return displayMode;
};

LookupDisplayModeHelper.prototype.getDisplayValue = (entity, displayMode, codePath, descriptionPath) => {
	return getDisplayValueCore(entity, displayMode, codePath, descriptionPath, getValue, formatDisplayValue);
};

LookupDisplayModeHelper.prototype.getDisplayValueAsync = async (entity, displayMode, codePath, descriptionPath) => {
	return (
		await getDisplayValueCore(entity, displayMode, codePath, descriptionPath, getValueAsync, async (codePromise, descPromise) => {
				const [code, desc] = await Promise.all([codePromise, descPromise]);
				return formatDisplayValue(code, desc);
			})
		);
};

function getDisplayValueCore(entity, displayMode, codePath, descriptionPath, getValue, formatDisplayValue) {
	if (entity) {
		switch (displayMode) {
			case constants.DropDownDisplayModes.DescOnly:
				return getValue(entity, descriptionPath, codePath);

			case constants.DropDownDisplayModes.CodeDesc: {
				const code = getValue(entity, codePath);
				const desc = getValue(entity, descriptionPath);
				return formatDisplayValue(code, desc);
			}

			default:
				return getValue(entity, codePath, descriptionPath);
		}
	}
}

function getValue(entity, path, fallbackPath) {
	if (path) {
		const { state, value } = dependency.getValue(entity, path);
		if (state === constants.States.NotAvailable && !fallbackPath) {
			return restrictedPlaceholder;
		}
		if (!value && fallbackPath) {
			return getValue(entity, fallbackPath, null);
		}

		return value;
	}
	else if (fallbackPath) {
		const { state, value } = dependency.getValue(entity, fallbackPath);
		if (state === constants.States.NotAvailable) {
			return restrictedPlaceholder;
		}
		return value;
	}
}

function getValueAsync(entity, path, fallbackPath) {
	if (path) {
		return dependency.getValueAsync(entity, path).then(({ state, value }) => {
			if (state === constants.States.NotAvailable && !fallbackPath) {
				return restrictedPlaceholder;
			}

			if (!value && fallbackPath) {
				return getValueAsync(entity, fallbackPath, null);
			}

			return value;
		});
	}
	else if (fallbackPath) {
		return dependency.getValueAsync(entity, fallbackPath).then(({ state, value }) => {
			if (state === constants.States.NotAvailable) {
				return restrictedPlaceholder;
			}
			return value;
		});
	}

	return Promise.resolve();
}

function formatDisplayValue(code, desc) {
	if (!code || code === restrictedPlaceholder) {
		code = desc;
	}
	else if (desc && desc !== restrictedPlaceholder) {
		code += ' - ' + desc;
	}

	return code;
}

export default new LookupDisplayModeHelper();
