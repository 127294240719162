import captionService from "CaptionService";

export const filters: Record<string, (params?: unknown[]) => string> = {
  add: () => captionService.getString("06886414-cd66-43b5-aff7-2fe4270335d8", "Add"),
  addGroup: () => captionService.getString("656569ac-da34-4a15-bb2f-5d9f6b78cfb7", "Add Group"),
  and: () => captionService.getString("237e8153-5e3f-4ad7-b9c4-8a3ef94232f7", "And"),
  all: () => captionService.getString("8967fdde-3cdf-4365-89dc-e56cf82de919", "All"),
  categoryBoolean: () => captionService.getString("1355acc7-58ba-48ab-ac4b-b8e3c6770f03", "Flags"),
  categoryCollection: () => captionService.getString("dad16ca2-5046-4037-bf30-8408d2110e22", "Related Records"),
  categoryDate: () => captionService.getString("c820fb9e-4d44-492a-ad75-8c07a92d3559", "Dates"),
  categoryGeo: () => captionService.getString("91b163e9-b65a-4e7b-b98e-c06a1bcebced", "Geo Locations"),
  categoryNumber: () => captionService.getString("1cf7dde4-4350-4942-b0ab-aced0ec9e63c", "Numbers"),
  categoryText: () => captionService.getString("c5064238-389d-4e6e-9a93-edfb932e2b04", "Text Search"),
  categoryOther: () => captionService.getString("c072bd42-4c27-4e7f-b6cb-36433d29c8d3", "Other"),
  contains: () => captionService.getString("cba9d5ae-7bc4-4bda-a9a4-789e944d1d06", "contains"),
  endsWith: () => captionService.getString("2b7e265c-dd2e-4f68-9c47-eedd6e0939d5", "ends with"),
  fieldSelectAriaLabel: () => captionService.getString("246ae714-d203-4bc4-8344-11b309b7d32c", "Field"),
  filter: () => captionService.getString("a9328752-3a3b-494c-a3cd-df9f0519f716", "Filter"),
  filters: () => captionService.getString("0a7512a1-927f-40c7-b837-03a3bf85685e", "Filters"),
  filtersTitle: () => captionService.getString("9504d295-aaac-4c2b-bd26-dee9c12b7df3", "Filters"),
  from: () => captionService.getString("0017a5a1-778d-45cb-b9a4-27f8f6471e36", "From"),
  greaterThan: () => captionService.getString("8e673f3f-4f39-4930-89cd-c5ff36fffa45", "greater than"),
  greaterThanOrEqual: () =>
    captionService.getString("dbe241cb-d688-41b7-80be-00ab000257ee", "greater than or equal to"),
  group: () => captionService.getString("e3ce3f4f-6a14-4e6a-82f6-64624770bdff", "Group"),
  hasDate: () => captionService.getString("7935ace0-5d1c-4ed7-9793-e57e0afafdd3", "has date"),
  hasDuration: () => captionService.getString("d2d5454f-6d02-45bf-be5a-cd774bc6f3cd", "has duration"),
  hasNoDate: () => captionService.getString("eba5e486-38aa-41a6-b9e5-e67acaada51c", "has no date"),
  hasNoDuration: () => captionService.getString("fd54b206-67b6-4e5b-b9df-b29615fc7f4e", "has no duration"),
  header: () => captionService.getString("01f78bd8-b068-4e39-803b-db112d3a9271", "Column"),
  invalidField: () => captionService.getString("1de06145-640f-407a-b0ee-e04892123f0e", "Field does not exist"),
  is: () => captionService.getString("f27c6e85-e97f-4e8c-a801-37bd673767b4", "is"),
  isBetween: () => captionService.getString("0fcc363b-81cd-4a69-a2bd-3bc36d3c3429", "is between"),
  isBetweenOrEqual: () => captionService.getString("db7ee230-567a-49d8-8e43-c07c74e9c3ec", "is between or equal to"),
  isBlank: () => captionService.getString("379297fe-8368-45b8-b39e-4b74b34a722b", "is blank"),
  isInTheDateRange: () => captionService.getString("a8636f4e-a3e7-4a6b-9c10-590dfd629301", "is in the date range"),
  isInTheFuture: () => captionService.getString("826e380c-9642-4735-8b31-a7cc1b03b858", "is in the future"),
  isInThePast: () => captionService.getString("f6b38a40-95d9-4136-9274-134201ef6a6f", "is in the past"),
  isNot: () => captionService.getString("a77394ca-bebd-489c-9b44-4ee6926b7c03", "is not"),
  isNotBetween: () => captionService.getString("7abb6ba3-5182-4990-8a0e-108b1650508f", "is not between"),
  isNotBetweenOrEqual: () =>
    captionService.getString("9e2c5de5-f3ca-4bb6-b03a-0a5d2860d582", "is not between or equal to"),
  isNotBlank: () => captionService.getString("2ff69519-8d75-4371-82f3-760e16a6c854", "is not blank"),
  isNotSpecified: () => captionService.getString("c24d6998-f96f-45e5-a086-9615b0ddba92", "is not specified"),
  isSpecified: () => captionService.getString("c7028dbc-9312-4dd0-b86c-7d936d6d3d75", "is specified"),
  lessThan: () => captionService.getString("8c1af310-6d37-4dd5-868c-87ef2a337d51", "less than"),
  lessThanOrEqual: () => captionService.getString("627a4ff3-b04c-4306-b2d6-1da2e823c18b", "less than or equal to"),
  maxValue: () => captionService.getString("0883ec6b-651f-4dbd-9854-095d0f4f7e1c", "Maximum value"),
  minValue: () => captionService.getString("647f7fac-015d-48a2-9111-d8102ae62fdb", "Minimum value"),
  more: () => captionService.getString("25413d61-bdeb-4481-94b5-1b40ab0f78f2", "more"),
  no: () => captionService.getString("8a7684b9-f3d1-45bf-ae32-94f842422275", "No"),
  notContain: () => captionService.getString("82f6b76f-2494-469e-b658-9b69a474d9cb", "not contain"),
  notEnding: () => captionService.getString("da0801ad-4b40-4132-96d3-9f4bbe06cfe1", "not ending"),
  notStarting: () => captionService.getString("06bd1938-6d7e-48b1-8d4d-b2d8c2e90fb3", "not starting"),
  operationSelectAriaLabel: () => captionService.getString("60c90d5e-b7bc-49cc-aee8-60665fd52cd8", "Operation"),
  or: () => captionService.getString("c3bb9b03-b42d-4f33-a168-b0efda5bdc99", "Or"),
  selectFilter: () => captionService.getString("2ac3b8fb-5e08-433e-92e9-41061cd8d497", "--- Select filter ---"),
  startsWith: () => captionService.getString("dc504aed-c08b-432a-836a-fd95bb990ef0", "starts with"),
  to: () => captionService.getString("24c3293f-59bb-4723-8d17-ed681bd18aed", "To"),
  isEarlierThan: () => captionService.getString("5b666aa6-7104-449c-9d00-ff46bd19e7e4", "is earlier than"),
  isLaterThan: () => captionService.getString("cdd9a9a0-0354-4351-bd89-a89abab34fa7", "is later than"),
  value: () => captionService.getString("d76c11a5-25f3-4f2a-8007-cd8edaa42cdf", "Value"),
  yes: () => captionService.getString("5b68b9b2-e761-40df-be7f-7b5f6d0e966e", "Yes"),
  filterName: () => captionService.getString("8917f644-4514-4553-a4dd-2fc0bb25ad93", "Filter Name"),
  loadSaved: () => captionService.getString("6d058ec0-2901-4810-adbe-644fa4014b61", "Load Saved"),
  delete: () => captionService.getString("35612733-3a0f-4a58-8108-0dba184b40bd", "Delete"),
  stopIfTrue: () => captionService.getString("5f709090-cbfb-4aeb-be30-373cf31b0cf6", "Stop If True"),
  doesNotExist: () => captionService.getString("2c6a44c0-cb55-4e3f-9077-1eba72f9e262", "does not exist"),
  exists: () => captionService.getString("8fc461d5-1cad-4391-bab7-41f4c6582782", "exists"),
  showAlertsOnly: () => captionService.getString("46265720-c428-4a63-81aa-15cecfb17775", "Show Alerts Only"),
  showAll: () => captionService.getString("24486548-1e43-49cf-85ec-54f03642d68d", "Show All"),
  toggleQuickFilter: () =>
    captionService.getString(
      "1a133076-01c0-4d75-80ec-337ef61b3afd",
      "There are errors in the table. Click on the button to toggle filtering of records with errors.",
    ),
  valueSelectAriaLabel: () => captionService.getString("aa61c96d-114d-4633-8264-7c8f3390541e", "Value"),
  value1SelectAriaLabel: () => captionService.getString("26c3ba26-3720-4239-9e47-dd6c7a90482b", "First value"),
  value2SelectAriaLabel: () => captionService.getString("22c0dffe-06e6-4351-90ad-f9b76852f924", "Second value"),
};
