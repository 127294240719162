/// <reference types="user-agent-data-types" />
import type { ResourceInfo } from "CaptionService";
import { FormFactor } from "FormFactors";
import { joinUri } from "UriUtils";
import type { ThemeOptions } from "wtg-material-ui";

export type PortalThemeConfiguration = Readonly<{
  logoLight?: string;
  logoLightType?: string;
  logoDark?: string;
  logoDarkType?: string;
  brandColor?: string;
  brandColorDark?: boolean;
  breadcrumbTitle?: string;
}>;

export type PortalInfo = Readonly<{
  modulePK: string;
  themePK: string;
  title?: ResourceInfo;
  logonFormFlowPK?: string;
  startupFormPK?: string;
  startupFormFlowPK?: string;
  userType: string;
  showGlobalDashboard: boolean;
  hideHeader: boolean;
  hideHomeButton?: boolean;
  playSystemSounds: boolean;
  overriddenSounds?: Record<string, string>;
  materialDesign: boolean;
  supplyVersion: number;
  defaultThemeAppearance?: string;
  frameworkVersion: number;
  moduleConfigurationKey: string;
  themeConfiguration?: PortalThemeConfiguration;
}>;

export type GlowWindow = Window &
  typeof globalThis & {
    glow: GlowConfig;
  };

export type FeatureFlags = {
  existingValidationAlertsPreventSave?: boolean;
  enableGridSupplyDialogs?: boolean;
  logRuleInvocationTimings?: boolean;
  serviceWorker?: boolean;
  gridWideProposedValues?: boolean;
  forceAndroidAppUpgradetoLatest?: boolean;
};

// TODO: Remove this when import the actual policylink type def
export type PolicyLink = {
  caption: string;
  url: string;
};

export interface GlowThemeOptions extends ThemeOptions {
  configurationTemplatePK?: string;
  configurationItemPK?: string;
}

export interface GlowConfig {
  appName: string;
  appLogo: string;
  appLogoDark: string;
  appLogoLight: string;
  versionNumber: string;
  appVersionNumber: string;
  clientExecutableDate: string;
  featureFlags?: FeatureFlags;
  portalInfo: PortalInfo;
  moduleName: string;
  rootPath: string;
  moduleRootPath: string;
  servicePath: string;
  serviceUri: string;
  baseServiceUri: string;
  uatID: string;
  useCompiledRulesets: boolean;
  environment: string;
  languageCode: string;
  isDebugMode: boolean;
  isNoHeader: boolean;
  themeOptions: GlowThemeOptions;
  policyLinks: PolicyLink[];
  isOidcEnabled: boolean;
  sendCspReport: boolean;
}

export class Global {
  private readonly config: GlowConfig;
  /** @deprecated Use FormFactor enum instead */
  readonly formFactors = FormFactor;
  featureFlags: FeatureFlags;
  /*! SuppressStringValidation form factor option */
  formFactorPath = "Desktop";
  formFactor = FormFactor.Desktop;
  isInteractive = false;
  constructor(config: GlowConfig) {
    this.config = config;
    this.featureFlags = { ...config.featureFlags };
  }

  isPWA(): boolean {
    /*! SuppressStringValidation html match media */
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  getAbsoluteUrl(path = ""): string {
    const absolutePathRegex = /^(?:[a-z]+:)?\/\//i;
    return absolutePathRegex.test(path) ? path : new URL(path, this.windowLocation.href).href;
  }

  getFormFactorName(code: string): string | undefined {
    for (const prop in FormFactor) {
      if (FormFactor[prop as keyof typeof FormFactor] === code) {
        return prop;
      }
    }
    return;
  }

  isPortable(): boolean {
    return this.formFactor === FormFactor.Tablet || this.formFactor === FormFactor.Mobile;
  }

  isPreviewMode(): boolean {
    return !!this.uatID;
  }

  getModuleUrl(module?: string): string {
    if (!module) {
      module = this.moduleName;
    }

    const uatPath = this.isPreviewMode() ? `uat/${this.uatID}` : "";
    return this.getAbsoluteUrl(joinUri(this.rootPath, uatPath, module, this.formFactorPath));
  }

  getWindow(): GlowWindow {
    return window as GlowWindow;
  }

  get materialDesign(): boolean {
    return (this.portalInfo.frameworkVersion || 1) >= 2;
  }

  get appName(): string {
    return this.config.appName;
  }

  get appLogo(): string {
    return this.config.appLogo;
  }

  get appLogoDark(): string {
    return this.config.appLogoDark;
  }

  get appLogoLight(): string {
    return this.config.appLogoLight;
  }

  get versionNumber(): string {
    return this.config.versionNumber;
  }

  get appVersionNumber(): string {
    return this.config.appVersionNumber;
  }

  get clientExecutableDate(): string {
    return this.config.clientExecutableDate;
  }

  get portalInfo(): PortalInfo {
    return this.config.portalInfo;
  }

  get moduleName(): string {
    return this.config.moduleName;
  }

  get rootPath(): string {
    return this.config.rootPath;
  }

  get rootPathForModule(): string {
    return this.config.moduleRootPath;
  }

  get servicePath(): string {
    return this.config.servicePath;
  }

  get serviceUri(): string {
    return this.config.serviceUri;
  }

  get baseServiceUri(): string {
    return this.config.baseServiceUri;
  }

  get uatID(): string {
    return this.config.uatID;
  }

  get useCompiledRulesets(): boolean {
    return this.config.useCompiledRulesets;
  }

  get environment(): string {
    return this.config.environment;
  }

  get languageCode(): string {
    return this.config.languageCode;
  }

  get isDebugMode(): boolean {
    return this.config.isDebugMode;
  }

  get isNoHeader(): boolean {
    return this.config.isNoHeader;
  }

  get themeOptions(): GlowThemeOptions {
    return this.config.themeOptions;
  }

  get policyLinks(): PolicyLink[] {
    return this.config.policyLinks || [];
  }

  get formLanguage(): string {
    return "HTML";
  }

  get navigator(): Navigator {
    return window.navigator;
  }

  get userAgent(): string {
    return window.navigator.userAgent;
  }

  get userAgentData(): NavigatorUAData | undefined {
    return window.navigator.userAgentData;
  }

  get windowLocation(): Location {
    return window.location;
  }

  get isOidcEnabled(): boolean {
    return this.config.isOidcEnabled;
  }

  get sendCspReport(): boolean {
    return this.config.sendCspReport;
  }
}

export default new Global((window as GlowWindow).glow || {});
