import type { Entity, EntityType } from "BreezeExtensions";
import { getTableCode } from "EntityExtensions";
import systemAuditPropertiesHelper from "SystemAuditPropertiesHelper";
import validationEngine from "ValidationEngine";

export async function shallowCopyExistingAsync(
  entity: Entity,
  targetEntity: Entity,
  excludedPropertyNames?: string[],
): Promise<void> {
  const entityType = targetEntity.entityType;
  const suspension = validationEngine.suspendValidation([targetEntity]);
  try {
    await copyPropertiesAsync(entityType, entity, targetEntity, excludedPropertyNames);
  } finally {
    suspension.dispose();
  }
}

async function copyPropertiesAsync(
  entityType: EntityType,
  entity: Entity,
  targetEntity: Entity,
  excludedPropertyNames?: string[],
): Promise<void[]> {
  const tableCode = getTableCode(entity);
  const sourceDataProperties = entityType.dataProperties;

  return await Promise.all(
    sourceDataProperties
      .filter(
        (property) =>
          !property.isPartOfKey &&
          !systemAuditPropertiesHelper.isSystemAuditProperty(property.name, tableCode) &&
          !(excludedPropertyNames && excludedPropertyNames.includes(property.name)),
      )
      .map((property) => targetEntity.entityAspect.setValueAsync(property.name, entity.getProperty(property.name))),
  );
}
