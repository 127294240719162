import { isNullOrEmpty } from "StringUtils";
import ko, { type PureComputed } from "knockout";

export default class RuleCommandResult {
  errorMessage?: string | null;
  isSuccess: PureComputed<boolean>;

  constructor(errorMessage?: string | null) {
    this.errorMessage = errorMessage;
    this.isSuccess = ko.pureComputed(() => {
      return isNullOrEmpty(this.errorMessage);
    });
  }

  static Success = new RuleCommandResult();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static Error(errorMessage: string): RuleCommandResult {
    return new RuleCommandResult(errorMessage);
  }
}
