import captionService from "CaptionService";

export const map = {
  resultsFound: (params: number[]): string =>
    captionService.getString("8619271b-7c9e-47a2-a1a1-067817d7b54a", "{0} results found at this location", ...params),
  routeByRoadNotDisplayed: (): string =>
    captionService.getString(
      "0456b4c6-32f3-4dd7-a5af-86e7009e86b3",
      "The route by road is not displayed because sections of the route are not possible via road.",
    ),
};
