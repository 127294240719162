import alertDialogService from 'AlertDialogService';
import captionService from 'CaptionService';
import changePasswordService, {
	ChangePasswordAlertNotification,
	ChangePasswordServiceConstants,
	ChangePasswordServiceError,
	ChangePasswordValidationNotification,
} from 'ChangePasswordService';
import dialogService from 'DialogService';
import materialDesignDialogService from 'MaterialDesignDialogService';
import { loadTemplateAsync } from 'ModuleLoader';
import NotificationSummary from 'NotificationSummary';
import { NotificationType } from 'NotificationType';
import Notifications from 'Notifications';
import RuleValidationResult from 'RuleValidationResult';
import { isNullOrEmpty } from 'StringUtils';
import ko from 'knockout';

function ChangePasswordDialogService() {}

function getErrorDialogTitle() {
	return captionService.getString('857a7c67-e457-4548-8236-f01a4ace33fC', 'Error');
}

ChangePasswordDialogService.prototype.changePassword = () => {
	changePasswordCoreAsync();
};

ChangePasswordDialogService.prototype.requirePasswordChange = (oldPassword) => {
	changePasswordCoreAsync(oldPassword);
};

async function changePasswordCoreAsync(oldPassword) {
	if (materialDesignDialogService.canShowChangePasswordDialog()) {
		materialDesignDialogService.showChangePasswordDialogAsync(oldPassword);
		return;
	}

	const viewModel = makeViewModel(oldPassword);

	const buttonOptions = [
		{
			caption: captionService.getString('702f6368-186c-4cef-b3d3-9155cb52cf8c', 'Cancel'),
			isDismiss: true,
		},
		{
			bindingString: 'click: savePasswordChange',
			caption: captionService.getString('d8b23c78-8efc-4bea-9747-7418247b7f7d', 'Change'),
			isPrimary: true,
			isDefault: true,
		},
	];

	const bodyDfd = loadTemplateAsync('ChangePassword.html');
	viewModel.dialog = await dialogService.showDialogAsync({
		title: viewModel.humanReadableName().caption,
		bodyAllowHtml: true,
		includeValidationSummary: true,
		bodyDeferred: bodyDfd,
		buttonOptions,
		viewModel,
	});
}

function makeViewModel(password) {
	let humanReadableName;
	/*! SuppressStringValidation user type */
	if (!isNullOrEmpty(password)) {
		humanReadableName = ko.pureComputed(getRequirePasswordChangeCaption);
	} else {
		humanReadableName = ko.pureComputed(getViewModelCaption);
	}

	const object = new ChangePasswordViewModel(password, humanReadableName);
	return object;
}

function ChangePasswordViewModel(password, humanReadableName) {
	this.dialog = undefined;
	this.oldPassword = ko.observable(password);
	this.newPassword = ko.observable('');
	this.newPasswordConfirmation = ko.observable('');

	this.requireOldPasswordEntry = ko.observable(isNullOrEmpty(password));
	this.savePasswordChange = savePasswordChangeAsync.bind(null, this);
	this.notificationSummary = new NotificationSummary(this);
	this.notifications = new Notifications();

	this.humanReadableName = humanReadableName;
}

function getViewModelCaption() {
	return {
		caption: captionService.getString(
			'fbbd22cf-8242-4d82-bff4-4382a47271d0',
			'Change Password',
		),
		isGeneric: true,
	};
}

function getRequirePasswordChangeCaption() {
	return {
		caption: captionService.getString(
			'a3d5bd12-0421-4ef6-a597-670a0fcaf461',
			'Password Change Required',
		),
		isGeneric: true,
	};
}

async function savePasswordChangeAsync(viewModel) {
	viewModel.notifications.removeAll();

	if (ko.unwrap(viewModel.newPassword) !== ko.unwrap(viewModel.newPasswordConfirmation)) {
		const message = captionService.getString(
			'35b6ced6-e436-4143-9830-c15634af931d',
			'Passwords are not identical',
		);

		const newValidationResult = new RuleValidationResult(message, NotificationType.Error);
		newValidationResult.propertyName = 'newPassword';
		newValidationResult.caption = captionService.getString(
			'3c14d1f2-ffd8-42d8-a771-d2550315642a',
			'New Password',
		);
		newValidationResult.ruleId = 'ee2a14d1-d0c8-4e3c-a29c-dceceeb8f4d2';
		viewModel.notifications.push(newValidationResult);

		const confirmValidationResult = new RuleValidationResult(message, NotificationType.Error);
		confirmValidationResult.propertyName = 'newPasswordConfirmation';
		confirmValidationResult.caption = captionService.getString(
			'3ba4b3e0-7c15-490e-823e-4d4ca741fc86',
			'Confirm Password',
		);
		confirmValidationResult.ruleId = '82946fab-853c-4bb4-b8db-c784816a7159';
		viewModel.notifications.push(confirmValidationResult);

		return false;
	}

	try {
		await changePasswordService.changePasswordAsync(
			ko.unwrap(viewModel.oldPassword),
			ko.unwrap(viewModel.newPassword),
		);

		if (viewModel.dialog) {
			dialogService.hide(viewModel.dialog);
		}

		await alertDialogService.alertAsync(
			captionService.getString(
				'c5654f28-40a3-476a-b9c4-59a51edb3aa3',
				'Use your new password the next time you log in',
			),
			captionService.getString(
				'024ba0b7-cd54-4631-86fe-736d0d990f70',
				'Password changed successfully',
			),
			{ notificationType: NotificationType.Success },
		);
	} catch (error) {
		if (!(error instanceof ChangePasswordServiceError)) {
			throw error;
		}

		error.notifications.forEach((notification) => {
			if (notification instanceof ChangePasswordAlertNotification) {
				alertDialogService.errorWithOKButtonAsync(
					notification.description,
					getErrorDialogTitle(),
				);
			} else if (notification instanceof ChangePasswordValidationNotification) {
				const validationResult = new RuleValidationResult(
					notification.description,
					NotificationType.Error,
				);
				const field = notification.field;

				if (field === ChangePasswordServiceConstants.ChangePasswordFields.OldPassword) {
					validationResult.propertyName = field;
					validationResult.caption = captionService.getString(
						'58bc4ffb-c9c5-4df5-bf00-06db04402719',
						'Old Password',
					);
				} else if (
					field === ChangePasswordServiceConstants.ChangePasswordFields.NewPassword
				) {
					validationResult.propertyName = field;
					validationResult.caption = captionService.getString(
						'9014f46f-52c7-4496-ae3b-a34ce3ada266',
						'New Password',
					);
				}

				viewModel.notifications.push(validationResult);
			}
		});
	}
}

export default new ChangePasswordDialogService();
