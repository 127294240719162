import alertDialogService from "AlertDialogService";
import captionService from "CaptionService";
import { RetryableActivityInvocationError, UnknownModuleError } from "Errors";
import { type FormFlowSession } from "FormFlowSession";
import { type FormFlowActivity } from "FormFlowTypes";
import { loadActivityInvokerAsync } from "ModuleLoader";

export type ActivityResult = {
  remainOpen?: boolean;
  refresh?: boolean;
};

export type ActivityInvoker<T extends FormFlowActivity> = (
  session: FormFlowSession,
  activity: T,
) => Promise<ActivityResult | undefined>;

export async function getActivityInvokerAsync<T extends FormFlowActivity>(
  kind: string,
): Promise<ActivityInvoker<T> | undefined> {
  try {
    const activityInvoker = (await loadActivityInvokerAsync(kind)) as ActivityInvoker<T>;
    return (session: FormFlowSession, activity: T): Promise<ActivityResult | undefined> =>
      invokeActivityAsync(activityInvoker, session, activity);
  } catch (error) {
    if (error instanceof UnknownModuleError) {
      return undefined;
    }
    throw error;
  }
}

async function invokeActivityAsync<T extends FormFlowActivity>(
  activityInvoker: ActivityInvoker<T>,
  session: FormFlowSession,
  activity: T,
): Promise<ActivityResult | undefined> {
  try {
    return await activityInvoker(session, activity);
  } catch (err) {
    if (err instanceof RetryableActivityInvocationError) {
      const error: RetryableActivityInvocationError = err;
      const message = captionService.getString(
        "794e88f7-2958-4420-86ab-be4ad939bbe3",
        "Your Internet connection appears to be offline. Would you like to retry?",
      );
      const title = captionService.getString("e5c14f08-9083-46cd-a7be-db8c47c31f1f", "Connection Lost");
      const shouldRetry = await alertDialogService.confirmOkCancelAsync(
        message,
        title,
        captionService.getString("36e02918-2000-4e06-8655-bed262bcef7a", "Retry"),
      );

      if (shouldRetry) {
        return await invokeActivityAsync(activityInvoker, session, activity);
      } else {
        throw error.cause;
      }
    }
    throw err;
  }
}
