import { type DependencyGraph, type DependencyVertex } from "DependencyGraph";
import { getDependencyGraph } from "EntityDependencyExtensions";
import { getInterfaceName } from "EntityExtensions";
import { trackEntityManagerOperationAsync } from "EntityManagerExtensions";
import { getMeasureProperties } from "EntityMetadataExtensions";
import { setPropertyValueAsync } from "EntityPropertyExtensions";
import { RuleInvocationException } from "Errors";
import { getMinMaxValueByType } from "NumericService";
import { isPropertyVertex } from "PropertyVertex";
import ProposedValue from "ProposedValue";
import type Rule from "Rule";
import RuleService from "RuleService";
import RuleVertex from "RuleVertex";
import type { RuleProcessResult } from "SubRule";
import unitStrategy from "UnitStrategy";
import type { DataProperty, Entity } from "breeze-client";
import { noop } from "lodash-es";

export interface ProposedValuesStorage {
  dispose(): void;
  startListeningAsync(): Promise<void>;
  stopListening(): void;
  waitForPendingChangesAsync(): Promise<void>;
}

interface Runner {
  runningCount: number;
  sequence: number;
  statePromise: Promise<unknown>;
  states: State[];
}

interface State {
  error?: unknown;
  result?: RuleProcessResult;
}

const proposedValueEngine = {
  async applyDefaultValuesAsync(entity: Entity): Promise<void> {
    const rules = getProposedValueRules(entity);
    if (!Object.keys(rules).length) {
      return;
    }

    const rulesToApply = getDefaultValueRules(rules);
    const applyRulesPromise = applyRulesAsync(rulesToApply, entity);

    await addOperationPromiseAsync(entity, applyRulesPromise);
  },

  createStorage(entity: Entity): ProposedValuesStorage {
    const hasRules = this.hasProposedValueRules(getInterfaceName(entity));
    return hasRules ? new ProposedValuesStorageImpl(entity) : this.createEmptyStorage();
  },

  createEmptyStorage(): ProposedValuesStorage {
    return emptyStorage;
  },

  hasProposedValueRules(entityName: string): boolean {
    const rules = getProposedValueRulesByEntityName(entityName);
    return !!Object.keys(rules).length;
  },
};

const emptyStorage = {
  startListeningAsync: async (): Promise<void> => await Promise.resolve(),
  stopListening: noop,
  dispose: noop,
  waitForPendingChangesAsync: async (): Promise<void> => await Promise.resolve(),
};

class ProposedValuesStorageImpl {
  private disposable?: () => void;
  private refCount: number;
  private readonly runningPromises: Map<string, Runner>;
  private starter?: Promise<void>;

  constructor(private readonly entity: Entity) {
    this.disposable = undefined;
    this.refCount = 0;
    this.runningPromises = new Map();
    this.starter = undefined;
  }

  async startListeningAsync(): Promise<void> {
    if (this.refCount++ !== 0) {
      return;
    }

    if (this.starter) {
      await this.starter;
    }

    const { entity } = this;
    const rules = getProposedValueRules(entity);
    this.starter = (async (): Promise<void> => {
      try {
        const disposable = await subscribeToChangesAsync(entity, rules, this.runningPromises);
        if (this.refCount) {
          this.disposable = disposable;
        } else {
          disposable?.();
        }
      } finally {
        this.starter = undefined;
      }
    })();

    await this.starter;
  }

  stopListening(): void {
    if (--this.refCount === 0) {
      this.dispose();
    }
  }

  async waitForPendingChangesAsync(): Promise<void> {
    const promises = Array.from(this.runningPromises.values())
      .filter((runner) => runner.runningCount > 0)
      .map((runner) => runner.statePromise);

    if (promises.length) {
      await Promise.all(promises);
      await this.waitForPendingChangesAsync();
    }
  }

  dispose(): void {
    this.disposable?.();
    this.disposable = undefined;
  }
}

function evaluateAsync(entity: Entity, rule: Rule, runningPromises: Map<string, Runner>): Promise<void> {
  return evaluateCoreAsync();

  async function evaluateCoreAsync(): Promise<void> {
    let runner = runningPromises.get(rule.property);
    if (!runner) {
      runner = { runningCount: 0, sequence: -1, statePromise: Promise.resolve(), states: [] };
      runningPromises.set(rule.property, runner);
    }

    runner.runningCount++;
    const sequence = ++runner.sequence;
    const statePromise = (async (): Promise<State> => {
      try {
        await runner.statePromise;
        return await addStateAsync(runner.states);
      } finally {
        runner.runningCount--;
      }
    })();
    runner.statePromise = statePromise;
    addOperationPromiseAsync(entity, statePromise);

    const state = await statePromise;
    if (runner.sequence === sequence) {
      const { states } = runner;
      runner.states = [];
      try {
        await processStatesAsync(states);
        if (runner.sequence === sequence) {
          runningPromises.delete(rule.property);
        }
      } catch (error) {
        if (!state.error) {
          state.error = error;
        }
      }
    }

    if (state.error) {
      throw state.error;
    }
  }

  async function addStateAsync(states: State[]): Promise<State> {
    let state: State;
    try {
      const result = await rule.processAsync(entity);
      state = { result };
    } catch (error) {
      state = { error };
    }
    states.push(state);
    return state;
  }

  async function processStatesAsync(states: State[]): Promise<void> {
    for (let i = states.length - 1; i >= 0; i--) {
      const { result } = states[i];
      if (result?.isSuccess && isValidResult(entity, rule.property, result.value)) {
        return await setProposedValueAsync(entity, rule, result.value);
      }
    }
  }
}

function getDefaultValueRules(rules: Record<string, Rule[]>): Rule[] {
  const result: Rule[] = [];
  for (const propertyName in rules) {
    const rulesForProperty = rules[propertyName];
    for (let i = 0; i < rulesForProperty.length; i++) {
      const rule = rulesForProperty[i];
      if (!rule.hasPathOrSelfDependenciesExcludingConditions()) {
        result.push(rule);
      }
    }
  }

  return result;
}

async function applyRulesAsync(rulesToApply: Rule[], entity: Entity): Promise<void> {
  for (const rule of rulesToApply) {
    try {
      const result = await rule.processAsync(entity);
      if (result.isSuccess && isValidResult(entity, rule.property, result.value)) {
        await setProposedValueAsync(entity, rule, result.value);
      }
    } catch (error) {
      throw new RuleInvocationException(getInterfaceName(entity), rule.ruleId, rule.property, error);
    }
  }
}

function getProposedValueRules(entity: Entity): Record<string, Rule[]> {
  return getProposedValueRulesByEntityName(getInterfaceName(entity));
}

function getProposedValueRulesByEntityName(entityName: string): Record<string, Rule[]> {
  return RuleService.get(entityName).proposedValueRules();
}

async function addOperationPromiseAsync(entity: Entity, promise: Promise<unknown>): Promise<void> {
  await trackEntityManagerOperationAsync(entity.entityAspect.entityManager, promise);
}

function isValidResult(entity: Entity, property: string, result: unknown): result is ProposedValue {
  if (!(result instanceof ProposedValue) || result.IsNoResult) {
    return false;
  }

  const dataProperty = entity.entityType.getDataProperty(property) as DataProperty | undefined;
  if (result.Value == null) {
    if (dataProperty && (!dataProperty.isNullable || dataProperty.dataType.getName() === "String")) {
      return false;
    }
  } else {
    if (dataProperty?.dataType.parse) {
      const newValue = dataProperty.dataType.parse?.(result.Value, typeof result.Value);
      if (newValue == null) {
        return false;
      }
    }

    const rules = RuleService.get(getInterfaceName(entity));
    const size = rules.numericSize(property);
    let allowNegatives = true;

    const measureProperty = getMeasureProperties(entity.entityType)?.find((x) => x.magnitudeProperty === property);
    if (measureProperty) {
      const strategy = unitStrategy.get(measureProperty.unitType);
      if (strategy) {
        allowNegatives = strategy.allowNegatives;
      }
    }

    const dataTypeName = dataProperty?.dataType.getName();
    const minMax = dataTypeName
      ? getMinMaxValueByType(dataTypeName, allowNegatives, size?.precision, size?.scale)
      : undefined;

    if (minMax && typeof result.Value === "number" && (result.Value < minMax.min || result.Value > minMax.max)) {
      return false;
    }
  }

  return true;
}

async function setProposedValueAsync(entity: Entity, rule: Rule, result: ProposedValue): Promise<void> {
  await setPropertyValueAsync(entity, rule.property, result.Value);
}

async function subscribeToChangesAsync(
  entity: Entity,
  rules: Record<string, Rule[]>,
  runningPromises: Map<string, Runner>,
): Promise<() => void> {
  const applicableRules = Object.values(rules).flatMap((rulesForProperty) =>
    rulesForProperty.filter((rule) => rule.hasPathDependenciesOtherThanRuleProperty()),
  );

  if (!applicableRules.length) {
    return noop;
  }

  const dependencyGraph = getDependencyGraph(entity.entityAspect.entityManager);
  await Promise.all(applicableRules.map((r) => r.getDependenciesAsync(entity)));
  const vertices = applicableRules.map((rule) => {
    const vertex = new ProposedValueVertex(entity, rule, runningPromises);
    vertex.wireDependencies();
    return vertex;
  });

  return () => {
    vertices.forEach((v) => dependencyGraph.removeNode(v));
  };
}

class ProposedValueVertex extends RuleVertex {
  constructor(
    entity: Entity,
    rule: Rule,
    private readonly runningPromises: Map<string, Runner>,
  ) {
    super(entity, rule);
  }

  async reportChangedAsync(_graph: DependencyGraph, loadedOnly: boolean, source?: DependencyVertex): Promise<void> {
    this.wireDependencies();
    if (!loadedOnly && (!source || !isPropertyVertex(source, this.entity, this.rule.property))) {
      await evaluateAsync(this.entity, this.rule, this.runningPromises);
    }
  }
}

export default proposedValueEngine;
