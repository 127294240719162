/*! StartNoStringValidationRegion Does not contain captions */

/*
This hack is to add select for root NavigationProperty to generated uri with Odata4.
We have created an Issue on github for this, maybe this hack should be removed once the Issue is resolved.
Issue: https://github.com/tschettler/breeze-odata4/issues/84
Original OData4UriBuilder: https://github.com/tschettler/breeze-odata4/blob/master/src/breeze-odata4-uriBuilder.ts
*/
import { config, type EntityQuery, type MetadataStore } from "breeze-client";
import { OData4UriBuilder } from "breeze-odata4";
import type { QueryOptions } from "breeze-odata4/dist/types/breeze-odata4-uriBuilder";

interface OData4UriBuilderInternals {
  buildQueryOptions(queryOptions: QueryOptions, entityQuery: EntityQuery, metadataStore: MetadataStore): QueryOptions;
  getResource(entityQuery: EntityQuery): string;
  toQueryOptionsString(queryOptions: QueryOptions): string;
}

export default class CustomOData4UriBuilder extends OData4UriBuilder {
  constructor() {
    super();
    this.name = "CustomOData4";
  }

  override buildUri(entityQuery: EntityQuery, metadataStore: MetadataStore): string {
    const internals = this as unknown as OData4UriBuilderInternals;
    const queryOptions = internals.buildQueryOptions({ expand: [], select: [] }, entityQuery, metadataStore);
    appendSelectForRootNavigationProperty(queryOptions, entityQuery);
    const qoText = internals.toQueryOptionsString(queryOptions);
    entityQuery.resourceName = internals.getResource(entityQuery);
    return entityQuery.resourceName + qoText;
  }
}

function appendSelectForRootNavigationProperty(queryOptions: QueryOptions, entityQuery: EntityQuery): void {
  const { select } = queryOptions;
  if (select && entityQuery.selectClause) {
    entityQuery.selectClause.propertyPaths.forEach((path) => {
      const rootProperty = path.split(".")[0];
      if (!select.includes(rootProperty)) {
        select.push(rootProperty);
      }
    });
  }
}

config.registerAdapter("uriBuilder", CustomOData4UriBuilder);

/*! EndNoStringValidationRegion */
