import { invokeFormFlowAsync } from "@wtg-glow/form-flow-engine";
import type { ActivityResult } from "ActivityInvokerProvider";
import captionService from "CaptionService";
import { FormFlowAbortError, RetryableActivityInvocationError, isNetworkError } from "Errors";
import { getFormFlowDefinitionAsync } from "FormFlowDefinitionProvider";
import { FormFlowError, FormFlowErrorType, withFormFlowErrorStackAsync } from "FormFlowError";
import type { FormFlowSession } from "FormFlowSession";
import type { FormFlowInstanceOptions } from "FormFlowSessionFactory";
import type { TaskShellOptions } from "TaskPresenter";
import { isVariableStrategy, makeStrategy } from "VariableStrategyFactory";

export type SubFormFlowOptions = {
  CreateNewSession?: boolean;
  OutArgumentNames?: string[];
  ShowInDialog?: boolean;
  ShowInDrawer?: boolean;
  ShowMaximizedDialog?: boolean;
  DisableSaveCurrentSession?: boolean;
  NextActivityId?: string;
};

export async function invokeSubFormFlowAsync(
  session: FormFlowSession,
  formFlowId: string,
  inputArguments?: unknown[],
  options?: SubFormFlowOptions,
): Promise<ActivityResult | undefined> {
  const shellOptions = { transitionResolveHandler: () => onTransitionResolved(session) } as TaskShellOptions;
  if (options?.CreateNewSession) {
    if (options?.ShowInDialog) {
      if (!options.DisableSaveCurrentSession) {
        let isSaved = session.uiContext.hasFormEntity() ? await session.uiContext.ensureSavedAsync() : true;
        if (isSaved && session.drawerSession) {
          isSaved = session.drawerSession.uiContext.hasFormEntity()
            ? await session.drawerSession.uiContext.ensureSavedAsync()
            : true;
        }
        if (!isSaved) {
          return { remainOpen: true, refresh: false };
        }
      }

      shellOptions.isDialog = true;
      shellOptions.dialogOptions = {
        showHeaderMenu: true,
        maximized: options.ShowMaximizedDialog,
        isDrawer: options.ShowInDrawer ?? false,
      };

      const instanceOptions: FormFlowInstanceOptions = {
        uiContextOrOptions: shellOptions,
        shouldSaveOnComplete: true,
      };

      try {
        await invokeCoreAsync(session, instanceOptions, formFlowId, inputArguments, options?.OutArgumentNames);
      } catch (error) {
        if (!(error instanceof FormFlowAbortError)) {
          throw error;
        }
      }

      const definition = await getFormFlowDefinitionAsync(formFlowId);
      return { remainOpen: true, refresh: !definition.DisableRefreshOnClose };
    } else {
      throw new FormFlowError(
        captionService.getString(
          "73c89dd3-d42d-4dad-b2b1-4e0dc54b25cd",
          "Cannot run a new session in the current page.",
        ),
        {
          type: FormFlowErrorType.NonReportableRuntimeError,
          friendlyCaption: captionService.getString(
            "ac6592be-33f8-4732-a0f8-1e93a51fd2cb",
            "Activity configuration issue",
          ),
        },
      );
    }
  } else {
    if (options?.ShowInDialog) {
      shellOptions.isDialog = true;
      shellOptions.disableSaveCancel = true;
      shellOptions.dialogOptions = {
        maximized: options.ShowMaximizedDialog,
        isDrawer: options.ShowInDrawer ?? false,
      };

      const instanceOptions: FormFlowInstanceOptions = {
        ...session.cloneFormFlowOptions,
        uiContextOrOptions: shellOptions,
      };

      await invokeCoreAsync(session, instanceOptions, formFlowId, inputArguments, options?.OutArgumentNames);
      return { remainOpen: true, refresh: false };
    } else {
      const instanceOptions = session.cloneFormFlowOptions;
      await invokeCoreAsync(session, instanceOptions, formFlowId, inputArguments, options?.OutArgumentNames);
      return options?.NextActivityId ? await session.invokeActivityAsync(options.NextActivityId) : undefined;
    }
  }
}

async function invokeCoreAsync(
  session: FormFlowSession,
  instanceOptions: FormFlowInstanceOptions,
  formFlowId: string,
  inputArguments: unknown[] | undefined,
  outArgumentNames: string[] | undefined,
): Promise<void> {
  await invokeFormFlowAsyncWithRetryAsync(() => {
    const stackFrame = `in sub form-flow ${formFlowId}`;
    return withFormFlowErrorStackAsync(async () => {
      const outputParameters = await invokeFormFlowAsync(formFlowId, inputArguments, instanceOptions);
      if (outputParameters && outArgumentNames) {
        const outValues = Object.values(outputParameters);
        outArgumentNames.forEach((outArgumentName, index) => {
          if (outArgumentName && index < outValues.length) {
            const outValue = outValues[index];
            session.setVariableStrategy(
              outArgumentName,
              isVariableStrategy(outValue) ? outValue : makeStrategy(outValue),
            );
          }
        });
      }
    }, stackFrame);
  });
}

async function invokeFormFlowAsyncWithRetryAsync<T>(callback: () => Promise<T>): Promise<T> {
  try {
    return await callback();
  } catch (error) {
    if (error instanceof Error && isNetworkError(error)) {
      throw new RetryableActivityInvocationError(error);
    }
    throw error;
  }
}

function onTransitionResolved(session: FormFlowSession): void {
  session.uiContext.resolveTransition();
}
