import { PromiseCacheItem } from "PromiseCacheItem";

export function getCachedPromise<T>(promiseFactory: () => Promise<T>, context?: unknown): () => Promise<T> {
  return getCachedPromiseCore(promiseFactory, context);
}

function getCachedPromiseCore<T>(promiseFactory?: () => Promise<T>, context?: unknown): () => Promise<T> {
  const item = new PromiseCacheItem<T>();
  return () => {
    let promise = item.accessCache();
    if (promise) {
      return promise;
    }

    if (!promiseFactory) {
      // only possible if there is some bug in the code
      throw new Error("Cache is in an invalid state and cannot be reused.");
    }

    const innerPromise = promiseFactory.call(context);
    // eslint-disable-next-line rulesdir/prefer-async-await
    promise = getCacheItemPromiseAsync<T>(item, innerPromise).then((value) => {
      promiseFactory = undefined;
      context = undefined;
      return value;
    });

    item.cachePromise(promise);
    return promise;
  };
}

async function getCacheItemPromiseAsync<T>(item: PromiseCacheItem<T>, promise: Promise<T>): Promise<T> {
  try {
    const value = await promise;
    item.setResolved(value);
    return value;
  } finally {
    item.clearCachedPromise();
  }
}
