import { getInterfaceName } from "EntityExtensions";
import { type Entity } from "breeze-client";

export async function deleteEntityAsync(entity: Entity): Promise<void> {
  // TODO: Extract deletion code from BreezeInit
  if ("deleteAsync" in entity.entityAspect && typeof entity.entityAspect.deleteAsync === "function") {
    await entity.entityAspect.deleteAsync();
  } else {
    throw new Error(`Entity of type '${getInterfaceName(entity)}' does not support deletion`);
  }
}
