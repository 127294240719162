import type { Caption } from "CaptionService";
import * as dependency from "Dependency2";
import { State as StateConstants } from "StateConstants";
import { useBindingContext } from "VueHooks/BindingContextHook";
import type { Entity } from "breeze-client";
import jsep, {
  type ArrayExpression,
  type BinaryExpression,
  type CallExpression,
  type Compound,
  type ConditionalExpression,
  type Expression,
  type Identifier,
  type Literal,
  type LogicalExpression,
  type MemberExpression,
  type ThisExpression,
  type UnaryExpression,
} from "jsep";
import ko, { type Computed } from "knockout";
import { onUnmounted, ref, watch, unref, type Ref, type WatchStopHandle } from "vue";

type ExpressionInterfaces =
  | Expression
  | Compound
  | Identifier
  | MemberExpression
  | Literal
  | ThisExpression
  | CallExpression
  | UnaryExpression
  | BinaryExpression
  | LogicalExpression
  | ConditionalExpression
  | ArrayExpression;

type CalcResult = string | number | boolean | Entity | Date | object;

export function useCalculatedCaptionProperty<T = CalcResult>(
  caption: Caption,
  captionType: string | undefined,
): Ref<T | null | undefined> {
  let value: undefined | string = caption.caption;
  switch (captionType) {
    case "long":
      if (caption.long) {
        value = caption.long;
      }
      break;
    case "medium":
      if (caption.medium) {
        value = caption.medium;
      }
      break;
    case "short":
      if (caption.short) {
        value = caption.short;
      }
      break;
    case "none":
      value = undefined;
      break;
  }
  return useCalculatedProperty<T>(value);
}

export function useCalculatedProperty<T = CalcResult>(value: CalcResult | null | undefined): Ref<T | null | undefined> {
  const [result] = useCalculatedPropertyWithState(value);
  return result as Ref<T | null | undefined>;
}

export function useCalculatedPropertyWithState<T = CalcResult>(
  value: CalcResult | null | undefined,
): [Ref<T | null | undefined>, Ref<StateConstants>] {
  const bindingContext = useBindingContext();
  const [result, state, dispose] = getCalculatedPropertyValue(value, bindingContext);
  onUnmounted(() => dispose());
  return [result, state] as [Ref<T | null | undefined>, Ref<StateConstants>];
}

export function getCalculatedPropertyValue<T = CalcResult>(
  value: CalcResult | null | undefined,
  bindingContext?: Ref<Entity> | undefined,
): [Ref<T | undefined | null>, Ref<StateConstants>, () => void] {
  const result: Ref<T | null | undefined> = ref(null);
  const state: Ref<StateConstants> = ref(StateConstants.NotLoaded);

  let computed: Computed, unwatch: WatchStopHandle;

  if (value && typeof value === "string" && /^calc\(/.test(value)) {
    unwatch = watch(
      () => unref(bindingContext),
      (bindingContext) => {
        computed?.dispose();
        const expr = jsep(value.substring(4));
        computed = ko.computed(() => {
          try {
            result.value = evaluateExpression(expr, bindingContext) as T;
            state.value = StateConstants.Available;
          } catch (error: unknown) {
            if (error instanceof ValueNotAvailableError) {
              result.value = null;
              state.value = error.state;
            } else {
              (error as Error).message += `\nExpression being evaluated: "${value}"`;
              throw error;
            }
          }
        });
      },
      { immediate: true },
    );
  } else {
    state.value = StateConstants.Available;
    result.value = value as T;
  }

  return [
    result,
    state,
    (): void => {
      unwatch?.();
      computed?.dispose();
    },
  ];
}

function evaluateExpression(expr: ExpressionInterfaces, bindingContext: Entity | undefined): CalcResult {
  switch (expr?.type) {
    case "Literal":
      return (expr as Literal).value;
    case "Identifier":
      return evaluateIdentifier(expr as Identifier, bindingContext);
    case "UnaryExpression":
      return evaluateUnaryExpression(expr as UnaryExpression, bindingContext);
    case "BinaryExpression":
      return evaluateBinaryExpression(expr as BinaryExpression, bindingContext);
    case "LogicalExpression":
      return evaluateLogicalExpression(expr as LogicalExpression, bindingContext);
    case "ConditionalExpression":
      return evaluateConditionalExpression(expr as ConditionalExpression, bindingContext);
    case "MemberExpression":
      return evaluateMemberExpression(expr as MemberExpression, bindingContext);
  }
  throw unsupportedOperation(expr.type);
}

function evaluateIdentifier(expr: Identifier, bindingContext: Entity | undefined): CalcResult {
  switch (expr.name) {
    case "true":
      return true;
    case "false":
      return false;
  }

  const result = dependency.getDependencyValue<CalcResult>(bindingContext, expr.name);
  if (result.state !== StateConstants.Available) {
    throw new ValueNotAvailableError(result.state);
  }

  return result.value;
}

function evaluateUnaryExpression(expr: UnaryExpression, bindingContext: Entity | undefined): boolean {
  const argument = evaluateExpression(expr.argument, bindingContext);
  switch (expr.operator) {
    case "!":
      return !argument;
  }
  throw unsupportedOperator(expr.operator);
}

function evaluateBinaryExpression(
  expr: BinaryExpression,
  bindingContext: Entity | undefined,
): number | string | boolean {
  let left = evaluateExpression(expr.left, bindingContext);
  let right = evaluateExpression(expr.right, bindingContext);
  switch (expr.operator) {
    case "+":
      return (left as unknown as number) + (right as unknown as number);
    case "-":
      return (left as unknown as number) - (right as unknown as number);
    case "*":
      return (left as unknown as number) * (right as unknown as number);
    case "/":
      return (left as unknown as number) / (right as unknown as number);
  }

  if (left instanceof Date && right instanceof Date) {
    left = left.getTime();
    right = right.getTime();
  }
  switch (expr.operator) {
    case "==":
      return left === right;
    case "!=":
      return left !== right;
    case "<":
      return left < right;
    case "<=":
      return left <= right;
    case ">":
      return left > right;
    case ">=":
      return left >= right;
  }
  throw unsupportedOperator(expr.operator);
}

function evaluateLogicalExpression(expr: LogicalExpression, bindingContext: Entity | undefined): CalcResult {
  const left = evaluateExpression(expr.left, bindingContext);
  switch (expr.operator) {
    case "&&":
      return left && evaluateExpression(expr.right, bindingContext);
    case "||":
      return left || evaluateExpression(expr.right, bindingContext);
  }
  throw unsupportedOperator(expr.operator);
}

function evaluateConditionalExpression(expr: ConditionalExpression, bindingContext: Entity | undefined): CalcResult {
  const test = evaluateExpression(expr.test, bindingContext);
  return test
    ? evaluateExpression(expr.consequent, bindingContext)
    : evaluateExpression(expr.alternate, bindingContext);
}

function evaluateMemberExpression(expr: MemberExpression, bindingContext: Entity | undefined): CalcResult {
  const object = evaluateExpression(expr.object, bindingContext);
  if (object) {
    return evaluateExpression(expr.property, object as Entity);
  }
  throw nullReference();
}

function nullReference(): Error {
  return new Error("null reference");
}

function unsupportedOperation(type: string): Error {
  return new Error(`unsupported operation: ${type}`);
}

function unsupportedOperator(operator: string): Error {
  return new Error(`unsupported operator: ${operator}`);
}

class ValueNotAvailableError extends Error {
  state: StateConstants;

  constructor(state: StateConstants) {
    super();
    this.state = state;
  }
}
