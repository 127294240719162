import type { DependencyOptions, GetterOptions, SetterOptions } from "DependencyOptions";
import type { StrictDependencyResult } from "DependencyResult";
import DependencyV2Visitor from "DependencyV2Visitor";
import { DependencyOptionsError } from "Errors";

export function avoidLoading<T>(callback: () => T): T {
  return DependencyV2Visitor.avoidLoading(callback);
}

export function getDependencyValue<TResult>(
  data: unknown,
  path: string,
  options?: DependencyOptions,
): StrictDependencyResult<TResult> {
  validatePath(path);
  validateOptionsForGet(options);

  return DependencyV2Visitor.visitSync<TResult>(data, path, options);
}

export async function getDependencyValueAsync<TResult>(
  data: unknown,
  path: string,
  options?: DependencyOptions,
): Promise<StrictDependencyResult<TResult>> {
  validatePath(path);
  validateOptionsForGet(options);

  const result = await DependencyV2Visitor.visitAsync<TResult>(data, path, options);
  return result;
}

export function getUltimateDataItem<TResult>(
  data: unknown,
  path: string,
  options?: DependencyOptions,
): StrictDependencyResult<TResult> {
  validatePath(path);
  validateOptionsForGet(options);

  try {
    const getterOptions: GetterOptions = { ...options, skipLastSegmentEvaluation: true };
    return DependencyV2Visitor.visitSync<TResult>(data, path, getterOptions);
  } catch (error) {
    if (error instanceof DependencyOptionsError) {
      throw new UltimateDataItemError(error);
    }
    throw error;
  }
}

export async function getUltimateDataItemAsync<TResult>(
  data: unknown,
  path: string,
  options?: DependencyOptions,
): Promise<StrictDependencyResult<TResult>> {
  validatePath(path);
  validateOptionsForGet(options);

  try {
    const getterOptions: GetterOptions = { ...options, skipLastSegmentEvaluation: true };
    const result = await DependencyV2Visitor.visitAsync<TResult>(data, path, getterOptions);
    return result;
  } catch (error) {
    if (error instanceof DependencyOptionsError) {
      throw new UltimateDataItemError(error);
    }
    throw error;
  }
}

export async function trySetValueAsync(
  data: unknown,
  path: string,
  value: unknown,
  options?: DependencyOptions,
): Promise<void> {
  validatePath(path);
  const setterOptions: SetterOptions = { ...options, valueToSet: value };
  await DependencyV2Visitor.visitAsync(data, path, setterOptions);
}

export function isValidDependency(path: string): boolean {
  return DependencyV2Visitor.isValidDependency(path);
}

function validateOptionsForGet(options?: object): void {
  if (options && "valueToSet" in options) {
    throw new Error("valueToSet cannot be specified when getting values. Use trySetValueAsync instead to set values.");
  }
}

function validatePath(path: string): void {
  if (!path) {
    throw new Error("Empty property name.");
  }
}

class UltimateDataItemError extends Error {
  override readonly cause?: DependencyOptionsError;

  constructor(cause: DependencyOptionsError) {
    super(
      "getUltimateDataItem can only be used with a binding path. It cannot be used with other types of dependency paths.",
    );
    this.cause = cause;
  }
}

/** @deprecated Use named imports instead */
export default {
  avoidLoading,
  getValue: getDependencyValue,
  getValueAsync: getDependencyValueAsync,
  getUltimateDataItem,
  getUltimateDataItemAsync,
  trySetValueAsync,
  isValid: isValidDependency,
};
