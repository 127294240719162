import captionService from "CaptionService";
import dialogService, { type DialogOptions } from "DialogService";
import materialDesignDialogService from "MaterialDesignDialogService";
import type NotificationSummary from "NotificationSummary";
import { NotificationType } from "NotificationType";
import type { Alert } from "Notifications";

/**
 * Represents a button in an alert dialog.
 * @property caption The text to display on the button.
 * @property isDefault Whether the button will be displayed as the default choice and clicked when pressing [Enter].
 * @property result The result to be resolved by the dialog promise when the button is clicked.
 */
interface AlertDialogButton {
  caption: string;
  isDefault: boolean;
  result?: string | boolean;
}

/**
 * Represents the options for an alert dialog.
 * @property notificationType The type of notification to show.
 */
export interface AlertDialogOptions {
  notificationType?: NotificationType;
}

/**
 * Represents the options for an validation result dialog.
 * @property filter a function that filters by alert. If the function returns true, the result will be included in the dialog.
 */
export interface ValidationResultsDialogOptions extends AlertDialogOptions {
  filter?: (alert: Alert) => boolean | null;
}

/*! StartNoStringValidationRegion for enum button results */
export enum SaveDialogResult {
  Save = "Save",
  Discard = "Discard",
  Cancel = "Cancel",
}
/*! EndNoStringValidationRegion */

class AlertDialogService {
  constructor() {}

  private _getAlertDialogOptions(options?: AlertDialogOptions): AlertDialogOptions {
    return {
      notificationType: options?.notificationType,
    };
  }

  private getUniqueDialogID(message: string, title: string): string {
    return `${message}${title}`;
  }

  /**
   * @description Shows a question dialog with Ok and Cancel buttons.
   * @param message The message to display in the dialog.
   * @param title The title of the dialog.
   * @param okButtonCaptionOverride The caption for the OK button. Default is "OK".
   * @param cancelButtonCaptionOverride The caption for the Cancel button. Default is "Cancel".
   * @param notificationType The type of notification to show. Default is NotificationType.Question.
   * @returns A promise that resolves to true if the user clicks OK, and false if the user clicks Cancel.
   */
  confirmOkCancelAsync(
    message: string,
    title: string,
    okButtonCaptionOverride?: string,
    cancelButtonCaptionOverride?: string,
    notificationType?: NotificationType,
  ): Promise<boolean> {
    const buttons: AlertDialogButton[] = [
      {
        caption:
          cancelButtonCaptionOverride || captionService.getString("7a6192c9-cce2-464e-bd87-e1e0dbdf6d3e", "Cancel"),
        result: false,
        isDefault: false,
      },
      {
        caption: okButtonCaptionOverride || captionService.getString("93f85d2e-d180-49b1-8630-15f4d81dd77b", "OK"),
        result: true,
        isDefault: true,
      },
    ];
    const uniqueDialogID = this.getUniqueDialogID(message, title);
    const dialogNotificationType = notificationType ?? NotificationType.Question;

    if (materialDesignDialogService.canShowAlertDialog()) {
      return materialDesignDialogService.showAlertDialogAsync(
        title,
        message,
        buttons,
        dialogNotificationType,
        uniqueDialogID,
      ) as Promise<boolean>;
    }

    return dialogService.showAsync(dialogNotificationType, message, title, buttons, {
      closeOnDismissOnly: true,
      dialogID: uniqueDialogID,
    }) as Promise<boolean>;
  }

  /**
   * @description: Shows a dialog with a single OK button.
   * @param message The message to display in the dialog.
   * @param title The title of the dialog.
   * @param options The options for the alert dialog. See the AlertDialogOptions interface for more information.
   * @param okButtonCaptionOverride The caption for the OK button. Default is "OK".
   * @returns A promise that resolves when the user clicks OK.
   */
  async alertAsync(
    message: string,
    title: string,
    options?: AlertDialogOptions,
    okButtonCaptionOverride?: string,
  ): Promise<void> {
    options = this._getAlertDialogOptions(options);
    const buttons: AlertDialogButton[] = [
      {
        caption: okButtonCaptionOverride || captionService.getString("93f85d2e-d180-49b1-8630-15f4d81dd77b", "OK"),
        isDefault: true,
      },
    ];
    const uniqueDialogID = this.getUniqueDialogID(message, title);
    const notificationType = options.notificationType ?? NotificationType.Warning;

    if (materialDesignDialogService.canShowAlertDialog()) {
      await materialDesignDialogService.showAlertDialogAsync(title, message, buttons, notificationType, uniqueDialogID);
    } else {
      await dialogService.showAsync(notificationType, message, title, buttons, {
        closeOnDismissOnly: true,
        overwriteDialog: false, // This is the default behavior in G2 and equivalent to keepDialog = true in G1.
        dialogID: uniqueDialogID,
      });
    }
  }

  /**
   * @description Shows a dialog warning the user about unsaved changes and showing 'Stay' and 'Leave' buttons.
   * @returns A promise that resolves true when the user clicks 'Leave' and false when the user clicks 'Stay'.
   */
  warnStayLeaveUnsavedChangesAsync(): Promise<boolean> {
    const message = captionService.getString(
      "81583030-e476-48d9-8f37-e199ba234e5b",
      "There are unsaved changes. Are you sure you want to leave?",
    );
    const title = captionService.getString("4c72e25d-c0e3-438f-8bde-a3808a38a5b2", "Warning - Unsaved Changes");
    const buttons: AlertDialogButton[] = [
      {
        caption: captionService.getString("fbe123db-9a25-4c7d-9a67-2984af9096a9", "Stay"),
        result: false,
        isDefault: false,
      },
      {
        caption: captionService.getString("73c6a632-f000-416b-8c0d-7dbdc1934031", "Leave"),
        result: true,
        isDefault: true,
      },
    ];
    const uniqueDialogID = this.getUniqueDialogID(message, title);

    if (materialDesignDialogService.canShowAlertDialog()) {
      return materialDesignDialogService.showAlertDialogAsync(
        title,
        message,
        buttons,
        NotificationType.Warning,
        uniqueDialogID,
      );
    }

    return dialogService.showAsync(NotificationType.Warning, message, title, buttons, {
      dialogID: uniqueDialogID,
    }) as Promise<boolean>;
  }

  /**
   * @description: Shows a dialog warning the user about unsaved changes and showing 'Save', 'Discard' and 'Cancel' buttons.
   * @param messageOverride The message override to display in the dialog.
   * @param titleOverride The override to display as the title of the dialog.
   * @param saveButtonCaptionOverride The caption for the Save button. Default is "Save".
   * @param discardButtonCaptionOverride The caption for the Discard button. Default is "Discard".
   * @returns A promise enum that resolves 'Save' when the user clicks 'Save', 'Discard' when the user clicks 'Discard', and 'Cancel' when the user clicks 'Cancel'.
   */
  async confirmSaveDiscardCancelAsync(
    messageOverride?: string,
    titleOverride?: string,
    saveButtonCaptionOverride?: string,
    discardButtonCaptionOverride?: string,
  ): Promise<SaveDialogResult> {
    const buttons: AlertDialogButton[] = [
      {
        caption: captionService.getString("983501da-08fb-4b26-8455-e6af1af363a2", "Cancel"),
        result: SaveDialogResult.Cancel,
        isDefault: false,
      },
      {
        caption:
          discardButtonCaptionOverride || captionService.getString("20459d3d-23a1-4af1-a00d-04c05c1d3b0c", "Discard"),
        result: SaveDialogResult.Discard,
        isDefault: false,
      },
      {
        caption: saveButtonCaptionOverride || captionService.getString("b122e480-8919-48e1-a009-15bf3b936617", "Save"),
        result: SaveDialogResult.Save,
        isDefault: true,
      },
    ];

    const notificationType = NotificationType.Warning;
    const title =
      titleOverride || captionService.getString("4c72e25d-c0e3-438f-8bde-a3808a38a5b2", "Warning - Unsaved Changes");
    const message =
      messageOverride ||
      captionService.getString(
        "7a515406-90f0-4a59-93e1-73050c5047be",
        "This record has been modified. Would you like to save the changes?",
      );
    const uniqueDialogID = this.getUniqueDialogID(message, title);

    if (materialDesignDialogService.canShowAlertDialog()) {
      return await materialDesignDialogService.showAlertDialogAsync(
        title,
        message,
        buttons,
        notificationType,
        uniqueDialogID,
      );
    }

    return dialogService.showAsync(notificationType, message, title, buttons, {
      closeOnDismissOnly: true,
      overwriteDialog: false,
      dialogID: uniqueDialogID,
    }) as Promise<SaveDialogResult>;
  }

  /**
   * @description Shows an error dialog with a single "OK" button.
   * @param message The message to display in the error dialog.
   * @param title The title of the error dialog.
   * @returns A promise that resolves when the user clicks OK.
   */
  async errorWithOKButtonAsync(message: string = "", title: string = ""): Promise<void> {
    const buttons: AlertDialogButton[] = [
      {
        caption: captionService.getString("3f309868-ce53-45fc-9283-e6eb93f444dd", "OK"),
        result: true,
        isDefault: true,
      },
    ];
    const uniqueDialogID = this.getUniqueDialogID(message, title);
    const notificationType = NotificationType.Error;

    if (materialDesignDialogService.canShowAlertDialog()) {
      await materialDesignDialogService.showAlertDialogAsync(title, message, buttons, notificationType, uniqueDialogID);
    } else {
      const dialogOptions = {
        closeOnDismissOnly: true,
        overwriteDialog: false,
        dialogID: uniqueDialogID,
      };
      await dialogService.showAsync(notificationType, message, title, buttons, dialogOptions);
    }
  }

  /**
   * @description Shows a validation result dialog with a single "OK" button.
   * @param message The message to display in the error dialog.
   * @param title The title of the error dialog.
   * @param notificationSummary The notification summary to display in the dialog.
   * @param options The options for the alert dialog. See the AlertDialogOptions interface for more information.
   * @returns A promise that resolves when the user clicks OK.
   */
  async showValidationResultsAsync(
    message: string = "",
    title: string = "",
    notificationSummary: NotificationSummary,
    options?: ValidationResultsDialogOptions,
  ): Promise<void> {
    const buttonOptions: AlertDialogButton[] = [
      {
        caption: captionService.getString("3f309868-ce53-45fc-9283-e6eb93f444dd", "OK"),
        isDefault: true,
        result: true,
      },
    ];
    const notificationType = options?.notificationType ?? NotificationType.Error;
    const filter = options?.filter;

    if (materialDesignDialogService.canShowErrorDialog()) {
      await materialDesignDialogService.showErrorDialogAsync(
        notificationType,
        message,
        title,
        notificationSummary,
        buttonOptions,
        filter,
      );
    } else {
      const body =
        message +
        "<div data-bind=\"component: { name: 'gwValidationSummary', params: { showNoAlertsMessage: false, disableNavigation: true, filter: filter } }\"></div>";
      const dialogOptions: DialogOptions = {
        bodyAllowHtml: true,
      };
      const viewModel = { notificationSummary, filter: filter ?? null };
      await dialogService.showAsync(notificationType, body, title, buttonOptions, dialogOptions, viewModel);
    }
  }
}

export default new AlertDialogService();
