import appConfig from "AppConfig";
import global from "Global";
import MessageBus, { MessageType, type MessageHandler } from "MessageBus";
import $ from "jquery";

const connectionStateChangedMessageType = new MessageType<boolean>("ConnectionStateChanged");

class Connection {
  OfflineError = OfflineError;
  private isOnline: boolean;
  private readonly messageBus: MessageBus;
  private promise?: Promise<boolean>;
  private poll?: number;

  constructor() {
    this.isOnline = true;
    this.messageBus = new MessageBus();
    removeWindowEventHandlers();
  }

  async isOnlineAsync(): Promise<boolean> {
    let promise = this.promise;

    if (!promise) {
      this.promise = promise = (async (): Promise<boolean> => {
        try {
          const result = await Promise.resolve(global.navigator.onLine && checkIsReallyOnlineAsync());
          if (this.promise === promise) {
            this.setState(result);
            return result;
          } else {
            return this.isOnline;
          }
        } finally {
          if (this.promise === promise) {
            this.promise = undefined;
          }
        }
      })();
    }

    const result = await promise;
    return result;
  }

  subscribe(handler: MessageHandler<boolean>): string {
    const id = this.messageBus.subscribe(connectionStateChangedMessageType, handler);

    if (this.getSubscriptionCount() === 1) {
      $(window)
        .on("online.glow", this.handleOnlineEvent.bind(this))
        .on("offline.glow", this.handleOfflineEvent.bind(this));

      if (!this.isOnline && this.poll === undefined && global.navigator.onLine) {
        this.schedulePoll();
      }
    }

    return id;
  }

  unsubscribe(id: string): void {
    this.messageBus.unsubscribe(connectionStateChangedMessageType, id);

    if (this.messageBus.getSubscriptionCount(connectionStateChangedMessageType) === 0) {
      removeWindowEventHandlers();
      this.clearPoll();
    }
  }

  reset(): void {
    this.isOnline = true;
    this.messageBus.clear();
    this.promise = undefined;
    this.clearPoll();
    removeWindowEventHandlers();
  }

  private setState(isOnline: boolean): void {
    const hasSubscription = this.getSubscriptionCount() > 0;

    if (this.isOnline !== isOnline) {
      this.isOnline = isOnline;
      if (hasSubscription) {
        this.messageBus.publishAsync(connectionStateChangedMessageType, isOnline);
      }
    }

    if (hasSubscription) {
      if (!isOnline && global.navigator.onLine) {
        this.schedulePoll();
      } else {
        this.clearPoll();
      }
    }
  }

  private getSubscriptionCount(): number {
    return this.messageBus.getSubscriptionCount(connectionStateChangedMessageType);
  }

  private schedulePoll(): void {
    this.poll = window.setTimeout(() => {
      this.isOnlineAsync();
    }, appConfig.connectionPollingIntervalSeconds * 1000);
  }

  private clearPoll(): void {
    clearTimeout(this.poll);
    this.poll = undefined;
  }

  private handleOnlineEvent(): void {
    this.isOnlineAsync();
  }

  private handleOfflineEvent(): void {
    this.setState(false);
    this.promise = undefined;
  }
}

function checkIsReallyOnlineAsync(): Promise<boolean> {
  return new Promise((resolve) => {
    $.get(global.serviceUri).then(
      () => resolve(true),
      (jqXHR) => resolve(jqXHR.status !== 0),
    );
  });
}

function removeWindowEventHandlers(): void {
  $(window).off("online.glow offline.glow");
}

class OfflineError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "OfflineError";
  }
}

export default new Connection();
