import { createDuration, emptyDuration, minValue, type Duration } from "DateExtensions";
import { fromISOString } from "DateTimeOffset";
import LookupListItem from "LookupListItem";
import ProposedValue from "ProposedValue";
import type { RuleType } from "RuleType";
import { ensureIsString } from "StringUtils";

export interface ValueConverter {
  (value: unknown, returnType: string | undefined): unknown;
}

interface MaybeLookupListItem {
  Code?: string | null;
  Description?: string | null;
  IconFontCode?: string | null;
  Sentiment?: string | null;
}

interface MaybeProposedValue {
  IsNoResult?: boolean;
  Value?: unknown;
}

function property(value: unknown, returnType?: string): unknown {
  switch (returnType) {
    case "DateTime":
      return value ? parseDateTime(value) : minValue().toDate();
    case "Nullable[[DateTime]]":
      return value ? parseDateTime(value) : null;
    case "DateTimeOffset":
      return value ? parseDateTimeOffset(value) : minValue().toDate();
    case "Nullable[[DateTimeOffset]]":
      return value ? parseDateTimeOffset(value) : null;
    case "TimeSpan":
      return value ? parseTimeSpan(value) : emptyDuration();
    case "Nullable[[TimeSpan]]":
      return value ? parseTimeSpan(value) : null;
    default:
      return value;
  }
}

function parseDateTime(value: unknown): Date {
  return ensureIsValidDate(new Date(ensureIsString(value)));
}

function parseDateTimeOffset(value: unknown): Date {
  return ensureIsValidDate(fromISOString(ensureIsString(value)));
}

function parseTimeSpan(value: unknown): Duration {
  return createDuration(ensureIsString(value));
}

function ensureIsValidDate(value: Date): Date {
  if (isNaN(value.getTime())) {
    throw new Error("Expected a valid Date.");
  }
  return value;
}

const valueConverter: Partial<Record<RuleType, ValueConverter>> = {
  lookup(value: unknown): LookupListItem[] {
    if (!Array.isArray(value)) {
      throw new Error("Lookup result should be an array.");
    }

    return value.map((item: MaybeLookupListItem) => {
      return new LookupListItem(
        item.Code,
        item.Description ?? undefined,
        item.IconFontCode ?? undefined,
        item.Sentiment ?? undefined,
      );
    });
  },
  property,
  proposedValue(value: unknown, returnType: string | undefined): ProposedValue {
    if (!value) {
      throw new Error("ProposedValue result should not be falsy.");
    }

    const maybeProposedValue: MaybeProposedValue = value;
    return maybeProposedValue.IsNoResult
      ? ProposedValue.NoResult
      : new ProposedValue(property(maybeProposedValue.Value, returnType));
  },
};

export default valueConverter;
