import { AjaxError } from "AjaxService";
import alertDialogService from "AlertDialogService";
import { AuthenticationError, AuthenticationErrorWithBeginSessionResponse } from "AuthenticationError";
import type { ConflictingSession } from "AuthenticationResponse";
import { AuthenticationResult } from "AuthenticationResult";
import { session } from "AuthenticationService";
import captionService from "CaptionService";
import connection from "Connection";
import { formatDate, formatStringForDateTimeType } from "DateExtensions";
import { DateTimeType } from "DateTimeConstants";
import log from "Log";
import nativeBridge from "NativeBridge";
import { NotificationType } from "NotificationType";

interface DialogCaptions {
  failedMessageBody: string;
  failedTitle: string;
  messageBody: string;
  title: string;
}

export async function tryHandleSessionLimitErrorAsync(error: unknown): Promise<boolean> {
  log.withTag("UserSession").info("SessionLimitReachedHandler.tryHandleSessionLimitErrorAsync");

  if (isSessionLimitError(error)) {
    const dialogCaptions = getDialogCaptions(error.response.conflictingSessions ?? []);
    return await promptForDestroyAsync(dialogCaptions);
  }

  return false;
}

export function isSessionLimitError(error: unknown): error is AuthenticationErrorWithBeginSessionResponse {
  return (
    error instanceof AuthenticationErrorWithBeginSessionResponse &&
    error.authenticationResult === AuthenticationResult.SessionLimitReached
  );
}

async function promptForDestroyAsync(dialogCaptions: DialogCaptions): Promise<boolean> {
  const dialogResult = await alertDialogService.confirmOkCancelAsync(
    dialogCaptions.messageBody,
    dialogCaptions.title,
    captionService.getString("3df4f950-ed61-4dff-8b46-22fec58b4e39", "Yes"),
    captionService.getString("27ffa69a-cd93-4007-a987-8553dad049f2", "No"),
    NotificationType.Error,
  );

  if (!dialogResult) {
    return false;
  }

  try {
    await session.destroyAllAsync(await nativeBridge.getSessionTypeAsync());
    return true;
  } catch (error) {
    if (
      (error instanceof AuthenticationError &&
        error.cause instanceof AjaxError &&
        (error.cause.status === 401 || error.cause.isTransientError())) ||
      error instanceof connection.OfflineError
    ) {
      await alertDialogService.errorWithOKButtonAsync(dialogCaptions.failedMessageBody, dialogCaptions.failedTitle);
      return false;
    }
    throw error;
  }
}

function getDialogCaptions(conflictingSessions: ConflictingSession[]): DialogCaptions {
  const conflictingSessionString = getConflictingSessionString(conflictingSessions);

  const reason = captionService.getString(
    "37111d05-e1aa-4468-b306-56063fc6f11d",
    "Your user account is already logged in from the following sessions:",
  );
  const title = captionService.getString("9adf52b5-b554-4c02-adb3-f4383c576eb2", "User already logged in");
  const dialogBody = captionService.getString(
    "0e36e2a5-0e86-41f9-9a5a-78517e9c0330",
    "A user can only be logged in from one session at a time.\n\nDo you want to force the other sessions to exit?\nWARNING: They could lose any unsaved work.",
  );

  const failedTitle = captionService.getString("ff00bca9-9947-4807-a7e4-03fbdbb83491", "Something Went Wrong");
  const failedMessageBody = captionService.getString(
    "c739660f-b6ee-4c00-90cd-4a0b566d4f58",
    "We were unable to terminate the other sessions. Please try again.",
  );

  return {
    title,
    messageBody: `${reason}\n${conflictingSessionString}\n\n${dialogBody}`,
    failedTitle,
    failedMessageBody,
  };
}

function getConflictingSessionString(conflictingSessions: { acquiredTime: string; machineName?: string }[]): string {
  /*! SuppressStringValidation no caption here*/
  const hyphen = conflictingSessions.length === 1 ? "" : "- ";
  const sessionStrings: string[] = [];
  conflictingSessions.forEach((conflictingSession) => {
    const ipAddress =
      conflictingSession.machineName || captionService.getString("f063c922-56c9-4d4a-a083-286dcedf4b6e", "unknown");
    const acquiredTime = formatDateTime(conflictingSession.acquiredTime);
    sessionStrings.push(
      hyphen +
        captionService.getString("bd15cade-831c-4c29-88ff-514e577aa9b5", "{0} (since {1})", ipAddress, acquiredTime),
    );
  });

  return sessionStrings.join("\n");
}

function formatDateTime(dateTimeString: string): string | null {
  /*! SuppressStringValidation date & time format */
  return dateTimeString
    ? formatDate(new Date(dateTimeString), formatStringForDateTimeType(DateTimeType.DateTime))
    : null;
}
