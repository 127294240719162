import captionService from "CaptionService";
import LookupListItem from "LookupListItem";
import Measure from "Measure";
import { notificationTypeFromString } from "NotificationType";
import { type RuleType } from "RuleType";
import RuleValidationResult from "RuleValidationResult";
import { ensureIsString } from "StringUtils";
import { defaultValueSymbol } from "Symbols";
import { isNumber, isString } from "lodash-es";

interface MaybeLookupListItem {
  code?: string | null;
  description?: string;
  iconFontCode?: string;
  sentiment?: string;
}

export interface ValueConverter {
  (value: unknown, values: unknown[], returnType: string | undefined): unknown;
}

const valueConverter: Partial<Record<RuleType, ValueConverter>> = {
  lookup(value: unknown): LookupListItem[] | null {
    if (value === defaultValueSymbol || !value) {
      return null;
    }
    if (!Array.isArray(value)) {
      return [];
    }
    if (value.length === 0 || value[0] instanceof LookupListItem) {
      return value;
    }

    return value.map((item: MaybeLookupListItem) => {
      return new LookupListItem(item.code, item.description, item.iconFontCode, item.sentiment);
    });
  },
  validation(value: unknown, values: unknown[]): RuleValidationResult | null {
    if (value == null || value === true || value === defaultValueSymbol) {
      return null;
    }
    if (value instanceof RuleValidationResult) {
      return value;
    }

    let text;
    let level;
    const length = values.length;

    if (value === false) {
      const key = ensureIsStringIfTruthy(values[length - 3]);
      const fallbackText = ensureIsString(values[length - 2]);
      const type = ensureIsStringIfTruthy(values[length - 1]);

      text = key ? captionService.getString(key, fallbackText) : fallbackText;
      level = notificationTypeFromString(type);
    } else if (isString(value)) {
      const type = ensureIsStringIfTruthy(values[length - 1]);

      text = value;
      level = notificationTypeFromString(type);
    } else if (isNumber(value)) {
      const key = ensureIsStringIfTruthy(values[length - 2]);
      const fallbackText = ensureIsString(values[length - 1]);

      text = key ? captionService.getString(key, fallbackText) : fallbackText;
      level = value;
    } else {
      throw new Error("Unknown validation rule value: " + JSON.stringify(value));
    }

    return new RuleValidationResult(text, level);
  },
  property(value: unknown, _values: unknown[], returnType: string | undefined): unknown {
    if (value !== defaultValueSymbol) {
      return value;
    }

    switch (returnType) {
      case "Byte":
      case "Int16":
      case "Int32":
      case "Int64":
      case "Double":
      case "Decimal":
      case "Single":
        return 0;

      case "Boolean":
        return false;

      case "Measure":
        return new Measure(0, "");

      default:
        return null;
    }
  },
};

function ensureIsStringIfTruthy(value: unknown): string | undefined {
  return value ? ensureIsString(value) : undefined;
}

export default valueConverter;
