import type { CalculatedProperty } from "CalculatedProperty";
import { getDependencyGraph } from "EntityDependencyExtensions";
import { getEntityManager, getInterfaceName } from "EntityExtensions";
import PropertyVertex from "PropertyVertex";
import type { State } from "StateConstants";
import type { Entity } from "breeze-client";
import type { Observable } from "knockout";

export function getPropertyState(entity: Entity, propertyName: string): State {
  const property = getProperty(entity, propertyName);

  if ("getState" in property) {
    return property.getState();
  }

  throw new Error(`${getInterfaceName(entity)}.${propertyName} is not a property with state.`);
}

/**
 * Sets the value of a property.
 * If the property is not attached to the entity then the function will throw an error.
 * Triggers the onchanged event
 *
 * @param entity The Breeze entity.
 * @param propertyName The property name.
 * @returns A void promise.
 */
export async function setPropertyValueAsync<T>(entity: Entity, propertyName: string, value: T): Promise<void> {
  if ("setValueAsync" in entity.entityAspect && typeof entity.entityAspect.setValueAsync === "function") {
    await entity.entityAspect.setValueAsync(propertyName, value);
  } else {
    throw new Error(`Entity of type ${getInterfaceName(entity)} does not support setting property value`);
  }
}

/**
 * Returns the value of the property.
 * If the property does not exist then the function will throw an error.
 * If the property is calculated or a navigation property then it will load the value first.
 *
 * @param entity The Breeze entity.
 * @param propertyName The property name.
 * @returns The property value.
 */
export async function getPropertyValueAsync<T>(entity: Entity, propertyName: string): Promise<T> {
  const property = getProperty<T>(entity, propertyName);

  if ("loadAsync" in property) {
    const result = await property.loadAsync();
    return result;
  }

  return property();
}

/**
 * Indicates whether the given property has changed from its last saved value.
 * Throws an error if the entity's state is Added or Detached.
 *
 * @param entity The Breeze entity.
 * @param propertyName The property name.
 * @returns true if the property has changed; otherwise, false.
 */
export function hasPropertyChanged(entity: Entity, propertyName: string): boolean {
  if (!entity.entityType.getDataProperty(propertyName)) {
    throw new Error(propertyName + " is not a data property.");
  }

  const { entityState, originalValues } = entity.entityAspect;
  if (entityState.isAdded() || entityState.isDetached()) {
    throw new Error(`Cannot determine if property has changed when entity state is ${entityState}.`);
  }

  return (
    propertyName in originalValues &&
    (originalValues as Record<string, unknown>)[propertyName] !== entity.getProperty(propertyName)
  );
}

export async function notifyDependentsAsync(entity: Entity, propertyName: string): Promise<void> {
  const entityManager = getEntityManager(entity);
  const property = getProperty(entity, propertyName);

  if (property.valueHasMutated) {
    property.valueHasMutated();
  } else if ("notifySubscribers" in property) {
    property.notifySubscribers(property());
  }

  await getDependencyGraph(entityManager).notifyDependentsAsync(new PropertyVertex(entity, propertyName), false);
}

function getProperty<T = unknown>(entity: Entity, propertyName: string): CalculatedProperty<T> | Observable<T> {
  if (!(propertyName in entity)) {
    throw new Error(`${getInterfaceName(entity)}.${propertyName} does not exist.`);
  }

  return entity[propertyName as keyof typeof entity] as CalculatedProperty<T> | Observable<T>;
}
