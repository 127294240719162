import { inject, provide } from "vue";

export type ContentViewModel = unknown;

export const contentViewModelInjectionKey = Symbol("contentViewModel");

export function useContentViewModel(): ContentViewModel | undefined {
  return inject<ContentViewModel | undefined>(contentViewModelInjectionKey, undefined);
}

export function setContentViewModel(value: ContentViewModel): void {
  provide(contentViewModelInjectionKey, value);
}
