import {
  invokeFormFlowAsync,
  isActivityBasedFlowDefinition,
  isCodedFormFlowDefinition,
} from "@wtg-glow/form-flow-engine";
import alertDialogService from "AlertDialogService";
import { type EntityManager } from "BreezeExtensions";
import captionService from "CaptionService";
import { type EntitySaveResult } from "EntitySaveService";
import errorHandler from "ErrorHandler";
import { FormFlowAbortError } from "Errors";
import { FormFlowError } from "FormFlowError";
import { loadFormFlowDefinitionAsync } from "FormFlowInitializer";
import { type FormFlowSession } from "FormFlowSession";
import { buildFormFlowSession, type FormFlowInstanceOptions } from "FormFlowSessionFactory";
import { type ExtendedActivityBasedFormFlowDefinition, type FormFlowDefinition } from "FormFlowTypes";
import global from "Global";
import log from "Log";
import { NotificationType } from "NotificationType";
import type { TaskShellOptions } from "TaskPresenter";
import toastService from "ToastService";

export type FormFlowOptions = Partial<TaskShellOptions>;
export class FormFlowInvoker {
  private readonly sessions: FormFlowSession[];

  constructor(sessions: FormFlowSession[]) {
    this.sessions = sessions;
  }

  invokeAsync(
    definitionPK: string,
    argumentValues: unknown[],
    options: FormFlowOptions,
    modalDialogOnlyEntityManager?: EntityManager,
  ): Promise<FormFlowInvokerResult> {
    return this.invokeCoreAsync(
      definitionPK,
      argumentValues,
      { ...{ parentID: definitionPK }, ...options } as TaskShellOptions,
      modalDialogOnlyEntityManager,
    );
  }

  private async invokeCoreAsync(
    definitionPK: string,
    argumentValues: unknown[],
    options: TaskShellOptions,
    modalDialogOnlyEntityManager?: EntityManager,
  ): Promise<FormFlowInvokerResult> {
    try {
      const instanceOptions: FormFlowInstanceOptions = {
        uiContextOrOptions: options,
        entityManagers: modalDialogOnlyEntityManager ? [modalDialogOnlyEntityManager] : [],
        clientPK: options.clientPK,
      };
      const definition = await loadFormFlowDefinitionAsync(definitionPK, instanceOptions);

      if (isActivityBasedFlowDefinition(definition)) {
        return this.invokeActivityBasedFormFlow(definition, argumentValues, instanceOptions);
      }

      if (isCodedFormFlowDefinition(definition)) {
        return this.invokeCodedFormFlowAsync(definition, argumentValues);
      }

      throw new FormFlowError(`Unsupported definition for form flow id "${definitionPK}"`);
    } catch (error: unknown) {
      if (error instanceof FormFlowError) {
        await handleErrorAsync(definitionPK, error);
        return new FormFlowInvokerResult(Promise.resolve(undefined));
      }

      throw error;
    }
  }

  hasChanges(): boolean {
    return this.sessions.some((s) => s.hasChanges());
  }

  abort(): void {
    this.sessions.forEach((s) => s.abort());
  }

  private invokeActivityBasedFormFlow(
    definition: ExtendedActivityBasedFormFlowDefinition,
    argumentValues: unknown[],
    instanceOptions: FormFlowInstanceOptions,
  ): FormFlowInvokerResult {
    const inputStrategies = definition.Parameters?.reduce(
      (input, param, index) => {
        input[param.Name] = argumentValues[index];
        return input;
      },
      {} as Record<string, unknown>,
    );

    // todo: defer session creation to invokeFormFlowAsync after we refactor the code that accesses this instance directly
    const session = buildFormFlowSession(definition, inputStrategies, instanceOptions);
    this.sessions.push(session);

    const awaiter = (async (): Promise<EntitySaveResult | undefined> => {
      try {
        await invokeFormFlowAsync(definition.PK, inputStrategies, session);
        const hasChanges = session.hasChanges();
        const saveResult = await session.saveAsync();

        if (hasChanges) {
          const toastMessage = captionService.getString(
            "c7b00380-e72a-46d5-8300-d1600a6a15fb",
            "Changes have been saved.",
          );
          toastService.showToastAlertAsync(toastMessage, NotificationType.Success);
        }

        return saveResult;
      } catch (error: unknown) {
        if (error instanceof FormFlowError) {
          await handleErrorAsync(definition.PK, error);
          return;
        }

        if (error instanceof FormFlowAbortError) {
          return;
        }

        throw error;
      } finally {
        session.drawerSession?.abort();
        session.drawerSession = undefined;
        const index = this.sessions.indexOf(session);
        this.sessions.splice(index, 1);
        session.uiContext.dispose();
        await session.dispose();
      }
    })();

    return new FormFlowInvokerResult(awaiter, session);
  }

  private invokeCodedFormFlowAsync(definition: FormFlowDefinition, argumentValues: unknown[]): FormFlowInvokerResult {
    const awaiter = (async (): Promise<undefined> => {
      await invokeFormFlowAsync(definition.PK, argumentValues);
    })();

    return new FormFlowInvokerResult(awaiter);
  }
}

export class FormFlowInvokerResult {
  constructor(
    public awaiter: Promise<EntitySaveResult | undefined>,
    public session?: FormFlowSession,
  ) {}
}

async function handleErrorAsync(definitionPK: string, error: FormFlowError): Promise<void> {
  log.error(error);
  if (error.isReportable && (!error.isConfigurationError || !global.isPreviewMode())) {
    errorHandler.reportError(error, `FormFlowError, Type=${error.type}, PK=${definitionPK}`);
  }

  const message = error.isConfigurationError ? getMessageForConfigurationError(error) : error.friendlyMessage;

  if (message) {
    const caption = error.friendlyCaption || getFallbackCaption();
    await alertDialogService.errorWithOKButtonAsync(message, caption);
  }
}

function getMessageForConfigurationError(error: FormFlowError): string {
  return global.isPreviewMode()
    ? captionService.getString(
        "77f288cf-acf2-452f-8d21-2e50b68acf0a",
        "An unexpected error occurred while running a form-flow, and so the form-flow will exit. The error may be caused by an invalid configuration.\r\n{0}{1}",
        error.message,
        getStackTrace(error),
      )
    : captionService.getString(
        "f151fb3a-5d7f-407d-8d33-000724671ba3",
        "An unexpected error occurred while performing that action; the error has been automatically reported. Please contact your system administrator.",
      );
}

function getStackTrace(error: FormFlowError): string {
  return error.activityStack ? "\r\n    " + error.activityStack.join("\r\n    ") : "";
}

function getFallbackCaption(): string {
  return global.isPreviewMode()
    ? captionService.getString("8a1541eb-d2a3-4e98-a994-3740459aef5e", "Form-flow error")
    : captionService.getString("ffadadbf-c25a-479d-aff2-17704b72bf12", "Something Went Wrong");
}

export default new FormFlowInvoker([]);
