import captionService from "CaptionService";

export const errorReporting = {
  continue: (): string => captionService.getString("d0b701a6-95d0-4073-bd47-cbc8fdf71789", "Continue"),
  important: (): string => captionService.getString("34c51a23-9153-4d8e-9127-b1ecb91fcb12", "IMPORTANT: "),
  environmentMessage: (): string =>
    captionService.getString(
      "abc8b9b8-1f6b-4315-88f1-1058ce655336",
      "If this error occurred due to the changes you are testing in UAT, please discuss it with the developer before submitting.",
    ),
  pleaseDescribeMessage: (): string =>
    captionService.getString(
      "fd3bb06d-3ff3-4a44-aa39-869733018d99",
      "Please describe, in as much detail as possible, what you were doing prior to this error occurring.",
    ),
  errorDetails: (): string => captionService.getString("998aaa94-338d-48b3-8fd1-8202f7be6fc5", "Error details"),
  minLength: (): string =>
    captionService.getString("bed67171-8cb3-4d96-8578-b64fdea358df", "You must enter at least 10 characters."),
  errorResolutionMessage: (): string =>
    captionService.getString(
      "5d3b65ba-0af8-4306-84de-78568c75b770",
      "By doing this, WiseTech Global will be able to provide a resolution much more quickly and prevent this error from happening again.",
    ),
  technicalDetailsButton: (): string =>
    captionService.getString("832756b3-517b-427e-a7f2-1330c0bc072a", "View technical details"),
  title: (): string => captionService.getString("99e85797-9c08-40a5-922c-d0ac2ac41301", "An error has occurred"),
};
