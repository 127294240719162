import { unwrapCollectionType } from "DataTypes";
import { getInterfaceName } from "EntityExtensions";
import entityMappingService from "EntityMappingService";
import type Rule from "Rule";
import RuleService from "RuleService";
import type { EntityType, NavigationProperty } from "breeze-client";

const separatorRegex = /(\/|\.)/g;
const separatorExclusiveRegex = /\/|\./g;

export type PropertyInfo = ValidPropertyInfo | InvalidPropertyInfo;
function isValid(propertyInfo: PropertyInfo | undefined): propertyInfo is ValidPropertyInfo {
  return propertyInfo?.isValid ?? false;
}

export function* enumeratePropertyPath(
  entityType: EntityType | undefined,
  propertyPath: string,
): Generator<PropertyInfo> {
  if (!entityType) {
    throw new Error("Entity type must be specified.");
  }
  if (!propertyPath) {
    return;
  }

  const parts = propertyPath.split(separatorRegex);
  let lastPropertyInfo;

  for (let i = 0; i < parts.length; i += 2) {
    const propertyName = parts[i];
    const nextSeparator = parts[i + 1];

    if (i) {
      entityType = getEntityType(lastPropertyInfo);
    }

    if (entityType) {
      const property = entityType.getProperty(propertyName) as NavigationProperty;
      const rule = !property && RuleService.get(getInterfaceName(entityType)).propertyRule(propertyName, true);

      if (property || rule) {
        lastPropertyInfo = new ValidPropertyInfo(
          entityType,
          propertyName,
          property || undefined,
          rule || undefined,
          nextSeparator,
        );
      } else {
        lastPropertyInfo = new InvalidPropertyInfo(entityType, propertyName, nextSeparator);
      }
    } else {
      lastPropertyInfo = new InvalidPropertyInfo(undefined, propertyName, nextSeparator);
    }

    yield lastPropertyInfo;
  }
}

function getEntityType(propertyInfo: PropertyInfo | undefined): EntityType | undefined {
  if (!isValid(propertyInfo)) {
    return;
  }

  if (propertyInfo.property) {
    return propertyInfo.property.entityType;
  }

  if (!propertyInfo.rule?.returnType) {
    return;
  }

  const propertyType = unwrapCollectionType(propertyInfo.rule.returnType).typeName;

  if (!entityMappingService.hasInterfaceName(propertyType)) {
    return;
  }

  return propertyInfo.entityType.metadataStore.getEntityType(propertyType, true) as EntityType; // returning non-extended type!
}

export function getPropertyName(propertyPath: string): string {
  const parts = propertyPath.split(separatorRegex);
  return parts[parts.length - 1];
}

export function isComplexPath(propertyPath: string): boolean {
  return !!propertyPath.match(separatorRegex);
}

export function replaceSeparators(propertyPath: string, targetSeparator: string): string {
  return propertyPath.replace(separatorRegex, targetSeparator);
}

export function splitPropertyPath(propertyPath: string): string[] {
  return propertyPath ? propertyPath.split(separatorExclusiveRegex) : [];
}

class ValidPropertyInfo {
  entityType: EntityType;
  propertyName: string;
  property: NavigationProperty | undefined;
  rule: Rule | undefined;
  nextSeparator: string;

  constructor(
    entityType: EntityType,
    propertyName: string,
    property: NavigationProperty | undefined,
    rule: Rule | undefined,
    nextSeparator: string,
  ) {
    this.entityType = entityType;
    this.propertyName = propertyName;
    this.property = property;
    this.rule = rule;
    this.nextSeparator = nextSeparator;
  }

  get isCalculated(): boolean {
    return !this.property;
  }

  get isValid(): boolean {
    return true;
  }
}

class InvalidPropertyInfo {
  entityType: EntityType | undefined;
  propertyName: string;
  property: undefined;
  rule: undefined;
  nextSeparator: string;

  constructor(entityType: EntityType | undefined, propertyName: string, nextSeparator: string) {
    this.entityType = entityType;
    this.propertyName = propertyName;
    this.nextSeparator = nextSeparator;
  }

  get isCalculated(): boolean {
    return false;
  }

  get isValid(): boolean {
    return false;
  }
}
