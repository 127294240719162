import { getCachedPromise } from "CachedPromise";
import ClientSideRule from "ClientSideRule";
import clientSideRuleValueConverter from "ClientSideRuleValueConverter";
import DependencyExpression from "DependencyExpression";
import dependencyExtractor from "DependencyExtractorVisitor";
import type { DependencyResult } from "DependencyResult";
import { getInterfaceName, isEntity } from "EntityExtensions";
import embeddedDependenciesExtractor from "ExtractEmbeddedDependenciesVisitor";
import global from "Global";
import log from "Log";
import { type NotificationType, notificationTypeFromString } from "NotificationType";
import ruleDependencyValue, { DependencyType } from "RuleDependencyValue";
import RuleExpressionCondition from "RuleExpressionCondition";
import type { RuleType } from "RuleType";
import type { RuleComponentBase, RuleDefinitionBase } from "RulesetMetadata";
import ServerSideRule from "ServerSideRule";
import serverSideRuleValueConverter from "ServerSideRuleValueConverter";
import { State } from "StateConstants";
import type { RuleDescriptor, RuleFunc, RuleProcessResult, SubRule } from "SubRule";
import { defaultValueSymbol } from "Symbols";
import { sortBy, uniqBy } from "lodash-es";

interface CacheDependencyFunc {
  (entity: unknown): unknown;
}

interface DependenciesInfo {
  subRules: Map<SubRule, DependencyExpression[]>;
  subRuleConditions: Map<SubRule, DependencyExpression[]>;
}

enum DependencyPathExpressionType {
  FullExpression,
  PathWithExpression,
}

export interface RuleCondition {
  func: RuleFunc<boolean>;
  parameters: string[];
}

/*! SuppressStringValidation property names */
export interface RuleComponent extends Omit<RuleComponentBase, "cacheKeyFunc" | "func" | "setterFunc"> {
  cacheKeyFunc?: string | RuleFunc;
  func?: string | RuleFunc;
  setterFunc?: string | RuleFunc;
}

/*! SuppressStringValidation property names */
export interface RuleDefinition extends Omit<RuleDefinitionBase, "components"> {
  components: RuleComponent[];
  expandPaths?: string[];
  ruleType: RuleType;
}

interface RuleDependency {
  dependency: DependencyExpression;
  isCondition: boolean;
}

interface SubRuleWithCondition extends SubRule {
  condition?: RuleExpressionCondition;
}

class Rule implements RuleDescriptor {
  readonly expandPaths: string[];
  readonly getCacheDependencyFuncAsync: () => Promise<CacheDependencyFunc | undefined>;
  readonly notificationType?: NotificationType;
  readonly property: string;
  readonly returnType?: string;
  readonly ruleId: string;
  readonly ruleType: RuleType;
  readonly subRules: SubRuleWithCondition[];
  private _hasPathDependenciesOtherThanRuleProperty?: boolean;
  private _hasPathOrSelfDependenciesExcludingConditions?: boolean;
  private readonly cacheMode?: string;
  private dependenciesInfo?: DependenciesInfo;

  constructor(definition: RuleDefinition) {
    if (!definition.id) {
      throw new Error("Rule must have id specified.");
    }
    if (!definition.components || definition.components.length === 0) {
      throw new Error("Rule must have component with at least one item.");
    }
    this.ruleId = definition.id;
    this.ruleType = definition.ruleType;
    this.property = definition.property;
    this.returnType = definition.returnType;
    this.expandPaths = definition.expandPaths || [];
    this.notificationType = parseNotificationType(definition.notificationType);
    this.cacheMode = definition.cacheMode;
    this.dependenciesInfo = undefined;
    this._hasPathDependenciesOtherThanRuleProperty = undefined;
    this._hasPathOrSelfDependenciesExcludingConditions = undefined;
    this.subRules = definition.components.map(createSubRule.bind(undefined, definition));
    this.getCacheDependencyFuncAsync = getCachedPromise(() => getCacheDependencyFuncAsync(this.subRules));
  }

  getAllDependencies(): DependencyExpression[] {
    const info = this.getDependenciesInfo();
    const empty: DependencyExpression[] = [];
    return uniqueDependencies(empty.concat(...info.subRules.values(), ...info.subRuleConditions.values()));
  }

  getDependencies(entity: unknown): RuleDependency[] {
    const info = this.getDependenciesInfo();
    const result = new Map<string, RuleDependency>();
    const addDependencies = (dependencies: DependencyExpression[] | undefined, isCondition: boolean): void => {
      dependencies?.forEach((dependency) => {
        if (!result.has(dependency.expression)) {
          result.set(dependency.expression, { dependency, isCondition });
        }
      });
    };

    for (const subRule of this.subRules) {
      if (subRule.condition) {
        const conditionValue = subRule.condition.evaluate(entity);
        addDependencies(info.subRuleConditions.get(subRule), true);
        if (conditionValue.value) {
          addDependencies(info.subRules.get(subRule), false);
          break;
        } else if (conditionValue.state === State.NotLoaded) {
          break;
        }
      } else {
        addDependencies(info.subRules.get(subRule), false);
      }
    }

    return Array.from(result.values());
  }

  async getDependenciesAsync(entity: unknown): Promise<DependencyExpression[]> {
    const info = this.getDependenciesInfo();
    const result: DependencyExpression[] = [];

    for (const subRule of this.subRules) {
      if (subRule.condition) {
        const conditionValue = await subRule.condition.evaluateAsync(entity);
        result.push(...(info.subRuleConditions.get(subRule) ?? []));
        if (conditionValue) {
          result.push(...(info.subRules.get(subRule) ?? []));
          break;
        }
      } else {
        result.push(...(info.subRules.get(subRule) ?? []));
      }
    }

    return uniqueDependencies(result);
  }

  private getDependenciesInfo(): DependenciesInfo {
    if (!this.dependenciesInfo) {
      const subRules = new Map<SubRule, DependencyExpression[]>();
      const subRuleConditions = new Map<SubRule, DependencyExpression[]>();
      this.subRules.forEach((subRule) => {
        subRules.set(
          subRule,
          mapDependencies(extractSubRuleDependencies(subRule, DependencyPathExpressionType.FullExpression)),
        );
        subRuleConditions.set(
          subRule,
          mapDependencies(extractConditionDependencies(subRule, DependencyPathExpressionType.FullExpression)),
        );
      });

      this.dependenciesInfo = {
        subRules,
        subRuleConditions,
      };
    }

    return this.dependenciesInfo;
  }

  hasPathDependenciesOtherThanRuleProperty(): boolean {
    if (this._hasPathDependenciesOtherThanRuleProperty === undefined) {
      this._hasPathDependenciesOtherThanRuleProperty = this.getAllDependencies().some((d) =>
        d.getAllDependencyPaths().some((p) => p !== this.property),
      );
    }

    return this._hasPathDependenciesOtherThanRuleProperty;
  }

  hasPathOrSelfDependenciesExcludingConditions(): boolean {
    if (this._hasPathOrSelfDependenciesExcludingConditions === undefined) {
      this._hasPathOrSelfDependenciesExcludingConditions = this.subRules.some(
        (subRule) =>
          subRule.dependencies.some(isPropertyPath) ||
          subRule.parameters.some((parameter) => {
            if (typeof parameter.value !== "string") {
              return;
            } else if (parameter.queryTypeArgument) {
              return extractEmbeddedDependencies(parameter.value).length;
            } else {
              const info = ruleDependencyValue.getDependencyInfo(parameter.value);
              if (info.type === DependencyType.Self) {
                return true;
              } else if (info.type === DependencyType.Expression) {
                return extractDependencies(info.value).length || extractEmbeddedDependencies(info.value).length;
              } else {
                return;
              }
            }
          }),
      );
    }

    return this._hasPathOrSelfDependenciesExcludingConditions;
  }

  tryProcessSync(entity: unknown, context?: object): DependencyResult {
    let state = State.NotAvailable;
    for (let i = 0; i < this.subRules.length; i++) {
      const subRule = this.subRules[i];
      let conditionValue: boolean | null;
      if (subRule.condition) {
        const evaluation = subRule.condition.evaluate(entity);
        if (evaluation.state === State.NotLoaded) {
          return { state: State.NotLoaded, value: null };
        } else if (evaluation.state === State.Available) {
          state = evaluation.state;
        }
        conditionValue = evaluation.value;
      } else {
        conditionValue = true;
      }

      if (conditionValue) {
        return subRule.tryProcessSync(this, entity, context);
      }
    }

    return { state, value: null };
  }

  hasSetter(): boolean {
    const subRules = this.subRules;
    return subRules.length === 1 && subRules[0].hasSetter();
  }

  async invokeSetterAsync(entity: unknown, value: unknown): Promise<boolean> {
    if (!this.hasSetter()) {
      throw new Error("This rule does not have a setter.");
    }
    return await this.subRules[0].invokeSetterAsync(entity, value);
  }

  isCacheDisabled(): boolean {
    return this.cacheMode === "NotCached";
  }

  async processAsync(entity: unknown, context?: object): Promise<RuleProcessResult> {
    const startTime = performance.now();
    const invocationId = Math.floor(Math.random() * 100000);
    if (global.featureFlags.logRuleInvocationTimings) {
      log.info(`[Rule] Invoked ${this.ruleId}. (${invocationId})`);
    }

    const result = await getFirstApplicableSubRuleAsync(entity, this.subRules);
    if (!result.value) {
      return {
        isSuccess: result.isSuccess,
        value: clientSideRuleValueConverter[this.ruleType]?.(defaultValueSymbol, [], this.returnType),
      };
    }

    const subRule = result.value;
    try {
      return await subRule.processAsync(this, entity, context);
    } catch (error) {
      log.error({
        /*! SuppressStringValidation error message */
        message: "Failed to run rule.",
        data: error,
        entityType: isEntity(entity) ? getInterfaceName(entity) : "",
        rule: subRule,
      });
      throw error;
    } finally {
      if (global.featureFlags.logRuleInvocationTimings) {
        log.info(`[Rule] Finished ${this.ruleId} in ${performance.now() - startTime}ms. (${invocationId})`);
      }
    }
  }
}

function uniqueDependencies(dependencies: DependencyExpression[]): DependencyExpression[] {
  return uniqBy(dependencies, getExpression);
}

function mapDependencies(strings: string[]): DependencyExpression[] {
  return trimDependencies(sortBy(strings)).map((s) => new DependencyExpression(s));
}

function getExpression(dependencyExpression: DependencyExpression): string {
  return dependencyExpression.expression;
}

function trimDependencies(dependencies: string[]): string[] {
  const result: string[] = [];
  dependencies.forEach((current) => {
    if (result.length) {
      const previous = result[result.length - 1];
      if (current.startsWith(previous) && (current.length === previous.length || current[previous.length] === ".")) {
        result[result.length - 1] = current;
        return;
      }
    }
    result.push(current);
  });

  return result;
}

function extractConditionDependencies(subRule: SubRuleWithCondition, type: DependencyPathExpressionType): string[] {
  const condition = subRule.condition && subRule.condition.conditionExpression;
  if (condition) {
    const propertyPaths = extractDependencies(condition);
    if (propertyPaths.length) {
      if (type !== DependencyPathExpressionType.FullExpression) {
        return propertyPaths;
      } else {
        return [condition];
      }
    }
  }

  return [];
}

function extractSubRuleDependencies(subRule: SubRule, type: DependencyPathExpressionType): string[] {
  const result = subRule.dependencies.flatMap((d) => extractDependencies(d));
  subRule.parameters.forEach((parameter) => {
    if (typeof parameter.value !== "string") {
      return;
    } else if (parameter.queryTypeArgument) {
      result.push(...extractEmbeddedDependencies(parameter.value));
    } else {
      const info = ruleDependencyValue.getDependencyInfo(parameter.value);
      if (info.type === DependencyType.Expression) {
        const propertyPaths = extractDependencies(info.value).concat(extractEmbeddedDependencies(info.value));
        if (propertyPaths.length) {
          if (type !== DependencyPathExpressionType.FullExpression) {
            result.push(...propertyPaths);
          } else {
            result.push(info.value);
          }
        }
      }
    }
  });
  return result;
}

function extractDependencies(dependency: string): string[] {
  return dependencyExtractor.visitDependency(dependency).filter(isPropertyPath);
}

function extractEmbeddedDependencies(dependency: string): string[] {
  return embeddedDependenciesExtractor.extractEmbeddedDependencies(dependency).filter(isPropertyPath);
}

function isPropertyPath(dependency: string): boolean {
  return !dependency.startsWith("%");
}

async function getCacheDependencyFuncAsync(subRules: SubRule[]): Promise<CacheDependencyFunc | undefined> {
  const subRulesWithCacheKeyFunc = subRules.filter((s) => s.hasCacheKeyFunc);
  if (subRulesWithCacheKeyFunc.length) {
    await Promise.all(subRulesWithCacheKeyFunc.map((s) => s.loadCacheKeyFuncAsync()));
    return (entity) => {
      const subRule = getFirstApplicableSubRule(entity, subRules);
      return subRule && subRule.hasCacheKeyFunc ? subRule.getCacheKey(entity) : undefined;
    };
  }
  return;
}

function getFirstApplicableSubRule(entity: unknown, subRules: SubRuleWithCondition[]): SubRule | undefined {
  for (const subRule of subRules) {
    if (!subRule.condition) {
      return subRule;
    }

    const result = subRule.condition.evaluate(entity);
    if (result.value) {
      return subRule;
    } else if (result.state === State.NotLoaded) {
      return;
    }
  }
  return;
}

async function getFirstApplicableSubRuleAsync(
  entity: unknown,
  subRules: SubRuleWithCondition[],
): Promise<RuleProcessResult<SubRule>> {
  let isSuccess = false;
  for (const subRule of subRules) {
    const isConditionSatisfied = !subRule.condition || (await subRule.condition.evaluateAsync(entity));
    if (isConditionSatisfied) {
      return { isSuccess: true, value: subRule };
    } else {
      isSuccess = isSuccess || isConditionSatisfied === false;
    }
  }

  return { isSuccess, value: undefined };
}

function createSubRule(definition: RuleDefinition, component: RuleComponent): SubRuleWithCondition {
  const subRule: SubRuleWithCondition = createSubRuleCore(component, definition.ruleType, definition.returnType);
  if (component.conditionExpression) {
    subRule.condition = new RuleExpressionCondition(component.conditionExpression);
  }
  return subRule;
}

function createSubRuleCore(component: RuleComponent, ruleType: RuleType, returnType: string | undefined): SubRule {
  let rule;
  /*! SuppressStringValidation (No caption here) */
  if (Object.hasOwn(component, "uri")) {
    rule = new ServerSideRule(component, serverSideRuleValueConverter[ruleType], returnType ?? "");
  } else {
    rule = new ClientSideRule(component, clientSideRuleValueConverter[ruleType]);
  }
  return rule;
}

function parseNotificationType(value: string | undefined): NotificationType | undefined {
  if (typeof value === "string") {
    return notificationTypeFromString(value);
  } else if (typeof value === "number") {
    return value;
  } else {
    return;
  }
}

export default Rule;
