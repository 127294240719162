import BreezeQueryable from "BreezeQueryable";
import { ensureIsDate, minValue, toFilterValueString } from "DateExtensions";
import { DateTimeType } from "DateTimeConstants";
import { fromISOString, toISOString } from "DateTimeOffset";
import { getDependencyValue, getDependencyValueAsync } from "Dependency2";
import type { DependencyResult } from "DependencyResult";
import embeddedDependenciesExtractor from "ExtractEmbeddedDependenciesVisitor";
import { State } from "StateConstants";
import { emptyGuid, ensureIsString } from "StringUtils";
import type { EntityQuery } from "breeze-client";
import { isNil, uniq } from "lodash-es";

export interface DependencyResults<TResult = unknown> {
  values: TResult;
  state: State;
}

/*! StartNoStringValidationRegion enum values */
export enum DependencyType {
  Constant = "constant",
  Expression = "expression",
  Self = "self",
}
/*! EndNoStringValidationRegion */

const ruleDependencyValue = {
  getValues(entity: unknown, parameters: Parameter[]): DependencyResults<unknown[]> {
    return syncStrategy.evaluateDependencyValues(entity, parameters, byIndexStrategy);
  },

  async getValuesAsync(entity: unknown, parameters: Parameter[]): Promise<DependencyResults<unknown[]>> {
    return await asyncStrategy.evaluateDependencyValuesAsync(entity, parameters, byIndexStrategy);
  },

  async getSerializableValuesByNameAsync(
    entity: unknown,
    parameters: Parameter[],
  ): Promise<DependencyResults<Record<string, unknown>>> {
    return await asyncStrategy.evaluateDependencyValuesAsync(entity, parameters, byNameStrategy);
  },

  getDependencyInfo,
};

function processParameters<T>(
  entity: unknown,
  parameters: Parameter[],
  getStrategy: GetStrategy<T>,
): { results: (DependencyResult | T)[]; embeddedDependencies: string[]; embeddedDependencyResult: T[] } {
  const results: (DependencyResult | T)[] = [];
  let embeddedDependencies: string[] = [];

  parameters.forEach((parameter, i) => {
    results[i] = getValueForSingleParameter(entity, parameter, getStrategy);
    if (parameter.queryTypeArgument && typeof parameter.value === "string") {
      embeddedDependencies = embeddedDependencies.concat(
        embeddedDependenciesExtractor.extractEmbeddedDependencies(parameter.value),
      );
    }
  });

  embeddedDependencies = uniq(embeddedDependencies);
  const embeddedDependencyResult = embeddedDependencies.map((path) => getStrategy.getValue(entity, path));

  return { results, embeddedDependencies, embeddedDependencyResult };
}

function getValuesForParameters<T>(
  entity: unknown,
  parameters: Parameter[],
  getStrategy: SyncGetStrategy,
  valuesStrategy: ValuesStrategy<T>,
): DependencyResults<T> {
  const { results, embeddedDependencies, embeddedDependencyResult } = processParameters(
    entity,
    parameters,
    getStrategy,
  );

  return getStrategy.finaliseValues(
    results,
    embeddedDependencies,
    embeddedDependencyResult,
    parameters,
    valuesStrategy,
  );
}

interface Parameter {
  isNullable?: boolean;
  name?: string;
  queryTypeArgument?: string;
  type?: string;
  value: unknown;
}

async function getValuesForParametersAsync<T>(
  entity: unknown,
  parameters: Parameter[],
  getStrategy: AsyncGetStrategy,
  valuesStrategy: ValuesStrategy<T>,
): Promise<DependencyResults<T>> {
  const { results, embeddedDependencies, embeddedDependencyResult } = processParameters(
    entity,
    parameters,
    getStrategy,
  );

  return await getStrategy.finaliseValuesAsync(
    results,
    embeddedDependencies,
    embeddedDependencyResult,
    parameters,
    valuesStrategy,
  );
}

function getValueForSingleParameter<T>(
  entity: unknown,
  parameter: Parameter,
  getStrategy: GetStrategy<T>,
): DependencyResult | T {
  const info =
    typeof parameter.value === "string"
      ? getDependencyInfo(parameter.value)
      : ({ value: parameter.value, type: DependencyType.Constant } as const);

  if (info.type === DependencyType.Constant) {
    let { value } = info;
    if (parameter.queryTypeArgument) {
      const queryableProvider = createQueryableProvider(ensureIsString(info.value));
      queryableProvider.interfaceName = parameter.queryTypeArgument;
      value = queryableProvider;
    } else {
      switch (parameter.type) {
        case DateTimeType.DateTime:
          value = new Date(ensureIsString(info.value));
          break;
        case DateTimeType.DateTimeOffset:
          value = fromISOString(ensureIsString(info.value));
          break;
      }
    }
    return { value, state: State.Available };
  } else {
    return getStrategy.getValue(entity, info.value, parameter);
  }
}

interface QueryableProvider {
  (query: EntityQuery, params?: unknown[], embeddedDependenciesMap?: Record<string, DependencyResult>): BreezeQueryable;
  embeddedDependenciesMap?: Record<string, unknown>;
  interfaceName?: string;
  isQueryableProvider: true;
}

function createQueryableProvider(expression: string): QueryableProvider {
  const result: QueryableProvider = (query, params, embeddedDependenciesMap) => {
    const queryable = new BreezeQueryable(query);
    return queryable.select(expression, params, embeddedDependenciesMap);
  };
  result.isQueryableProvider = true;
  return result;
}

function isQueryableProvider(value: unknown): value is QueryableProvider {
  return typeof value === "function" && "isQueryableProvider" in value && value.isQueryableProvider === true;
}

function finaliseValuesCore<T>(
  results: DependencyResult[],
  embeddedDependencies: string[],
  embeddedDependencyResult: DependencyResult[],
  parameters: Parameter[],
  valuesStrategy: ValuesStrategy<T>,
): DependencyResults<T> {
  const notAvailableEmbeddedDependency = embeddedDependencyResult.find((result) => result.state !== State.Available);
  if (notAvailableEmbeddedDependency) {
    return { values: valuesStrategy.init(), state: notAvailableEmbeddedDependency.state };
  }

  const embeddedDependenciesMap: Record<string, unknown> = {};
  embeddedDependencies.forEach((path, i) => {
    embeddedDependenciesMap[path] = embeddedDependencyResult[i].value;
  });

  const values = valuesStrategy.init();
  for (let i = 0; i < results.length; ++i) {
    const result = results[i];
    if (result.state === State.Available) {
      const parameter = parameters[i];
      const convertedValue = valuesStrategy.shouldSerialize
        ? formatServerValue(result.value, parameter.type)
        : result.value;
      if (parameter.queryTypeArgument) {
        if (!isQueryableProvider(convertedValue)) {
          throw new Error("Expected a queryable provider.");
        }
        convertedValue.embeddedDependenciesMap = embeddedDependenciesMap;
      }
      valuesStrategy.add(values, i, parameter.name, convertedValue);
    } else {
      return { values: valuesStrategy.init(), state: result.state };
    }
  }
  return { values, state: State.Available };
}

function getDefaultValue(parameter: Parameter): unknown {
  if (parameter.isNullable) {
    return null;
  }

  switch (parameter.type) {
    case "DateTime":
      return toFilterValueString(minValue());

    case "Decimal":
    case "Double":
    case "Int32":
    case "Int64":
    case "Single":
      return 0;

    case "Guid":
      return emptyGuid;

    case "String":
      return "";

    default:
      return null;
  }
}

function formatServerValue(value: unknown, serverType: string | undefined): unknown {
  if (value) {
    switch (serverType) {
      case "DateTime":
        return toFilterValueString(ensureIsDate(value));
      case "DateTimeOffset":
        return toISOString(ensureIsDate(value));
    }
  }
  return value;
}

function getDependencyInfo(value: string): { value: string; type: DependencyType } {
  let type;
  const match = /^<([\w\W]+)>$/.exec(value);
  const isDependency = match;
  if (isDependency) {
    value = match[1];
    if (value === ".") {
      type = DependencyType.Self;
    } else {
      type = DependencyType.Expression;
    }
  } else {
    type = DependencyType.Constant;
  }

  return { value, type };
}

function getValueOrDefault(value: DependencyResult, parameter: Parameter | undefined): DependencyResult {
  if (isNil(value.value) && value.state === State.Available && parameter) {
    return { value: getDefaultValue(parameter), state: value.state };
  }
  return value;
}

interface GetStrategy<T> {
  getValue(entity: unknown, path: string, parameter?: Parameter): T;
}

interface SyncGetStrategy extends GetStrategy<DependencyResult> {
  evaluateDependencyValues<T>(
    entity: unknown,
    parameters: Parameter[],
    valuesStrategy: ValuesStrategy<T>,
  ): DependencyResults<T>;
  finaliseValues<T>(
    values: DependencyResult[],
    embeddedDependencies: string[],
    embeddedDependencyResult: DependencyResult[],
    parameters: Parameter[],
    valuesStrategy: ValuesStrategy<T>,
  ): DependencyResults<T>;
}

interface AsyncGetStrategy extends GetStrategy<Promise<DependencyResult>> {
  evaluateDependencyValuesAsync<T>(
    entity: unknown,
    parameters: Parameter[],
    valuesStrategy: ValuesStrategy<T>,
  ): Promise<DependencyResults<T>>;
  finaliseValuesAsync<T>(
    valuePromises: (DependencyResult | Promise<DependencyResult>)[],
    embeddedDependencies: string[],
    embeddedDependencyPromises: Promise<DependencyResult>[],
    parameters: Parameter[],
    valuesStrategy: ValuesStrategy<T>,
  ): Promise<DependencyResults<T>>;
}

const syncStrategy: SyncGetStrategy = {
  evaluateDependencyValues(entity, parameters, valuesStrategy) {
    return getValuesForParameters(entity, parameters, syncStrategy, valuesStrategy);
  },
  getValue(entity, path, parameter) {
    const value = getDependencyValue(entity, path);
    return getValueOrDefault(value, parameter);
  },
  finaliseValues: finaliseValuesCore,
};
const asyncStrategy: AsyncGetStrategy = {
  async evaluateDependencyValuesAsync(entity, parameters, valuesStrategy) {
    return await getValuesForParametersAsync(entity, parameters, asyncStrategy, valuesStrategy);
  },
  // eslint-disable-next-line rulesdir/async-function-suffix
  async getValue(entity, path, parameter) {
    const value = await getDependencyValueAsync(entity, path);
    return getValueOrDefault(value, parameter);
  },
  async finaliseValuesAsync(
    valuePromises,
    embeddedDependencies,
    embeddedDependencyPromises,
    parameters,
    valuesStrategy,
  ) {
    const [values, embeddedDependencyValues] = await Promise.all([
      Promise.all(valuePromises),
      Promise.all(embeddedDependencyPromises),
    ]);

    return finaliseValuesCore(values, embeddedDependencies, embeddedDependencyValues, parameters, valuesStrategy);
  },
};

interface ValuesStrategy<T = unknown> {
  init(): T;
  add(values: T, index: number, name: string | undefined, value: unknown): void;
  shouldSerialize?: boolean;
}

const byIndexStrategy: ValuesStrategy<unknown[]> = {
  init() {
    return [];
  },
  add(values, index, _name, value) {
    values[index] = value;
  },
};
const byNameStrategy: ValuesStrategy<Record<string, unknown>> = {
  init() {
    return {};
  },
  add(values, _index, name, value) {
    values[ensureIsString(name)] = value;
  },
  shouldSerialize: true,
};

export default ruleDependencyValue;
