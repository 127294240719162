import type { Entity } from "breeze-client";
import captionService from "CaptionService";
import { getInterfaceName, getPrimaryKey } from "EntityExtensions";
import type { AlertDetails } from "Notifications";
import type { NotificationType } from "NotificationType";

export function getBaseAlertDetails(
  text: string,
  level: NotificationType,
  entity: Entity,
  propertyName: string,
  propertyCaption?: string,
): AlertDetails {
  const entityName = getInterfaceName(entity);
  const entityPK = getPrimaryKey(entity);

  return {
    caption: propertyCaption ?? captionService.getCaptionForProperty(entityName, propertyName).caption,
    entityName,
    entityPK,
    Level: level,
    propertyName,
    Text: text,
  };
}

export function getControlAlertDetails(
  controlAlertId: string,
  text: string,
  level: NotificationType,
  entity: Entity,
  propertyName: string,
): AlertDetails {
  const result = getBaseAlertDetails(text, level, entity, propertyName);
  result.controlAlertId = controlAlertId;
  result.requiresAcknowledgement = false;
  return result;
}

export function getRuleAlertDetails(
  ruleId: string,
  text: string,
  level: NotificationType,
  entity: Entity,
  propertyName: string,
): AlertDetails {
  const result = getBaseAlertDetails(text, level, entity, propertyName);
  result.ruleId = ruleId;
  return result;
}
