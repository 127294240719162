import breeze from "BreezeExtensions";
import entityMappingService from "EntityMappingService";

export default class ModelProvider {
  readonly routeName?: string;
  readonly mainEntityTypeName?: string;

  constructor(routeName?: string, mainEntityTypeName?: string, fallbackModelProvider?: ModelProvider) {
    this.routeName = routeName || fallbackModelProvider?.routeName;
    this.mainEntityTypeName = mainEntityTypeName || fallbackModelProvider?.mainEntityTypeName;
  }

  async createEntityManagerAsync(entityTypeName: string, optionalRouteName?: string): Promise<breeze.EntityManager>;
  async createEntityManagerAsync(
    entityTypeName: string,
    optionalRouteName?: string,
    okIfNotFound?: boolean,
  ): Promise<breeze.EntityManager | undefined>;
  async createEntityManagerAsync(
    entityTypeName: string,
    optionalRouteName?: string,
    okIfNotFound?: boolean,
  ): Promise<breeze.EntityManager | undefined> {
    const store = await this.getMetadataStoreAsync(entityTypeName, optionalRouteName, okIfNotFound);
    return store ? breeze.EntityManager.withMetadataStore(store) : undefined;
  }

  async getMetadataStoreAsync(entityTypeName: string, optionalRouteName?: string): Promise<breeze.MetadataStore>;
  async getMetadataStoreAsync(
    entityTypeName: string,
    optionalRouteName?: string,
    okIfNotFound?: boolean,
  ): Promise<breeze.MetadataStore | undefined>;
  async getMetadataStoreAsync(
    entityTypeName: string,
    optionalRouteName?: string,
    okIfNotFound?: boolean,
  ): Promise<breeze.MetadataStore | undefined> {
    if (optionalRouteName) {
      const store = await breeze.MetadataStore.forRouteAsync(optionalRouteName);
      if (isValidStoreForType(store, entityTypeName)) {
        return store;
      }
    }

    if (this.routeName) {
      const store = await breeze.MetadataStore.forRouteAsync(this.routeName);
      if (isValidStoreForType(store, entityTypeName)) {
        return store;
      }
    }

    if (this.mainEntityTypeName && entityMappingService.hasDefaultRoute(this.mainEntityTypeName)) {
      const store = await breeze.MetadataStore.forEntityTypeAsync(this.mainEntityTypeName);
      if (isValidStoreForType(store, entityTypeName)) {
        return store;
      }
    }

    const result = await breeze.MetadataStore.forEntityTypeAsync(entityTypeName, !!okIfNotFound);
    // TODO: Remove when we stop using forEntityTypeAsync or when that's updated to return
    // undefined instead of null.
    return result ?? undefined;
  }

  isDefault(): boolean {
    return !this.routeName && !this.mainEntityTypeName;
  }
}

function isValidStoreForType(metadataStore: breeze.MetadataStore | undefined, entityTypeName: string): boolean {
  return !!metadataStore?.getEntityType(entityTypeName, true);
}
