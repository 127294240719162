import { DependencyError } from "Errors";
import { getFullText } from "ExpressionVisitorUtils";
import { uniq } from "lodash-es";
import { ExpressionVisitor } from "wtg-expressions";
import { type PathContext, type Path_partContext } from "wtg-expressions/lib/ExpressionParser";

interface Options {
  extractPathsOnly?: boolean;
}

export default class DependencyExtractorVisitor extends ExpressionVisitor<unknown> {
  readonly dependencyPaths: string[];
  private readonly extractPathsOnly?: boolean;
  private hasPathPart: boolean;
  private path?: string;
  private topLevel: boolean;

  constructor(options?: Options) {
    super();
    this.dependencyPaths = [];
    this.extractPathsOnly = options?.extractPathsOnly;
    this.path = undefined;
    this.topLevel = true;
    this.hasPathPart = false;
  }

  static visitDependency(dependencyPath: string, options?: Options): string[] {
    if (!dependencyPath) {
      return [];
    }

    const tree = DependencyExtractorVisitor.getDependencyTree(
      dependencyPath,
      DependencyExtractorVisitor.TreeName.Dependency,
      onDependencyError,
    );
    const visitor = new DependencyExtractorVisitor(options);
    visitor.visit(tree);
    return uniq(visitor.dependencyPaths);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitEmbedded_path = (): void => {};

  override visitPath = (ctx: PathContext): void => {
    if (this.extractPathsOnly) {
      const backupPath = this.path;
      this.visitChildren(ctx);
      if (this.path && this.path !== backupPath) {
        this.dependencyPaths.push(this.path);
      }
      this.path = backupPath;
    } else {
      if (this.topLevel) {
        this.topLevel = false;
        this.visitChildren(ctx);
        this.topLevel = true;

        if (this.hasPathPart) {
          this.dependencyPaths.push(getFullText(ctx));
        }
        this.hasPathPart = false;
      } else if (!this.hasPathPart) {
        this.visitChildren(ctx);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  override visitPath_part = (ctx: Path_partContext): void => {
    if (this.extractPathsOnly) {
      const propertyName = ctx.children?.[0].getText();
      if (this.path) {
        this.path += "." + propertyName;
      } else {
        this.path = propertyName;
      }
      this.visitChildren(ctx);
    } else {
      this.hasPathPart = true;
    }
  };
}

function onDependencyError(msg: string): never {
  throw new DependencyError(msg);
}
