import bindingEvaluator from 'BindingEvaluator';
import captionService from 'CaptionService';
import constants from 'Constants';
import Dependency from 'Dependency';
import errors from 'Errors';
import Filter from 'Filters/Filter';
import FilterGroup from 'Filters/FilterGroup';
import filterHelpers from 'Filters/FilterHelpers';
import FilterStrip from 'Filters/FilterStrip';
import ko from 'knockout';
import RuleService from 'RuleService';
import moment from 'moment';
import { startsWith, endsWith } from 'StringUtils';
import _ from 'underscore';

class FilterSerializer {
	toFilterGroupsData(filterGroups) {
		return filterGroups.map((filterGroup) => {
			const filterStripsData = filterGroup
				.filterStrips()
				.map((filterStrip) => {
					const filter = filterStrip.filter();
					if (filter) {
						return toFilterData(this, filter);
					}
					return null;
				})
				.filter(Boolean);

			return { Filters: filterStripsData, IsImplicit: filterGroup.isImplicit };
		});
	}

	async populateFromDataAsync(filter, operation, values, subFilters, entityType, filterFactory, rootDataItem, processDependencyValues, contextDataItem) {
		if (operation) {
			operation = getOperatorName(filter, operation);
			filter.operatorName(operation);
		}

		if (isSubQueryFilter(filter.type, operation) && subFilters && subFilters.length > 0) {
			const convertedValue = await convertJsonValueAsync(this, filter, null, subFilters, entityType, filterFactory, rootDataItem, processDependencyValues);
			filter.value(convertedValue);
		}
		else {
			const converted = await Promise.all(
				values.map((value) =>
					convertJsonValueAsync(this, filter, value, null, entityType, filterFactory, rootDataItem, null, contextDataItem))
			);
			converted.forEach((value, i) => filter.setValue(i, value));
		}
	}

	async fromFilterGroupsDataAsync(filterGroupsData, entityType, filterFactory, rootDataItem, contextDataItem, searchFields, isImplicitFiltersVisible, modelProvider, processDependencyValues, category) {
		return await Promise.all(
			filterGroupsData.map(async (filterGroupData) => {
				const filtersData = filterGroupData.Filters.results || filterGroupData.Filters;
				if (!(filtersData || !Array.isArray(filtersData) || !filtersData.length)) {
					return;
				}

				const validFilters = filtersData.filter((filterData) => {
					return _.contains(Filter.types, filterData.FilterType);
				});

				const isImplicitFilterGroup = filterGroupData.IsImplicit;
				const hasSearchFields = !isImplicitFilterGroup && searchFields && searchFields.length > 0;
				const hasValidFilters = validFilters.length !== 0;

				let filterSearchFields;
				if (hasValidFilters) {
					if (hasSearchFields) {
						filterSearchFields = searchFields;
					}
					else {
						const loadedFields = await filterFactory.loadSearchFieldsAsync(entityType, {category});
						filterSearchFields = filterHelpers.addSelectFilterToSearchFields(loadedFields);
					}
				}
				else {
					filterSearchFields = filterHelpers.addSelectFilterToSearchFields([]);
				}

				const filterStripResults = await Promise.all(
					validFilters.map(
						createFilterStripAsync.bind(
							null,
							this,
							filterFactory,
							filterSearchFields,
							entityType,
							rootDataItem,
							contextDataItem,
							isImplicitFilterGroup && !isImplicitFiltersVisible,
							modelProvider,
							processDependencyValues))
				);
				const filterGroup = new FilterGroup(isImplicitFilterGroup);
				const filterStrips = filterStripResults.filter(Boolean);

				filterStrips.forEach(filterGroup.addStrip.bind(filterGroup));

				if (filterStripResults.length !== filterStrips.length) {
					const filterStrip = new FilterStrip(filterSearchFields, filterFactory, modelProvider);
					filterStrip.errorMessage(captionService.getString('A9D09E7D-D009-4005-A485-D134409A9EE3', 'One or more fields used in your saved filter were removed from the data model, please check and adjust your filter.'));
					filterGroup.addStrip.call(filterGroup, filterStrip);
				}

				return filterGroup;
			})
		);
	}
}

// operatorName in G2 is in lower case, but in camel cased in G1.
function getOperatorName(filter, operatorName) {
	const operator = filter?.operators.find((o) => {
		return o.name.toLowerCase() === operatorName.toLowerCase();
	});
	return operator?.name || operatorName;
}

function toFilterData(serializer, filter) {
	const filterData = {};
	filterData.PropertyPath = filter.fieldName;
	const operator = filter.operator();
	filterData.Operation = operator ? operator.name : null;
	filterData.FilterType = filter.type;

	if (isSubQueryFilter(filter.type, filterData.Operation)) {
		filterData.FilterGroups = serializer.toFilterGroupsData(filter.value() || []);
	} else {
		filterData.Values = [];
		const values = getValues(serializer, filter);
		if (values) {
			filterData.Values = values;
		}
	}

	return filterData;
}

async function createFilterStripAsync(serializer, filterFactory, searchFields, entityType, rootDataItem, contextDataItem, isHiddenImplicitFilterGroup, modelProvider, processDependencyValues, filterData) {
	let searchField = filterHelpers.findSearchField(searchFields, filterData.PropertyPath);

	if (!searchField && isHiddenImplicitFilterGroup && filterFactory.supportsBindingPath) {
		const rules = RuleService.get(entityType.interfaceName);
		searchField = filterHelpers.mapFilterKey(
			{
				propertyName: filterData.PropertyPath,
				filterType: filterData.FilterType
			},
			rules,
			entityType);
	}

	if (!searchField) {
		if (isHiddenImplicitFilterGroup) {
			/*! SuppressStringValidation Developer error message */
			const errorMessage = `Attempted to use an invalid implicit filter on entityType: "${entityType.interfaceName}", propertyPath: "${filterData.PropertyPath}"`;
			throw new errors.FilterStripError(errorMessage);
		}
		return null;
	}

	const filterStrip = new FilterStrip(searchFields, filterFactory, modelProvider);
	filterStrip.selectFilter(searchField);

	const filter = filterStrip.filter();
	if (filter) {
		const processedValues = processDependencyValues && processDependencyValues(filterData);
		const filterDataValues = filterData.Values && (filterData.Values.results || filterData.Values);

		await serializer.populateFromDataAsync(
				filter,
				filterData.Operation,
				(processedValues && processedValues.value) || filterDataValues,
				filterData.FilterGroups && (filterData.FilterGroups.results || filterData.FilterGroups),
				entityType,
				filterFactory,
				rootDataItem,
				processDependencyValues,
				contextDataItem
			);
	}

	return filterStrip;
}

function convertToStringValue(serializer, filter, value) {
	let operatorName;
	switch (filter.type) {
		case Filter.types.dateTime:
		case Filter.types.dateTimeUtc:
		case Filter.types.dateTimeOffset:
			return value === null ? '' : moment(value).toFilterValueString('DateTimeUtc') + 'Z';
		case Filter.types.time:
			return value === null ? '' : moment.toDateTimeTypeString(value, constants.DateTimeTypes.Time);
		case Filter.types.duration:
			return value === null ? '' : moment.durationFromDate(value).toString();
		case Filter.types.collection:
			operatorName = filter.operatorName();
			if (operatorName === constants.Filters.CollectionOperators.Exists ||
				operatorName === constants.Filters.CollectionOperators.NotExists ||
				operatorName === constants.Filters.CollectionOperators.ForAll) {
				return JSON.stringify(serializer.toFilterGroupsData(value || []));
			}
			return value?.toString() ?? '';
		default:
			return value?.toString() ?? '';
	}
}

function convertJsonValueAsync(serializer, filter, value, subFilters, entityType, filterFactory, rootDataItem, processDependencyValues, contextDataItem) {
	if (value === undefined) {
		return Promise.resolve();
	}

	if (value && startsWith(value, '<') && endsWith(value, '>')) {
		const dataItem = ko.unwrap(contextDataItem) || ko.unwrap(rootDataItem);
		const path = value.substr(1, value.length - 2);

		if (startsWith(path, '%')) {
			return Dependency.loadValueAsync(dataItem, path);
		}else if (dataItem && dataItem.entityType && bindingEvaluator.isValidEntityBindingPath(dataItem.entityType, path)) {
			return Dependency.loadValueAsync(dataItem, path);
		}
	}

	switch (filter.type) {
		case Filter.types.boolean:
		case Filter.types.isActive:
			return getBooleanValueAsync(value);
		case Filter.types.collection:
			return getCollectionFilterValueAsync(serializer, filter, value, subFilters, entityType, filterFactory, rootDataItem, processDependencyValues, contextDataItem);
		case Filter.types.dateTime:
		case Filter.types.dateTimeUtc:
		case Filter.types.dateTimeOffset:
			return Promise.resolve(value === '' ? null : new Date(value));
		case Filter.types.time:
			return Promise.resolve(value === '' ? null : moment.parse(value, constants.DateTimeTypes.Time).toDate());
		case Filter.types.duration: {
			const duration = moment.parseDurationExpression(value);
			return Promise.resolve(duration ? duration.toDate() : null);
		}
		case Filter.types.numericByte:
		case Filter.types.numericInt16:
		case Filter.types.numericInt32:
		case Filter.types.numericInt64:
		case Filter.types.numericDouble:
		case Filter.types.numericDecimal:
		case Filter.types.numericSingle:
			return Promise.resolve(value === '' ? null : parseFloat(value));
		default:
			return Promise.resolve(value === '' ? null : value);
	}
}

function getCollectionFilterValueAsync(serializer, filter, value, subFilters, entityType, filterFactory, rootDataItem, processDependencyValues, contextDataItem) {
	const operatorName = filter.operatorName();
	if (operatorName === constants.Filters.CollectionOperators.Exists ||
		operatorName === constants.Filters.CollectionOperators.NotExists ||
		operatorName === constants.Filters.CollectionOperators.ForAll) {
		const targetPropertyType = bindingEvaluator.getEntityBindingInfo(entityType, filter.fieldName).propertyEntityType;

		return serializer.fromFilterGroupsDataAsync(value ? JSON.parse(value) : subFilters, targetPropertyType, filterFactory, rootDataItem, contextDataItem, null, false, filter.modelProvider, processDependencyValues);
	}

	return getBooleanValueAsync(value);
}

function getBooleanValueAsync(value) {
	if (typeof value === 'boolean') {
		return Promise.resolve(value);
	}

	const normalizedValue = value ? value.toLowerCase() : '';
	if (normalizedValue === 'true') {
		return Promise.resolve(true);
	} else if (normalizedValue === 'false') {
		return Promise.resolve(false);
	}

	return Promise.resolve(null);
}

function isSubQueryFilter(filterType, operation) {
	return filterType === Filter.types.collection
		&& [constants.Filters.CollectionOperators.Exists, constants.Filters.CollectionOperators.NotExists, constants.Filters.CollectionOperators.ForAll].includes(operation);
}

function getValues(serializer, filter) {
	const values = [];

	filter.values().forEach((value, i) => {
		value = value && value();
		if (value !== undefined) {
			while (values.length < i) {
				values.push('');
			}
			values.push(convertToStringValue(serializer, filter, value));
		}
	});

	return values;
}

export default new FilterSerializer();
